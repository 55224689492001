export interface IMailerSizeOptionForm {
  id: string;
  optionKey: string;
  displayName: string;
  startingPricePerUnit: number;
  width: number;
  height: number;
  active: boolean;
  mostPopular: boolean;
}

export interface IMailerSizeOptionLookup {
  id: string;
  optionKey: string;
  displayName: string;
  startingPricePerUnit: number;
  width: number;
  height: number;
  active: boolean;
  mostPopular: boolean;
}

export function createEmptyMailerSizeOptionLookup(): IMailerSizeOptionLookup {
  return {
    id: '',
    optionKey: '',
    displayName: '',
    startingPricePerUnit: 0,
    width: 0,
    height: 0,
    active: false,
    mostPopular: false,
  };
}