import { Grid, Typography, Box } from '@mui/material';
import { useEffect } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from 'components/buttons';
import { createEmptyMailerSizeOptionLookup, IMailerSizeOptionLookup } from 'model/lookup/neighborhoodMailersConfig';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useNeighborhoodMailersConfig from './hookStore/useNeighborhoodMailersConfig';
import SubmitButton from './SubmitButton';

export interface INeighborhoodMailersConfigFormProps {
  isNew: boolean;
}

export interface INeighborhoodMailersConfigFormInternalProps {
  initialFormData: IMailerSizeOptionLookup;
}


export const neighborhoodMailersSizeOptionModalKey = 'neighborhoodMailersSizeOptionModalKey';

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { onSubmit } = useNeighborhoodMailersConfig();
  const navigate = useNavigate();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Option'}
      body='Save changes?'
      modalKey={neighborhoodMailersSizeOptionModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12}>

          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await onSubmit(data);

      }}

    />
  );
}

function NeighborhoodMailersConfigFormInternal({ initialFormData }: INeighborhoodMailersConfigFormInternalProps) {
  const navigate = useNavigate();
  const formContext = useForm({
    mode: 'onChange',
    defaultValues: createEmptyMailerSizeOptionLookup(),
  });

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  return (
    <FormProvider {...formContext}>
      <form>
        <Grid container>
          <Grid item sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <HookTextField name="optionKey" label="Option Key" required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <HookTextField name="displayName" label="Display Name" required />
              </Grid>
              <Grid item xs={12} sm={4}>
                <HookTextField name="startingPricePerUnit" label="Starting Price Per Unit" required />
              </Grid>
              <Grid item xs={12} sm={4}>
                <HookCheckbox name="active" label="Active" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <HookCheckbox name="mostPopular" label="Most Popular" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <HookTextField name="width" label="Width" required />
              </Grid>
              <Grid item xs={12} sm={4}>
                <HookTextField name="height" label="Height" required />
              </Grid>

              <Grid container item xs={12} alignItems="center" sx={{ marginTop: '10px' }}>
                <Grid item xs="auto" sx={{ marginRight: '10px' }}>
                  <SubmitButton />
                </Grid>
                <Grid item xs="auto">
                  <SecondaryButton
                    onClick={() => {
                      formContext.reset(initialFormData);
                      navigate('/');
                    }}
                  >
                    <>Cancel</>
                  </SecondaryButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function NeighborhoodMailersConfigForm({ isNew }: INeighborhoodMailersConfigFormProps) {
  const { initialFormData } = useNeighborhoodMailersConfig();

  useEffect(() => {
    if (isNew) {

    }
  }, [isNew]);

  return <NeighborhoodMailersConfigFormInternal initialFormData={initialFormData} />;
}
