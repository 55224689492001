import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { splitNeighborhoods as onSplitNeighborhoods } from 'api/neighborhoodApi';
import { ISplitNeighborhoodRequest } from 'model/neighborhoods/neighborhoodRestructuring';
import { useParams } from 'react-router-dom';
import { deleteServiceProvider, findInternalServiceProviderById, findServiceProviderById } from 'api/serviceProviderApi';
import { createEmptyInternalServiceProvider, IInternalServiceProvider } from 'model/serviceProvider';
import { IProviderStreetfairReview } from 'model/reviews/providerStreetFairReview';
import { IServiceOffering } from 'model/serviceOffering';
import { transferPartnershipAgreement, transferProviderCoverageAreas, transferProviderUsers, transferReviews, transferServiceOfferings } from 'api/mergeApi';
import { findProviderStreetfairReviews } from 'api/reviewsApi';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { ServiceTypeInfoUtil } from 'util/serviceType/serviceTypeInfoUtil';
import { IServiceType } from 'model/serviceType';
import { findCoverageAreaDTOsByServiceProvider } from 'api/providerCoverageAreaApi';
import { IProviderCoverageAreaDTO } from 'model/providerCoverageArea';
import { findExistingProviderUsers } from 'api/userApi';
import { IProviderUserLite } from 'model/user';
import { IServiceTypeInfo } from 'model/serviceCategory';

const loadingKey = 'TransferServiceOfferings';

type TransferServiceOfferings = {
  serviceProvider: IInternalServiceProvider | null;
  coverageAreas: IProviderCoverageAreaDTO[];
  transferServiceProvider: IInternalServiceProvider | null;
  serviceOfferings:IServiceOffering[];
  reviews:IProviderStreetfairReview[];
  transferServiceProviderId?: string;
  serviceTypeInfo: IServiceTypeInfo | null;
  existingProviderUsers:IProviderUserLite[];

}

const { get, update, registerListener, unregisterListener } = createStore<TransferServiceOfferings>('TransferServiceOfferings', {
  serviceProvider: null,
  coverageAreas: [],
  transferServiceProvider: null,
  serviceOfferings: [],
  reviews: [],
  serviceTypeInfo: null,
  existingProviderUsers: [],
});

export default function useTransferServiceProviderData() {

  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
      update({
        ...get(),
        serviceProvider: null,
        coverageAreas: [],
        transferServiceProvider: null,
        serviceOfferings: [],
        reviews: [],
        serviceTypeInfo: null,
        existingProviderUsers: [],
      });
    };
  }, []);

  async function init() {
    try {
      onLoading();
      if (!serviceProviderId) {
        createErrorToast('No service provider id provided');
        return;
      }
      const [
        serviceProvider,
        coverageAreasRes,
        resServiceCategoryTypes,
        reviews,
        serviceOfferings,
        existingProviderUsers,
      ] = await Promise.all([
        refreshProvider(serviceProviderId),
        findCoverageAreaDTOsByServiceProvider(serviceProviderId),
        findServiceTypeInfo(),
        refreshReviews(serviceProviderId),
        refreshServiceOfferings(serviceProviderId),
        refreshProviderUsers(serviceProviderId),
      ]);

      let coverageAreas: IProviderCoverageAreaDTO[]= [];
      if (coverageAreasRes.status === 200) {
        coverageAreas = coverageAreasRes.data;
      }

      update({
        ...get(),
        serviceProvider: serviceProvider,
        coverageAreas: coverageAreas,
        reviews: reviews,
        serviceOfferings: serviceOfferings,
        serviceTypeInfo: resServiceCategoryTypes.data,
        existingProviderUsers: existingProviderUsers,
      });
    } catch (e) {
      createErrorToast('Failed to load service provider');
    } finally {
      doneLoading(300);
    }
  }


  async function setTransferServiceProvider(selectedServiceProviderId:string) {
    const transferServiceProvider = await refreshProvider(selectedServiceProviderId);
    update({
      ...get(),
      transferServiceProviderId: selectedServiceProviderId,
      transferServiceProvider: transferServiceProvider,
    });
  };

  async function refreshProvider(paramServiceProviderId:string) {
    let serviceProvider:IInternalServiceProvider = createEmptyInternalServiceProvider();
    const serviceProviderRes = await findServiceProviderById(paramServiceProviderId);
    if (serviceProviderRes.status === 200) {
      serviceProvider = serviceProviderRes.data;
    }
    return serviceProvider;
  }

  async function refreshReviews(paramServiceProviderId:string):Promise<IProviderStreetfairReview[]> {
    let reviews: IProviderStreetfairReview[] = [];
    const reviewsRes = await findProviderStreetfairReviews(paramServiceProviderId, {});
    if (reviewsRes.status === 200) {
      reviews = reviewsRes.data.items;
    }
    return reviews;
  }

  async function refreshServiceOfferings(paramServiceProviderId:string):Promise<IServiceOffering[]> {
    let serviceOfferings: IServiceOffering[] = [];
    const serviceOfferingsRes = await findServiceOfferingsByProvider(paramServiceProviderId);
    if (serviceOfferingsRes.status === 200) {
      serviceOfferings = serviceOfferingsRes.data;
    }
    return serviceOfferings;
  }

  async function refreshCoverageAreas(paramServiceProviderId:string):Promise<IProviderCoverageAreaDTO[]> {
    let coverageAreas: IProviderCoverageAreaDTO[] = [];
    const coverageAreasRes = await findCoverageAreaDTOsByServiceProvider(paramServiceProviderId);
    if (coverageAreasRes.status === 200) {
      coverageAreas = coverageAreasRes.data;
    }
    return coverageAreas;
  }

  async function refreshProviderUsers(paramServiceProviderId:string):Promise<IProviderUserLite[]> {
    let existingProviderUsers:IProviderUserLite[] = [];
    const existingProviderUsersRes = await findExistingProviderUsers(paramServiceProviderId);
    if (existingProviderUsersRes.status === 200) {
      existingProviderUsers = existingProviderUsersRes.data.providerUsers;
    }
    return existingProviderUsers;
  }

  async function onDelete() {
    try {
      const res = await deleteServiceProvider(serviceProviderId!);
      if (res.data.errorMessage) {
        if (res.data.invalidStatus) {
          createErrorToast(res.data.errorMessage, null, { persistent: true });
        }
      } else if (res.data.message) {
        createSuccessToast(res.data.message);
      }
    } catch (e:any) {
      console.error(`Unable to delete service provider. Message=${e.response?.data?.message}`);
      createErrorToast(`Unable to delete service provider. Message=${e.response?.data?.message}`);
    }
  }

  function canDelete() {
    const { serviceOfferings, reviews, transferServiceProviderId } = get();
    return serviceOfferings.length == 0 && reviews.length == 0 && transferServiceProviderId && serviceProviderId;
  }


  async function onTransferReviews() {
    try {
      onLoading();
      const { transferServiceProviderId } = get();
      const res = await transferReviews(serviceProviderId!, transferServiceProviderId!);
      if (res.status === 200) {


        update({
          ...get(),
          reviews: [],
        });
        createInfoToast('Reviews transferred successfully');
      } else {
        createErrorToast('Failed to transfer reviews');
      }

    } catch (e:any) {
      console.error('Failed to transfer reviews');
      createErrorToast(`Failed to transfer reviews: ${e.response.data.message}`);
    } finally {
      doneLoading(300);
    }
  }

  async function onTransferServiceOfferings() {
    try {
      onLoading();
      const { transferServiceProviderId } = get();
      const res = await transferServiceOfferings(serviceProviderId!, transferServiceProviderId!);
      if (res.status === 200) {
        const nextServiceOfferings = await refreshServiceOfferings(serviceProviderId!);
        update({
          ...get(),
          serviceOfferings: nextServiceOfferings,
        });
        createInfoToast('Service offerings transferred successfully');
      } else {
        createErrorToast('Failed to transfer service offerings');
      }
    } catch (e:any) {
      console.error('Failed to transfer service offerings');
      createErrorToast(`Failed to transfer service offerings: ${e.response.data.message}`);
    } finally {
      doneLoading(300);
    }
  }

  async function onTransferCoverageAreas() {
    try {
      onLoading();
      const { transferServiceProviderId } = get();
      const res = await transferProviderCoverageAreas(serviceProviderId!, transferServiceProviderId!);
      if (res.status === 200) {
        const nextCoverageAreas = await refreshCoverageAreas(serviceProviderId!);
        update({
          ...get(),
          coverageAreas: nextCoverageAreas,
        });
        createInfoToast('Coverage areas transferred successfully');
      } else {
        createErrorToast('Failed to transfer Coverage areas');
      }
    } catch (e:any) {
      console.error('Failed to transfer Coverage areas');
      createErrorToast(`Failed to transfer Coverage areas: ${e.response.data.message}`);
    } finally {
      doneLoading(300);
    }
  }

  async function onTransferProviderUsers() {
    try {
      onLoading();
      const { transferServiceProviderId } = get();
      const res = await transferProviderUsers(serviceProviderId!, transferServiceProviderId!);
      if (res.status === 200) {
        const nextUsers = await refreshProviderUsers(serviceProviderId!);
        update({
          ...get(),
          existingProviderUsers: nextUsers,
        });
        createInfoToast('Users transferred successfully');
      } else {
        createErrorToast('Failed to transfer users');
      }
    } catch (e:any) {
      console.error('Failed to transfer users');
      createErrorToast(`Failed to transfer users: ${e.response.data.message}`);
    } finally {
      doneLoading(300);
    }
  }

  async function onTransferProviderPartnership() {
    try {
      onLoading();
      const { transferServiceProviderId } = get();
      const res = await transferPartnershipAgreement(serviceProviderId!, transferServiceProviderId!);
      if (res.status === 200) {
        const serviceProvider = await refreshProvider(serviceProviderId!);
        const transferServiceProvider = await refreshProvider(transferServiceProviderId!);
        update({
          ...get(),
          serviceProvider: serviceProvider,
          transferServiceProvider: transferServiceProvider,
        });
        createInfoToast('partnership data transferred successfully');
      } else {
        createErrorToast('Failed to transfer partnership data');
      }
    } catch (e:any) {
      console.error('Failed to transfer partnership data');
      createErrorToast(`Failed to transfer partnership data: ${e.response.data.message}`);
    } finally {
      doneLoading(300);
    }
  }


  return {
    ...get(),
    init,
    onDelete,
    canDelete,
    setTransferServiceProvider,
    onTransferServiceOfferings,
    onTransferReviews,
    onTransferCoverageAreas,
    onTransferProviderUsers,
    onTransferProviderPartnership,
    loadingKey,
  };
}