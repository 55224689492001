import { findNeighborhoodServiceOfferingByNeighborhoodProviderAndServiceOffering, findNeighborhoodServiceOfferingsByFilter, saveNeighborhoodServiceOffering } from 'api/neighborhoodServiceOfferingApi';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';

export interface IGenerateNSOParams {
  serviceProviderId: string;
  serviceOfferingId: string;
  serviceType: string;
  discountScheduleId: string;
  neighborhoodId?: string;
}

export class NSOCreationUtil {

  static async generateNeighborvilleNso({
    serviceProviderId,
    serviceOfferingId,
    serviceType,
    discountScheduleId,
    neighborhoodId = window.REACT_APP_NEIGHBORVILLE_UUID,
  }:IGenerateNSOParams) {

    if (!serviceProviderId || !serviceOfferingId || !discountScheduleId) {
      console.log('missing required data to generate NSO. ', { serviceProviderId, serviceOfferingId, discountScheduleId });
      return;
    }

    let nso:INeighborhoodServiceOffering = {
      name: '',
      altName: '',
      serviceOfferingId: serviceOfferingId,
      discountScheduleId: discountScheduleId,
      neighborhoodId: neighborhoodId,
      providerId: serviceProviderId,
      disabledDate: null,
    };

    const request = {
      neighborhoodServiceOfferings: [nso],
    };

    // check if the NSO already exists in Neighborville
    const generatedNsosRes = await findNeighborhoodServiceOfferingByNeighborhoodProviderAndServiceOffering(neighborhoodId, serviceProviderId, serviceOfferingId);

    if (generatedNsosRes.data) {
      console.log('NSO already exists in Neighborville. ', { generatedNsosRes });
      return;
    }

    try {
      return await saveNeighborhoodServiceOffering(request);
    } catch (error) {
      console.error('error creating NSO: ', { error, request });
    }
  }
}