import { Card, CardContent, Typography } from '@mui/material';
import { IServiceOffering } from 'model/serviceOffering';
import { ServiceTypeInfoUtil } from 'util/serviceType/serviceTypeInfoUtil';
import { ISampleServiceCategoryAndServiceTypeName, IServiceTypeInfo } from 'model/serviceCategory';

export interface ITransferServiceOfferingSearchBoxProps {
  serviceOffering:IServiceOffering;
  serviceTypeInfo: IServiceTypeInfo | null;
}

export default function TransferServiceOffering(
  { serviceOffering, serviceTypeInfo }: ITransferServiceOfferingSearchBoxProps,
) {

  var x: ISampleServiceCategoryAndServiceTypeName | null = ServiceTypeInfoUtil.findMatchingServiceCategoryAndServiceTypeNames(serviceOffering.serviceTypeId, serviceTypeInfo!);
  return (
    <Card variant="outlined" sx={{ maxWidth: '450px' }}>
      <CardContent>
        <Typography variant="body1">ID: {serviceOffering.id}</Typography>
        <Typography variant="body1">name: {serviceOffering.name}</Typography>
        {
          serviceOffering.serviceTypeId &&
          <Typography variant="body1">type: {x?.serviceTypeName}</Typography>
        }
      </CardContent>
    </Card>
  );
}