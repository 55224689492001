import { Typography } from '@mui/material';
import { toggleNeighborhoodServiceOfferingsByServiceProvider } from 'api/neighborhoodServiceOfferingApi';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { createEmptyServiceOfferingStatusCheckDTO, IServiceOfferingStatusCheckDTO } from 'model/serviceOffering';
import { formatMonthDayTime } from 'util/dateUtil';
import useServiceProviderTable from '../hookStore/useServiceProviderTable';

export interface IDisableNsoModal {
  name: string;
  id:string;
  wasEnabled:boolean;
  serviceOfferingStatusCheckDTO: IServiceOfferingStatusCheckDTO;
}
export function createEmptyDisableNsoModal() :IDisableNsoModal {
  return {
    name: '',
    id: '',
    wasEnabled: false,
    serviceOfferingStatusCheckDTO: createEmptyServiceOfferingStatusCheckDTO(),
  };
}

export default function DisableNsoModal() {
  const { getModalState, closeModal, open } = useModal('disableNsoModal');
  const { postToggle } = useServiceProviderTable();
  const _state = getModalState() as IDisableNsoModal & IModal;
  const { name, wasEnabled, serviceOfferingStatusCheckDTO } = _state;

  async function _onDisableNsosByServiceProvider(state:IDisableNsoModal & IModal) {
    if (state.wasEnabled) {
      await toggleNeighborhoodServiceOfferingsByServiceProvider(state.id, { shouldEnable: false });
    } else {
      await toggleNeighborhoodServiceOfferingsByServiceProvider(state.id, { shouldEnable: true });
    }
    postToggle(state.id, !state.wasEnabled);
    closeModal(createEmptyDisableNsoModal());
  }
  return (
    <GenericModal
      modalStoreKey={'disableNsoModal'}
      title={wasEnabled ? 'Disable all NSOs for provider?' : 'Enable all NSOs for provider?'}
      modalContent={
        (
          <div >
            <Typography variant='body1' >Do you want to {wasEnabled ? 'disable' : 'enable'} all NSOs for {name}?</Typography>
            {serviceOfferingStatusCheckDTO?.disabledServiceOfferings?.length > 0 && (
              <>
                <Typography variant='body1' sx={{ marginTop: 2 }}>Note the following disabled service offerings:</Typography>
                <ul>
                  {serviceOfferingStatusCheckDTO?.disabledServiceOfferings?.map(x => {
                    return (
                      <li>{x.name} disabled on: {x.disabledNeighborhoodServiceOfferingsDate && formatMonthDayTime(x.disabledNeighborhoodServiceOfferingsDate)}</li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
        )
      }
      onConfirm={() => _onDisableNsosByServiceProvider(_state)}
      secondaryButtonText='Cancel'
    />
  );
}