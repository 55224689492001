import { Link, Typography } from '@mui/material';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import useServiceProviderTable from '../hookStore/useServiceProviderTable';
import { useState } from 'react';

export interface IDeleteServiceProviderModal {
  name: string;
  id:string;

}
export function createEmptyDeleteServiceProviderModal() :IDeleteServiceProviderModal {
  return {
    name: '',
    id: '',
  };
}
export const deleteServiceProviderModalStoreKey = 'DeleteServiceProviderModal';

export default function DeleteServiceProviderModal() {
  const { getModalState, closeModal, open } = useModal(deleteServiceProviderModalStoreKey);
  const { onDeleteServiceProvider } = useServiceProviderTable();
  const _state = getModalState() as IDeleteServiceProviderModal & IModal;
  const { id, name } = _state;
  const [ShowManageDataLink, setShowManageDataLink] = useState(false);

  async function _onDeleteServiceProvider(state:IDeleteServiceProviderModal & IModal) {
    const allowManageDataLink = await onDeleteServiceProvider(id);
    if (allowManageDataLink) {
      setShowManageDataLink(true);
    } else {
      closeModal(createEmptyDeleteServiceProviderModal());
      // clearStoredSearch();
      // location.reload();
    }
  }

  return (
    <GenericModal
      modalStoreKey={deleteServiceProviderModalStoreKey}
      title={ShowManageDataLink ? 'Manage Data' : 'Delete Service Provider'}
      modalContent={
        (
          <>
            {ShowManageDataLink && (
              <div>
                <Typography variant='body1' >Unable to delete {name}, but actions are available:</Typography>
                <Link href={`/service-providers/${id}/transfer-service-provider-data`} sx={{ paddingTop: '20px' }}>
                  <Typography variant='body1' >Move Data</Typography>
                </Link>
              </div>
            )}
            {!ShowManageDataLink && (
              <div >
                <Typography variant='body1' >About to delete service provider and all related entities {name}?</Typography>

              </div>
            )}
          </>
        )
      }
      primaryDisabled={ShowManageDataLink}
      onConfirm={() => _onDeleteServiceProvider(_state)}
      secondaryButtonText='Cancel'
    />
  );
}