import HookTextField from 'components/reactHookForm/HookTextField';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import useToast from 'components/toast/useToast';
import DeleteButton from '../components/delete/DeleteButton';
import Save from '@mui/icons-material/Save';
import CrmStatusSelect from '../components/CrmStatusSelect';
import DebugSelect from '../components/DebugSelect';
import CrmDefaultServiceTypesSelect from '../components/CrmDefaultServiceTypesSelect';
import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { useFormContext } from 'react-hook-form';
import { useLoading } from 'components/Layout/Loading';
import { useParams } from 'react-router-dom';
import { saveHCPMaxIntegration } from 'api/serviceProviderApi';
import { CrmIntegrationType, CrmPartners } from 'model/serviceProvider';

export const houseCallProConfirmationModalKey = 'HouseCallProConfirmationModal';

function HouseCallProConfirmationModal() {
  const { loadingKey, refresh } = useProviderIntegrationDetails();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createSuccessToast, createErrorToast } = useToast();

  return <HookConfirmationModal
    title={() => 'Save Housecall Pro Configuration'}
    body='Save changes? This will will make the Housecall Pro integration ACTIVE.'
    modalKey={houseCallProConfirmationModalKey}
    message={(confirmArgs, cancelModal) => (
      <Grid container spacing={1} alignItems='center' justifyContent='center' paddingTop={'15px'} >
        <Grid item xs={12} paddingBottom={'25px'}>
        Save changes? This will will make the Housecall Pro integration ACTIVE.
        </Grid>
      </Grid>
    )}
    onConfirm={async (formData) => {
      try {
        onLoading();
        const payload = {
          apiKey: formData.apiKey,
          webhookSecret: formData.webhookSecret,
          status: formData.status,
          debug: formData.debug,
          defaultServiceTypeId: formData.defaultServiceType,
        };

        if (serviceProviderId) {
          const resp = await saveHCPMaxIntegration(serviceProviderId, payload);

          if (resp.status === 200) {
            createSuccessToast('Housecall Pro Configuration Saved!');
            await refresh();
          } else {
            createErrorToast('Error saving Housecall Pro Configuration');
          }
        }
      } catch (error) {
        console.error('error saving HCP config', error);
        createErrorToast('Error saving Housecall Pro Configuration');
      } finally {
        doneLoading();
      }
    }}
  />;
}


export default function HouseCAllProFormInputs() {
  const { openModal } = useConfirmationModal(houseCallProConfirmationModalKey);
  const formContext = useFormContext();
  const { createErrorToast } = useToast();
  let formState = formContext.getValues();
  let { displayGenericFormFields } = formState;

  const onSave = () => {
    if (formContext.formState.isValid) {
      const formData = formContext.getValues();
      openModal(formData);
    } else {
      createErrorToast('Please fill out all required fields');
    }
  };

  return (
    <>
      <Grid container>
        { displayGenericFormFields &&
          <Grid container item spacing={1}>
            <Grid item xs={12} lg={3}>
              <CrmStatusSelect />
            </Grid>
            <Grid item xs={12} lg={3}>
              <DebugSelect />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} lg={6}>
                <CrmDefaultServiceTypesSelect />
              </Grid>
            </Grid>
          </Grid>
        }
        <Grid item xs={8}>
          <HookTextField name='apiKey' label='API Key' required />
        </Grid>
        <Grid item xs={12} md={5}>
          <HookTextField name='webhookSecret' label='Webhook Secret' required />
        </Grid>
        <Grid container item spacing={2} paddingTop={'15px'} alignItems={'center'}>
          <Grid item xs={3}>
            <PrimaryButton
              type='submit'
              onSubmit={onSave}
              startIcon={<Save />}
              onClick={onSave}
            >
                Save
            </PrimaryButton>
          </Grid>
          <Grid item xs={3}>
            <DeleteButton partner={CrmPartners.HOUSECALL_PRO} type={CrmIntegrationType.GET_OFF_PLATFORM_JOBS} />
          </Grid>
        </Grid>
      </Grid>
      <HouseCallProConfirmationModal />
    </>
  );
}