import { setFormData } from 'util/formHelpers';
import { createValidateEmail } from 'util/rhfValidateFunctions';
import { sortByString } from 'util/sortFunctions';
import { Grid } from '@mui/material';
import { findIndividualAccounts } from 'api/accountApi';
import { findAllServiceProviders } from 'api/serviceProviderApi';
import { findUserById, saveUser } from 'api/userApi';
import { HookTextField } from 'components/reactHookForm';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { createEmptyUser, IEditableUser } from 'model/user';
import { useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { VerificationMessage } from './userDetails/VerificationMessage';
import { FormButtons } from './userDetails/FormButtons';
import { ServiceProviderSelect } from './userDetails/ServiceProviderSelect';
import { RolesSelect } from './userDetails/RolesSelect';
import { IndividualsSelect } from './userDetails/IndividualsSelect';
import { AccountsSelect } from './userDetails/AccountsSelect';
import useToast from 'components/toast/useToast';
import useUserDetailsStore from './hookStore/useUserDetails';
import Loading from 'components/Layout/Loading';

const validateEmail = createValidateEmail();
export const sortByName = sortByString('name');

interface IUserDetailsFormInternalProps {
  formData: IEditableUser;
}

export function UserDetailsFormInternal(
  { formData }: IUserDetailsFormInternalProps,
) {
  const formContext = useForm<IEditableUser>({
    mode: 'onTouched',
    defaultValues: formData,
  });
  useEffect(() => {
    formContext.reset(formData);
  }, [formData]);

  const { onSaveUser } = useUserDetailsStore();

  const onSubmit : SubmitHandler<IEditableUser> = data => {
    void onSaveUser(data);
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={2} className='pageGridContainer' >
          <Grid container item xs={12}>
            <PageHeader headerText={'Manage user'}/>
            <VerificationMessage/>
          </Grid>
          <Grid item xs={12} md={3}>
            <HookTextField
              label='Email'
              name='email'
              required
              validationRules={{
                validate: validateEmail,
              }}
            />
            <HookTextField
              label='Name'
              name='name'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <RolesSelect/>
          </Grid>
          <Grid item xs={12} md={6}>
            <ServiceProviderSelect/>
          </Grid>
          <Grid item xs={12} md={8}></Grid>
          <Grid item xs={12} md={4}>
            <FormButtons/>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function UserDetails() {
  const { init, formData, loadingKey } = useUserDetailsStore();
  useEffect(() => {
    void init();
  }, []);

  return (
    <Loading loadingKey={loadingKey}>
      <UserDetailsFormInternal formData={formData}/>
    </Loading>

  );
}