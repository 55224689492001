import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import { IMarket } from 'model/markets';
import { deleteServiceProvider } from 'api/serviceProviderApi';
import useTooltipDrawer from 'components/drawer/useTooltipDrawer';

const loadingKey = 'ServiceProviderTableStore';

type ServiceProviderTableStore = {
  toggledMap: { [id: string]: boolean };
  triggerSearchTableCount: number;
}

const { get, update, registerListener, unregisterListener } = createStore<ServiceProviderTableStore>('ServiceProviderTableStore', {
  toggledMap: { },
  triggerSearchTableCount: 0,
});


export default function useServiceProviderTable() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const location = useLocation();
  const { createSuccessToast, createErrorToast, createErrorHandlingToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        toggledMap: {},
      });
      unregisterListener(setState);
    };
  }, []);

  async function init(market:IMarket | null) {

    if (market == null) {
      console.log('waiting to load market');
      //we should wait for the market to load before making any requests
      return;
    }
    onLoading();

    doneLoading(300);
  }

  function postToggle(serviceProviderId:string, wasEnableRequest:boolean) {
    const { toggledMap } = get();
    toggledMap[serviceProviderId] = wasEnableRequest;
    update({
      ...get(),
      toggledMap,
    });
    createSuccessToast(`Service Provider ${wasEnableRequest ? 'enabled' : 'disabled'}`);
  }


  async function onDeleteServiceProvider(id: string):Promise<boolean> {
    let allowManageDataLink = false;
    try {
      const res = await deleteServiceProvider(id);
      if (res.data.errorMessage) {
        if (res.data.invalidStatus) {
          createErrorToast(res.data.errorMessage, null, { persistent: true });
        } else {
          //in the case that we have a non-live service provider, we can allow the admin user to manage the data
          //to finish the deletion process (i.e. transfer provider users, move service provider reviews, etc.)
          allowManageDataLink = true;
        }
      } else if (res.data.message) {
        createSuccessToast(res.data.message);
        update({
          ...get(),
          triggerSearchTableCount: get().triggerSearchTableCount + 1,
        });
      }
    } catch (e:any) {
      console.error(`Unable to delete service provider. Message=${e.response?.data?.message}`);
      createErrorToast(`Unable to delete service provider. Message=${e.response?.data?.message}`);
    }
    return allowManageDataLink;
  }

  return {
    ...get(),
    loadingKey,
    init,
    postToggle,
    onDeleteServiceProvider,
  };
}