import { IAddress } from './address';
import { IMarket } from './markets';

export const PaymentTypes = {
  CREDITCARD: 'CREDIT-CARD',
  VENMO: 'VENMO',
  CASHAPP: 'CASHAPP',
};
export interface IServiceProviderStatusUpdateDTO {
  status:string;
}

export class StatusType {
  static get ACTIVE():string {
    return 'ACTIVE';
  }
  static get PENDING():string {
    return 'PENDING';
  }
  static get DRAFT():string {
    return 'DRAFT';
  }
  static get READY_FOR_APPROVAL():string {
    return 'READY_FOR_APPROVAL';
  }
  static get READY_FOR_ACQUISITION_PLAYBOOK():string {
    return 'READY_FOR_ACQUISITION_PLAYBOOK';
  }
  static get TEST():string {
    return 'TEST';
  }
  static get NOT_INTERESTED():string {
    return 'NOT_INTERESTED';
  }
  static get LAUNCHED():string {
    return 'LAUNCHED';
  }
  static get DELISTED():string {
    return 'DELISTED';
  }
  static get FAILED_TO_CONTACT():string {
    return 'FAILED_TO_CONTACT';
  }
  static get NOT_A_GOOD_FIT():string {
    return 'NOT_A_GOOD_FIT';
  }
  static get BAD_TIMING():string {
    return 'BAD_TIMING';
  }

  static list = [
    { id: StatusType.ACTIVE, name: 'Active' },
    { id: StatusType.PENDING, name: 'Pending' },
    { id: StatusType.DRAFT, name: 'Draft' },
    { id: StatusType.READY_FOR_APPROVAL, name: 'Ready for Approval' },
    { id: StatusType.TEST, name: 'Test' },
    { id: StatusType.NOT_INTERESTED, name: 'Not Interested' },
    { id: StatusType.LAUNCHED, name: 'Launched' },
    { id: StatusType.DELISTED, name: 'Delisted' },
    { id: StatusType.FAILED_TO_CONTACT, name: 'Failed to Contact' },
    { id: StatusType.NOT_A_GOOD_FIT, name: 'Not a Good Fit' },
    { id: StatusType.BAD_TIMING, name: 'Bad Timing' },
  ];

  // The Supply team will be introducing additional statuses for the
  // supply acquisition playbook. We do not want to have to keep track of all of them here
  // and we also do not want to clear out the status accidentally when a user updates a provider
  // so we will return the standard list of statuses plus the current status if it is not already in the list
  static getListIncludingStatus(currentStatus:string) {
    const list = [...StatusType.list];
    const currentStatusIndex = list.findIndex((status) => status.id === currentStatus);
    if (currentStatusIndex > -1 || !currentStatus) {
      return list;
    }
    return list.concat([{ id: currentStatus, name: currentStatus }]);

  }
}

export class StreetFairProviderRatings {
  static get DELISTED():number {
    return 0;
  }
  static get CONCERN():number {
    return 1;
  }
  static get OK():number {
    return 2;
  }
  static get GOOD():number {
    return 3;
  }
  static get STRESS_FREE():number {
    return 4;
  }
  static get POWERUSER():number {
    return 5;
  }
  static list = [
    { id: StreetFairProviderRatings.DELISTED, name: 'Delisted' },
    { id: StreetFairProviderRatings.CONCERN, name: 'Concern' },
    { id: StreetFairProviderRatings.OK, name: 'Just OK / Too New' },
    { id: StreetFairProviderRatings.GOOD, name: 'Good' },
    { id: StreetFairProviderRatings.STRESS_FREE, name: 'Stress Free' },
    { id: StreetFairProviderRatings.POWERUSER, name: 'Power User' },
  ];
}

export interface IServiceProvider {
  approvedBy:string | null;
  contactPersonEmail: string;
  contactPersonFirstName?: string | null;
  contactPersonLastName?: string | null;
  contactPersonName: string;
  contactPersonPhone: string;
  bookingPhone:string | null;
  id: string;
  imageUrl?: string; //to be removed
  imageUrlGuid:string;
  imageKey:string;
  aboutUs?: string;
  legacyId?: number; //to be removed
  name: string;
  rating: {
    numberOfRatings: number | null;
    rating: number | null;
  };
  facebookRating: {
    numberOfRatings: number | null;
    rating: number | null;
  };
  status: string;
  websiteUrl: string; //retired,
  isImageRequired:boolean; // not persisted
  venmoId:string | null;
  cashAppId:string | null;
  paymentTypesAccepted:string[];
  maxServiceInstancesShown:number | null;
  marketId: string | null;
  phoneBookingOptedOut: boolean;
  referringNeighborhoodId?: string | null;
  relevantPlatformReviewsCount?: number | null;
}
export interface IInternalServiceProviderContact {
  email:string;
  firstName:string;
}

export interface IServiceOfferingAllocationDTO {
  serviceOfferingId:string;
  percent:number;
}

export interface IInternalServiceProvider extends IServiceProvider {
  additionalContactPhones: string[];
  transientAdditionalContactPhones: any[];
  additionalContacts: IInternalServiceProviderContact[];
  acceptsCreditCard:boolean;
  acceptsVenmo:boolean;
  acceptsCashApp:boolean;
  oneTimePercent:number | string | null;
  recurringPercent:number | string | null;
  takeRateCap: number | string | null;
  hipFireProvider:boolean;
  inAppChatExcluded:boolean;
  happeningSoonInvitePreferred: boolean | null;
  streetFairProviderRating: number;
  nonStandardPricing:boolean;
  verifiedCustomers:boolean;
  customConditions:string | null;
  lastReconciled:Date | null;
  address?: IAddress | null;
  leadSource?: string | null;
  referringPersonName?: string | null;
  companyHubspotId?: string | null;
  serviceOfferingLaunchDate?: Date | null;
  supplyBatchNumber?: string | null;
  supplyQualitativeScore?: number | null;
  skipFullSap?: boolean | null;
  crm?: string | null;
  requestIdToServiceOfferingAllocationMap?: { [key: string]: IServiceOfferingAllocationDTO[] };
  pauseNotificationsUntilDate?: Date | null;
  neighborhoodNotificationsAllowed: boolean;
  crmIntegrationDetails?: Array<ICrmIntegrationDetails>;
  agreedToPartnership: boolean;
  agreedToPartnershipDate: Date | null;
  agreedToConditions: string | null;
}

export function createEmptyServiceProvider():IServiceProvider {
  return {
    approvedBy: '',
    contactPersonEmail: '',
    contactPersonFirstName: null,
    contactPersonLastName: null,
    contactPersonName: '',
    contactPersonPhone: '',
    bookingPhone: '',
    id: '',
    imageUrl: '',
    imageUrlGuid: '',
    imageKey: '',
    aboutUs: '',
    legacyId: -1,
    name: '',
    rating: {
      numberOfRatings: null,
      rating: null,
    },
    facebookRating: {
      numberOfRatings: null,
      rating: null,
    },
    status: '',
    websiteUrl: '',
    isImageRequired: false,
    venmoId: '',
    cashAppId: '',
    paymentTypesAccepted: [],
    maxServiceInstancesShown: null,
    marketId: null,
    phoneBookingOptedOut: false,
  };
}

export function createEmptyInternalServiceProvider():IInternalServiceProvider {
  return {
    ...createEmptyServiceProvider(),
    additionalContactPhones: [],
    address: {
      addressLineOne: '',
      city: '',
      state: '',
      zipCode: '',
    },
    leadSource: '',
    transientAdditionalContactPhones: [],
    additionalContacts: [],
    acceptsCreditCard: false,
    acceptsVenmo: false,
    acceptsCashApp: false,
    recurringPercent: null,
    oneTimePercent: null,
    takeRateCap: null,
    happeningSoonInvitePreferred: false,
    hipFireProvider: false,
    inAppChatExcluded: false,
    streetFairProviderRating: 2,
    nonStandardPricing: false,
    verifiedCustomers: false,
    customConditions: '',
    status: StatusType.PENDING,
    marketId: '',
    lastReconciled: null,
    skipFullSap: false,
    crm: '',
    neighborhoodNotificationsAllowed: false,
    agreedToPartnership: false,
    agreedToPartnershipDate: null,
    agreedToConditions: '',
  };
}

export function createEmptyInternalServiceProviderWithDefaults( market:IMarket | null):IInternalServiceProvider {
  const servicePro = {
    ...createEmptyServiceProvider(),
    additionalContactPhones: [],
    transientAdditionalContactPhones: [],
    additionalContacts: [],
    acceptsCreditCard: false,
    acceptsVenmo: false,
    acceptsCashApp: false,
    recurringPercent: '',
    oneTimePercent: 0.08,
    takeRateCap: '',
    happeningSoonInvitePreferred: false,
    hipFireProvider: false,
    inAppChatExcluded: false,
    streetFairProviderRating: StreetFairProviderRatings.OK,
    nonStandardPricing: false,
    verifiedCustomers: false,
    customConditions: '',
    status: StatusType.DRAFT,
    lastReconciled: null,
    crm: '',
    neighborhoodNotificationsAllowed: false,
    agreedToPartnership: false,
    agreedToPartnershipDate: null,
    agreedToConditions: '',
  };

  if (market?.id) {
    servicePro.marketId = market.id;
  }

  return servicePro;
}

export interface IApiKeyDTO {
  apiKey: string;
}

export interface IServiceProviderDeleteResult {
  hasServiceOfferings:boolean;
  hasServiceProviderReviews:boolean;
  hasCustomers:boolean;
  hasSubscribers:boolean;
  hasCometChatUser:boolean;
  hasServiceProviderConnections:boolean;
  hasCoverageAreas:boolean;
  hasProviderUsers:boolean;
  hasServiceSummaries:boolean;
  hasDiscountSchedules:boolean;
  hasIndividuals:boolean;
  hasTsoiManagementBatch:boolean;
  hasNsos:boolean;
  hasNsois:boolean;

  message:string | null;
  errorMessage:string | null;
  invalidStatus:boolean;
}

export interface IClaimYourBusinessRequestDTO {
  referringNeighborhoodId: string;
  nsoId: string;
  reference?: string;
}

export interface IApproveForSapDTO {
  referringNeighborhoodId: string;
  referringPersonName?: string;
  companyHubspotId?: string;
  skipFullSap?: boolean;
}

export class LeadSource {
  static get AngiHomeAdvisor():string {
    return 'Angi/HomeAdvisor';
  }
  static get FacebookSearching():string {
    return 'Facebook (searching)';
  }
  static get Google():string {
    return 'Google';
  }
  static get HOANewsletterDirectory():string {
    return 'HOA Newsletter/Directory';
  }
  static get Event():string {
    return 'Event (expo, chamber event, conference, etc.)';
  }
  static get WebsiteInbound():string {
    return 'Website Inbound';
  }
  static get LKNSBN():string {
    return 'LKNSBN (direct)';
  }
  static get MarketingMailer():string {
    return 'Marketing Mailer';
  }
  static get NextDoor():string {
    return 'NextDoor';
  }
  static get ReferralfromSFPro():string {
    return 'Referral from SF Pro';
  }
  static get RoadSighting():string {
    return 'Road Sighting';
  }
  static get SalesIdentifiedLead():string {
    return 'Sales Identified Lead';
  }
  static get Seamless():string {
    return 'Seamless';
  }
  static get Thumbtack():string {
    return 'Thumbtack';
  }
  static get UserRecommended():string {
    return 'User Recommended';
  }
  static get Yelp():string {
    return 'Yelp';
  }
  static get Jobber():string {
    return 'Jobber';
  }

  static list = [
    { id: LeadSource.AngiHomeAdvisor, name: LeadSource.AngiHomeAdvisor },
    { id: LeadSource.FacebookSearching, name: LeadSource.FacebookSearching },
    { id: LeadSource.Google, name: LeadSource.Google },
    { id: LeadSource.HOANewsletterDirectory, name: LeadSource.HOANewsletterDirectory },
    { id: LeadSource.Event, name: LeadSource.Event },
    { id: LeadSource.WebsiteInbound, name: LeadSource.WebsiteInbound },
    { id: LeadSource.LKNSBN, name: LeadSource.LKNSBN },
    { id: LeadSource.MarketingMailer, name: LeadSource.MarketingMailer },
    { id: LeadSource.NextDoor, name: LeadSource.NextDoor },
    { id: LeadSource.ReferralfromSFPro, name: LeadSource.ReferralfromSFPro },
    { id: LeadSource.RoadSighting, name: LeadSource.RoadSighting },
    { id: LeadSource.SalesIdentifiedLead, name: LeadSource.SalesIdentifiedLead },
    { id: LeadSource.Seamless, name: LeadSource.Seamless },
    { id: LeadSource.Thumbtack, name: LeadSource.Thumbtack },
    { id: LeadSource.UserRecommended, name: LeadSource.UserRecommended },
    { id: LeadSource.Yelp, name: LeadSource.Yelp },
    { id: LeadSource.Jobber, name: LeadSource.Jobber },
  ];
};


export interface IServiceSummaryUpdateRequest {
  useLiveData:boolean;
}

export interface ICrmIntegrationDetails {
  partner?:string;
  type?:string;
  debug:boolean;
  status? :string;
  statusChanged?:Date;
  settings:ICrmIntegrationSettings;
}

export interface ICrmIntegrationSettings {
  serviceTypes?:ICrmIntegrationSettingServiceType[];
  recontactDays?:number;
  recontactDaysRepeat?:number;
  maxPerBatch?:number;
  fromEmailName?:string;
  fromEmail?:string;
  businessOwnerFirstName?:string;
  // private String sendGridTemplate;

  // SMS only
  // private String smsTemplate;
  multiProviderMapping?:ICrmIntegrationSettingConfiguration;
}

export interface ICrmIntegrationSettingServiceType {
  serviceTypeId:string;
  default:boolean;
  serviceTypeName:string;
  // private CrmIntegrationSettingConfiguration configuration; // not supported in Admin Portal yet
}

export interface ICrmIntegrationSettingConfiguration {
  group?:string;
  // config?:ICrmIntegrationSettingConfiguration[]; // not supported in Admin Portal yet
  key?:string;
  operator?:string;
  value?:string;
}

export class CrmIntegrationStatus {
  static get ACTIVE():string {
    return 'ACTIVE';
  }
  static get PENDING():string {
    return 'PENDING';
  }
  static get CONNECTED():string {
    return 'CONNECTED';
  }
  static get READY():string {
    return 'READY';
  }
  static get DISABLED():string {
    return 'DISABLED';
  }

  static list = [
    { id: CrmIntegrationStatus.ACTIVE, name: 'Active' },
    { id: CrmIntegrationStatus.PENDING, name: 'Pending' },
    { id: CrmIntegrationStatus.CONNECTED, name: 'Connected' },
    { id: CrmIntegrationStatus.READY, name: 'Ready' },
    { id: CrmIntegrationStatus.DISABLED, name: 'Disabled' },
  ];
}

export class CrmPartners {
  static get STREETFAIR():string {
    return 'streetfair';
  }
  static get JOBBER():string {
    return 'jobber';
  }
  static get ZAPIER():string {
    return 'zapier';
  }
  static get SERVICETITAN():string {
    return 'service-titan';
  }
  static get HOUSECALL_PRO():string {
    return 'housecall-pro';
  }
  static get EMAIL():string {
    return 'email';
  }
}

export class CrmIntegrationType {
  static get GET_OFF_PLATFORM_JOBS():string {
    return 'GET_OFF_PLATFORM_JOBS';
  }
  static get NOTIFY_NEARBY_NEIGHBORS():string {
    return 'NOTIFY_NEARBY_NEIGHBORS';
  }
  static get NOTIFY_NEARBY_NEIGHBORS_SMS():string {
    return 'NOTIFY_NEARBY_NEIGHBORS_SMS';
  }
  static get NOTIFY_NEARBY_NEIGHBORS_POSTCARD():string {
    return 'NOTIFY_NEARBY_NEIGHBORS_POSTCARD';
  }
  static get GDM5_EMAIL():string {
    return 'GDM5_EMAIL';
  }
}

export interface IGenericIntegrationForm {
  partner:string;
  status: string;
  debug: boolean;
  type?:string;
  defaultServiceType?:string;
  serviceTypes?:ICrmIntegrationSettingServiceType[];
}

export function createEmptyGenericIntegrationFormForPartner(partner:string):IGenericIntegrationForm {
  return {
    partner: partner,
    status: CrmIntegrationStatus.DISABLED,
    debug: false,
  };
}

export interface INeighborhoodNudgeForm extends IGenericIntegrationForm {
  recontactDays?:number;
  recontactDaysRepeat?:number;
  businessOwnerFirstName?:string;
  maxPerBatch:number;
}

export class NeighborhoodNudgeDefaults {
  static get MAX_PER_BATCH():number {
    return 100;
  }
}

export function createEmptyNeighborhoodNudgeForm():INeighborhoodNudgeForm {
  return {
    ...createEmptyGenericIntegrationFormForPartner(CrmPartners.STREETFAIR),
    recontactDays: 0,
    recontactDaysRepeat: 0,
    businessOwnerFirstName: '',
    maxPerBatch: 0,
  };
}
