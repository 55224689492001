import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { NeighborhoodRating } from 'model/neighborhood';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';


export default function RatingSelect() {
  const formCtx = useFormContext();
  const [ratingOptions] = useState<any[]>(NeighborhoodRating.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('rating')}>
      <InputLabel htmlFor="rating">Rating</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: false, message: 'Required' },
        }}
        name='rating'
        id='rating'
        menuItemKeys={{
          key: 'id',
          value: 'id',
          displayValue: 'name',
        }}
        menuItems={ratingOptions}
      />
      <FormHelperText>{getErrorMessage('rating')}</FormHelperText>
    </FormControl>
  );
}