import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import CrmStatusSelect from '../components/CrmStatusSelect';
import DebugSelect from '../components/DebugSelect';
import DeleteButton from '../components/delete/DeleteButton';
import { Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { CrmIntegrationType, CrmPartners, IGenericIntegrationForm } from 'model/serviceProvider';
import CrmDefaultServiceTypesSelect from '../components/CrmDefaultServiceTypesSelect';
import { emailConfirmationModalKey, EmailConfirmationModal } from './EmailConfirmationModal';
import { PrimaryButton } from 'components/buttons';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import Save from '@mui/icons-material/Save';

export default function Email() {
  const { emailFormData } = useProviderIntegrationDetails();
  const formContext = useForm<IGenericIntegrationForm>({ mode: 'onTouched', defaultValues: emailFormData });
  const { openModal } = useConfirmationModal(emailConfirmationModalKey);

  useEffect(() => {
    formContext.reset(emailFormData);
  }, [emailFormData]);

  const onSave = () => {
    const formState = formContext.getValues();
    openModal(formState);
  };

  return (
    <>
      <FormProvider {...formContext}>
        <Grid container>
          <Grid container alignItems={'center'} paddingBottom={'15px'}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Email</Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }} paddingLeft={'8px'}>({emailFormData.status})</Typography>
          </Grid>

          <Grid container item spacing={1}>
            <Grid item xs={12} lg={3}>
              <CrmStatusSelect />
            </Grid>
            <Grid item xs={12} lg={3}>
              <DebugSelect />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} lg={6}>
              <CrmDefaultServiceTypesSelect />
            </Grid>
          </Grid>

          <Grid container item paddingTop={'25px'} spacing={2} alignItems={'center'}>
            <Grid item xs={12} lg={3} paddingRight={'30px'}>
              <PrimaryButton
                onClick={onSave}
                onSubmit={onSave}
                startIcon={<Save />}
              >
                Save
              </PrimaryButton>
            </Grid>
            <Grid item xs={12} lg={3}>
              <DeleteButton partner={CrmPartners.EMAIL} type={CrmIntegrationType.GET_OFF_PLATFORM_JOBS} />
            </Grid>
          </Grid>
        </Grid>

        <EmailConfirmationModal />
      </FormProvider>
    </>
  );
}