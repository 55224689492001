import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { ManageServiceTypeMode } from 'model/serviceCategory';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMarket } from 'model/markets';
import { ITerritory } from 'model/territories';
import { findMarketById } from 'api/marketsApi';
import { findTerritoryById } from 'api/territoriesApi';
import useMarkets from 'hooks/useMarkets';
import useModal from 'components/modal/useModal';
import { createEmptySelectMarketModal, modalStoreKey as selectMarketModalKey } from '../components/pageConfigModal/SelectMarketModal';
import { createEmptySelectTerritoryModal, modalStoreKey as selectTerritoryModalKey } from '../components/pageConfigModal/SelectTerritoryModal';
import { IServiceType, IServiceTypeAdminDTO, IServiceTypeAdminRequest, IServiceTypeDetailForm, createEmptyServiceTypeDetailForm, createServiceTypeAdminDTOFromForm } from 'model/serviceType';
import { findServiceTypeAdminDTO, saveServiceTypeAdminDTO } from 'api/serviceTypeApi';

const loadingKey = 'ServiceTypeDetailStore';
type ServiceTypeDetailStore = {
  formData:IServiceTypeDetailForm;
  prevSelectedTabs: string[];
  selectedTab: string;
  selectedMarket: IMarket | null;
  selectedTerritory:ITerritory | null;
}

const { get, update, registerListener, unregisterListener } = createStore<ServiceTypeDetailStore>('ServiceTypeDetailStore', {
  formData: createEmptyServiceTypeDetailForm(),
  prevSelectedTabs: ['1'],
  selectedTab: '1',
  selectedMarket: null,
  selectedTerritory: null,
});

export default function useServiceTypeDetail() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();
  const { serviceTypeId, mode, marketId, territoryId } = useParams();
  const { selectedMarket: savedMarket } = useMarkets();
  const { openModal: openSelectMarketModal } = useModal(selectMarketModalKey);
  const { openModal: openSelectTerritoryModal } = useModal(selectTerritoryModalKey);

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      if (mode === ManageServiceTypeMode.CREATE) {
        update({
          ...get(),
          formData: createEmptyServiceTypeDetailForm(),
          selectedTab: '1',
          prevSelectedTabs: ['1'],
          selectedMarket: null,
          selectedTerritory: null,
        });
      } else if (
        mode === ManageServiceTypeMode.EDIT_DEFAULT ||
        mode === ManageServiceTypeMode.EDIT_MARKET ||
        mode === ManageServiceTypeMode.EDIT_TERRITORY
      ) {
        await refresh();
      }
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function refresh() {

    if (!serviceTypeId) {
      return;
    }
    let data:IServiceTypeAdminRequest | null = null;
    let _selectedTab = '1';
    let selectedMarket: IMarket | null = null;
    let selectedTerritory: ITerritory | null = null;
    if (marketId && !territoryId) {
      data = {
        serviceTypeId: serviceTypeId,
        marketId: marketId,
        zipCodeTerritory: null,
      };
      let marketRes = await findMarketById(marketId);
      if (marketRes.data) {
        selectedMarket = marketRes.data;
      }
      _selectedTab = '2';
    } else if (marketId && territoryId) {

      let [marketRes, territoryRes] = await Promise.all([
        findMarketById(marketId),
        findTerritoryById(marketId, territoryId),
      ]);
      if (marketRes.data) {
        selectedMarket = marketRes.data;
      }
      if (territoryRes.data) {
        selectedTerritory = territoryRes.data;
      }
      if (!selectedMarket || !selectedTerritory) {
        createErrorToast('Error loading service category');
        return;
      }
      data = {
        serviceTypeId: serviceTypeId,
        marketId: marketId,
        zipCodeTerritory: selectedTerritory!.zipCodeTerritory,
      };
      _selectedTab = '3';
    } else {
      data = {
        serviceTypeId: serviceTypeId,
        marketId: null,
        zipCodeTerritory: null,
      };
      _selectedTab = '1';
    }
    if (!data) {
      createErrorToast('Error loading service category');
      return;
    }
    let adminDtoRes = await findServiceTypeAdminDTO(data);
    if (adminDtoRes.data) {
      update({
        ...get(),
        formData: createForm(adminDtoRes.data, selectedTerritory?.zipCodeTerritory ?? null),
        selectedTab: _selectedTab,
        prevSelectedTabs: [_selectedTab],
        selectedMarket: selectedMarket,
        selectedTerritory: selectedTerritory,
      });
    }

  }

  function createForm(
    dto:IServiceTypeAdminDTO,
    zipCodeTerritory:string | null,
  ):IServiceTypeDetailForm {
    return {
      colorScheme: dto.colorScheme,
      displayName: dto.displayName,
      enabled: dto.enabled,
      imageGuid: dto.imageGuid,
      marketId: dto.marketId ?? marketId ?? null,
      name: dto.name,
      searchResultCardStyle: dto.searchResultCardStyle,
      serviceTypeCategoryMetadata: dto.serviceTypeCategoryMetadata,
      serviceTypeId: dto.serviceTypeId,
      serviceTypePriority: dto.serviceTypePriority,
      tags: dto.tags.map(x => ({ value: x })),
      zipCodeTerritory: dto.zipCodeTerritory ?? zipCodeTerritory,
      oneTimePercent: !dto.oneTimePercent ? null : dto.oneTimePercent.toString(),
      recurringPercent: !dto.recurringPercent ? null : dto.recurringPercent.toString(),
      takeRateCap: !dto.takeRateCap ? null : dto.takeRateCap.toString(),
      introductoryOffer: !dto.introductoryOffer ? null : dto.introductoryOffer.toString(),
      aliases: dto.aliases.map(x => ({ value: x })),
      isRebookEnabled: dto.isRebookEnabled,
      neighborhoodNudgeEnabled: dto.neighborhoodNudgeEnabled,
      neighborhoodNudgeLookBackInDays: dto.neighborhoodNudgeLookBackInDays,
    };
  }

  async function save(formData:IServiceTypeDetailForm) {
    onLoading();

    const isValid = preSubmitValidation(formData);
    if (!isValid) {
      doneLoading();
      return;
    }

    var adminDto = createServiceTypeAdminDTOFromForm(formData);
    try {
      const result = await saveServiceTypeAdminDTO(adminDto);
      if (serviceTypeId === 'unknown') {
        navigate('/serviceTypeInfoTabs');
      }
      window.location.reload();
    } catch (e:any) {
      console.error(e);
      createErrorToast('Unable to save service type');
    }
    doneLoading(300);
  }

  function preSubmitValidation(formData:IServiceTypeDetailForm):boolean {
    if (!formData.imageGuid) {
      createErrorToast('Must supply an image');
      return false;
    }
    return true;
  }

  function cancel() {
    navigate('/serviceTypeInfoTabs');
  }

  function onSelectTab(event: React.SyntheticEvent, newValue: string) {
    const { prevSelectedTabs, selectedTab } = get();
    if (newValue == '1') {
      navigate(`/serviceTypes/${serviceTypeId}/service-type-details/${ManageServiceTypeMode.EDIT_DEFAULT}`);
      window.location.reload();
    } else if (newValue == '2') {
      let state = createEmptySelectMarketModal();
      state.marketId = savedMarket?.id ?? '';
      openSelectMarketModal(state);
    } else if (newValue == '3') {
      let state = createEmptySelectTerritoryModal();
      state.marketId = savedMarket?.id ?? '';
      openSelectTerritoryModal(state);
    }
    let updatedPrevSelectedTabs = [...prevSelectedTabs, selectedTab];
    update({
      ...get(),
      prevSelectedTabs: updatedPrevSelectedTabs,
      selectedTab: newValue,
    });
  }

  function revertSelectedTab() {
    const { prevSelectedTabs } = get();
    if (prevSelectedTabs && prevSelectedTabs.length > 0) {
      let updatedPrevSelectedTabs = [...prevSelectedTabs];
      let popped = updatedPrevSelectedTabs.pop();
      if (popped) {
        update({
          ...get(),
          prevSelectedTabs: updatedPrevSelectedTabs,
          selectedTab: popped,
        });
      }
    }
  }

  return {
    ...get(),
    loadingKey,
    init,
    save,
    cancel,
    revertSelectedTab,
    onSelectTab,
  };
}