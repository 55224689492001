import { IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useRef } from 'react';
import useUsersTable from '../hookStore/useUsersTable';

export default function SearchUsersInput() {
  const ref = useRef(null);
  const { onFindUserByEmail, updateSearchedEmail } = useUsersTable();

  function onEmailKeyDown(e) {
    if (e.keyCode === 13) {
      void onFindUserByEmail();
    }
  }
  function handleClearClick() {
    (ref?.current as any).value = '';
  }

  return (
    <TextField
      variant='outlined'
      label='email'
      size='small'
      inputRef={ref}
      onKeyDown={onEmailKeyDown}
      onChange={(e) => updateSearchedEmail(e.target.value)}
      fullWidth
      InputProps={{
        autoComplete: 'email',
        endAdornment: (
          <IconButton
            size='small'
            onClick={handleClearClick}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
}