import { useFormContext, useWatch } from 'react-hook-form';
import ServiceProviderMultiAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderMultiAutocomplete';

export function ServiceProviderSelect() {
  const formCtx = useFormContext();
  const watched = useWatch({ control: formCtx.control, name: 'serviceProviders' });

  return (
    <ServiceProviderMultiAutocomplete
      placeholder=''
      label="Service Providers"
      formless={false}
      fieldName='serviceProviders'
      required
      controlledValues={watched} />
  );
}
