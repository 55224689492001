import _ from 'lodash';
import { HookTextField } from 'components/reactHookForm';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Button, Drawer, Typography } from '@mui/material';
import { useState } from 'react';
import { getServiceTitanBusinessUnits } from 'api/serviceProviderApi';
import { useParams } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';

export default function BusinessUnitIdsInput() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [businessUnits, setBusinessUnits] = useState([] as any[]);
  const { serviceProviderId } = useParams();
  const { createSuccessToast, createErrorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const toggleDrawer = (open) => async (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
    if (open && serviceProviderId) {
      try {
        setIsLoading(true);
        var resp = await getServiceTitanBusinessUnits(serviceProviderId);

        if (resp.data?.success && !_.isEmpty(resp.data.data)) {
          setBusinessUnits(resp.data.data);
        } else {
          createErrorToast('Failed to get business units');
        }
      } catch (e) {
        console.error(e);
        createErrorToast('Failed to get business units');
      }
      setIsLoading(false);
    }
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(JSON.stringify(businessUnits, null, 2));
    createSuccessToast('Business Units copied to clipboard');
  };

  return (
    <>
      <Grid container item alignItems={'center'} xs={12}>
        <Grid item xs={7}>
          <HookTextField name='businessUnitIds' label='Business Unit Ids (optional)' fullWidth />
        </Grid>

        <Grid item xs={1}>
          <Tooltip title="Used in the scenario where a single ServiceTitan instance is used across multiple different ServiceProviders, such as when operating across multiple markets. Enter the IDs of the business units separated by commas. (e.g. 12321,4566,7789999)">
            <IconButton>
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={4}>
          <Button variant="contained" onClick={toggleDrawer(true)} startIcon={<SearchIcon />}>View Business Units</Button>
        </Grid>
      </Grid>

      <Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer(false)} sx={{ width: '500px' }}>
        <Grid container maxWidth={'800px'}>
          <Grid container item xs={12} sx={{ padding: '10px' }} alignItems={'center'}>
            <Typography variant='h6'>Business Units</Typography>
            <Tooltip title="Copy to Clipboard">
              <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon color='primary'/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid container item >
            <Typography component="pre" sx={{ backgroundColor: '#f5f5f5', padding: '10px', margin: '10px', borderRadius: '4px', overflow: 'auto' }}>
              { isLoading ? 'Loading...' : JSON.stringify(businessUnits, null, 2)}
            </Typography>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}
