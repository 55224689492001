import { IServiceTypeInfo } from './serviceCategory';


export class ServiceTypePriority {
  static PRIMARY = 'PRIMARY';
  static SECONDARY = 'SECONDARY';
  static TERTIARY = 'TERTIARY';
  static list = [
    { id: 'PRIMARY', name: 'Primary (Core)' },
    { id: 'SECONDARY', name: 'Secondary' },
    { id: 'TERTIARY', name: 'Tertiary' },
  ];
}

export interface IServiceType {
  pk:string;
  sk:string;
  serviceTypeId:string;
  marketId:string | null;
  name:string;
  displayName:string | null;
  colorScheme:string;
  imageGuid:string;
  searchResultCardStyle:string | null;
  tags:string[];
  serviceTypeCategoryMetadata:IServiceTypeCategoryMetadata[];
  serviceTypePriority:string;
  enabled:boolean;
  zipCodeTerritory:string | null;
  deleteDate: string | null;
  aliases:string[];
  neighborhoodNudgeLookBackInDays?: number | null;
}

export interface IServiceTypeCategoryMetadata {
  serviceCategoryId:string;
  sortOrder:number | null;
}

export interface IServiceTypeDetailForm {
  serviceTypeId:string;
  marketId:string | null;
  name:string;
  displayName:string | null;
  colorScheme:string;
  imageGuid:string;
  searchResultCardStyle:string | null;
  tags:ITagValue[];
  serviceTypeCategoryMetadata:IServiceTypeCategoryMetadata[];
  serviceTypePriority:string;
  enabled:boolean;
  zipCodeTerritory:string | null;
  oneTimePercent: string | null;
  recurringPercent: string | null;
  takeRateCap: string | null;
  introductoryOffer: string | null;
  aliases:IAliasValue[];
  isRebookEnabled: boolean;
  neighborhoodNudgeEnabled: boolean;
  neighborhoodNudgeLookBackInDays: number | null;
}

export interface ITagValue {
  value:string;
}

export interface IAliasValue {
  value:string;
}

export function createEmptyServiceTypeDetailForm():IServiceTypeDetailForm {
  return {
    serviceTypeId: '',
    marketId: '',
    name: '',
    displayName: '',
    colorScheme: '',
    imageGuid: '',
    zipCodeTerritory: '',
    searchResultCardStyle: null,
    tags: [],
    serviceTypeCategoryMetadata: [],
    serviceTypePriority: ServiceTypePriority.PRIMARY,
    enabled: true,
    oneTimePercent: null,
    recurringPercent: null,
    takeRateCap: null,
    introductoryOffer: null,
    aliases: [],
    isRebookEnabled: false,
    neighborhoodNudgeEnabled: false,
    neighborhoodNudgeLookBackInDays: null,
  };
}


export interface IServiceTypeAdminRequest {
  serviceTypeId:string | null;
  marketId:string | null;
  zipCodeTerritory:string | null;
}


export interface IServiceTypeAdminDTO {
  serviceTypeId:string;
  marketId:string | null;
  name:string;
  displayName:string | null;
  colorScheme:string;
  imageGuid:string;
  searchResultCardStyle:string | null;
  tags:string[];
  serviceTypeCategoryMetadata:IServiceTypeCategoryMetadata[];
  serviceTypePriority:string;
  enabled:boolean;
  zipCodeTerritory:string | null;
  serviceTypeInfo: IServiceTypeInfo | null;
  oneTimePercent: number | null;
  recurringPercent: number | null;
  takeRateCap: number | null;
  introductoryOffer: number | null;
  aliases:string[];
  isRebookEnabled: boolean;
  neighborhoodNudgeEnabled: boolean;
  neighborhoodNudgeLookBackInDays: number | null;
}

/**
 * This maps to the neighborhoodNudgeLookBackInDays number
 * value on @IServiceType
 *
 * The list is used by a Select dropdown on the ServiceType details screen.
 * The name is a human readible label that indicates the annual reminder cadence for a service type.
 * The id represents the number of days to look back when determining if we should send a neighborhood nudge
 * to a consumer user. The id value is intentionally less than the total number of days represented by the label to
 * ensure we reach out before the actual anniversary date.
 */
export class AnnualReminderCadence {
  static list = [
    {
      id: null,
      name: 'None',
    },
    {
      id: 60,
      name: '3 months',
    },
    {
      id: 150,
      name: '6 months',
    },
    {
      id: 225,
      name: '9 months',
    },
    {
      id: 315,
      name: '12 months',
    },
    {
      id: 700,
      name: '2 years',
    },
    {
      id: 1000,
      name: '3 years',
    },
  ];
}

export function createServiceTypeAdminDTOFromForm(form:IServiceTypeDetailForm):IServiceTypeAdminDTO {
  return {
    serviceTypeId: form.serviceTypeId,
    marketId: form.marketId,
    name: form.name,
    displayName: form.displayName,
    colorScheme: form.colorScheme,
    imageGuid: form.imageGuid,
    searchResultCardStyle: form.searchResultCardStyle,
    tags: form.tags.map(x => x.value),
    serviceTypeCategoryMetadata: form.serviceTypeCategoryMetadata,
    serviceTypePriority: form.serviceTypePriority,
    enabled: form.enabled,
    zipCodeTerritory: form.zipCodeTerritory,
    serviceTypeInfo: null,
    oneTimePercent: !form.oneTimePercent ? null : parseFloat(form.oneTimePercent),
    recurringPercent: !form.recurringPercent ? null : parseFloat(form.recurringPercent),
    takeRateCap: !form.takeRateCap ? null : parseFloat(form.takeRateCap),
    introductoryOffer: !form.introductoryOffer ? null : parseFloat(form.introductoryOffer),
    aliases: form.aliases.map(x => x.value),
    isRebookEnabled: form.isRebookEnabled,
    neighborhoodNudgeEnabled: form.neighborhoodNudgeEnabled,
    neighborhoodNudgeLookBackInDays: form.neighborhoodNudgeLookBackInDays,
  };
}