import { Grid, Typography } from '@mui/material';
import useTransferServiceProviderData from '../hookStore/useTransferServiceProviderData';
import { PrimaryButton } from 'components/buttons';
import TransferServiceOffering from './TransferServiceOffering';

export default function TransferServiceOfferings() {
  const {
    serviceOfferings,
    serviceTypeInfo,
    onTransferServiceOfferings,
    transferServiceProviderId,
  } = useTransferServiceProviderData();

  if (serviceOfferings.length === 0) {
    return null;
  }

  return (
    <div>
      <Typography variant="h6">Service Offerings</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2} >
            <Grid item >
              <PrimaryButton
                disabled={!transferServiceProviderId}
                onClick={onTransferServiceOfferings}
              >
              Transfer All
              </PrimaryButton>
            </Grid>

          </Grid>
        </Grid>
        {serviceOfferings.map(serviceOffering => (
          <Grid item xs='auto' key={serviceOffering.id}>
            <TransferServiceOffering serviceOffering={serviceOffering} serviceTypeInfo={serviceTypeInfo}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}