import { EditButton } from 'components/buttons';
import styles from './serviceProviders.module.css';
import { useNavigate } from 'react-router-dom';

export default function EditCell({ id, name }) {
  const navigate = useNavigate();

  function handleClick() {
    navigate(`/service-providers/${id}/service-provider-details`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}
