import { Grid } from '@mui/material';
import { ProviderSelect } from '../providerInformation/ProviderSelect';
import { useNavigate } from 'react-router-dom';
import RecentProviders from './RecentProviders';

export default function ProviderSetupLanding() {
  const navigate = useNavigate();
  return (
    <Grid container item xs={12} sx={{ margin: '50px 20px 0 20px' }} alignContent={'center'} justifyContent={'center'}>
      <Grid item xs={12} sx={{ height: '20px' }}></Grid>
      <Grid item xs={12} md={3}>
        <ProviderSelect />
      </Grid>
      <Grid item xs={12} sx={{ height: '20px' }}></Grid>
      <RecentProviders/>
    </Grid>
  );
}