import { AutocompleteChangeReason, Grid, Typography } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import Loading from 'components/Layout/Loading';
import { useLocation, useParams } from 'react-router-dom';
import useTransferServiceProviderData from './hookStore/useTransferServiceProviderData';
import TransferServiceOfferings from './transferServiceOfferings/TransferServiceOfferings';
import { useEffect } from 'react';
import TransferServiceProviderSearchBox from './common/TransferServiceProviderSearchBox';
import { IDropdownOption } from 'model/dropdown';
import TransferReviews from './transferReviews/TransferReviews';
import DeleteServiceProvider from './common/DeleteServiceProvider';
import FromServiceProviderSummary from './common/FromServiceProviderSummary';
import TransferProviderPartnershipData from './transferPartnershipAgreement/TransferProviderPartnershipData';
import TranferCoverageAreas from './transferProviderCoverageAreas/TransferCoverageAreas';
import TransferUsers from './transferExistingUsers/TransferUsers';

export default function TransferServiceProviderData() {
  const { serviceProviderId } = useParams();
  const { loadingKey, init, setTransferServiceProvider } = useTransferServiceProviderData();

  useEffect(() => {
    void init();
  }
  , [serviceProviderId]);

  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container sx={{ padding: '10px' }}>
        <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
          <Typography variant="h4">Transfer Service Provider Data</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={'auto'}>
            <FromServiceProviderSummary/>
          </Grid>
          <Grid item sx={{ marginLeft: '10px', marginTop: '32px' }}>
            <DeleteServiceProvider/>
          </Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '32px' }}>

          <Grid item xs='auto' sx={{ minWidth: '300px' }}>
            <TransferServiceProviderSearchBox
              postOnChange={ (formCtx: any, selectedOption: IDropdownOption | null, reason: AutocompleteChangeReason, details: any) => {
                const selectedServiceProviderId = selectedOption?.optionValue;
                if (selectedServiceProviderId) {
                  void setTransferServiceProvider(selectedServiceProviderId);
                }
              }}
            />
          </Grid>

        </Grid>
        <TransferProviderPartnershipData/>
        <Grid item xs={12} sx={{ paddingBottom: '32px' }}>
          <TransferUsers/>
        </Grid>
        <Grid item xs={12} sx={{ paddingBottom: '32px' }}>
          <TranferCoverageAreas/>
        </Grid>
        <Grid item xs={12} sx={{ paddingBottom: '32px' }}>
          <TransferServiceOfferings/>
        </Grid>
        <Grid item xs={12}>
          <TransferReviews/>
        </Grid>
        <Grid container item xs={12} className='pageGridContainer'>
          <Grid container item xs={12} sx={{
            'marginBottom': '10px',
            '&': {
              maxWidth: '1300px',
            },
          }}
          >
          </Grid>

        </Grid>
      </Grid>

    </Loading>
  );
}