import { NeighborhoodLetterStatus } from './ancillary';

export interface ISearchLetterBatchRequest {
  marketId:string | null;
  batchName:string | null;
  externalBatchId: string | null;
  batchStatus:LetterBatchStatus | null;
  pageNumber: number | null;
  pageSize:number | null;
}

export interface ILetterBatchPage {
  letterBatches:ILetterBatch[];
  availableCount:number;
}

export interface ILetterBatch {
  id:string | null;
  batchName:string;
  externalBatchId: string | null;
  batchStatus:string;
  sendDate:Date;
  exportDate:Date | null;
  marketId:string | null;
  createDate:Date | null;
  updateDate:Date | null;
  deleteDate: Date | null;
  projectedLaunchDate: Date | null;
  lettrLabsOrderIdToStatus: { [key: string]: string } | null;
  batchConfig: { [key: string]: any } | null;
}

export function createEmptyLetterBatch():ILetterBatch {
  return {
    id: null,
    batchName: '',
    externalBatchId: null,
    batchStatus: LetterBatchStatus.DRAFT,
    sendDate: new Date(),
    exportDate: null,
    marketId: null,
    createDate: null,
    updateDate: null,
    deleteDate: null,
    projectedLaunchDate: null,
    lettrLabsOrderIdToStatus: null,
    batchConfig: null,
  };
}

export interface ILetterBatchFormDto {
  id:string | null;
  batchName:string;
  externalBatchId: string | null;
  batchStatus:string;
  sendDate:string;
  exportDate:Date | null;
  marketId:string | null;
  createDate:Date | null;
  updateDate:Date | null;
  deleteDate: Date | null;
  homeCount: number | null;
  projectedLaunchDate: string | null;
  lettrLabsOrderIdToStatus: { [key: string]: string } | null;
  postageType: number | null;
}

export function createEmptyLetterBatchFormDto():ILetterBatchFormDto {
  return {
    id: null,
    batchName: '',
    externalBatchId: null,
    batchStatus: LetterBatchStatus.DRAFT,
    sendDate: '',
    exportDate: null,
    marketId: null,
    createDate: null,
    updateDate: null,
    deleteDate: null,
    homeCount: null,
    projectedLaunchDate: null,
    lettrLabsOrderIdToStatus: null,
    postageType: null,
  };
}


export class LetterBatchStatus {
  static DRAFT = 'DRAFT';
  static EXPORTED = 'EXPORTED';
  static SENT = 'SENT';

  static list = [
    {
      id: LetterBatchStatus.DRAFT,
      name: 'Draft',
    },
    {
      id: LetterBatchStatus.EXPORTED,
      name: 'Exported',
    },
    {
      id: LetterBatchStatus.SENT,
      name: 'Sent',
    },
  ];
}


export interface ISearchLetterBatchNeighborhoodRequest {
  letterBatchId:string;
  neighborhoodLetterStatus?:NeighborhoodLetterStatus;
  territoryId?:string | null;
  neighborhoodId?:string | null;
}

export interface ILetterBatchValidationResult {
  valid:boolean;
  message:string;
  letterBatchId:string | null;
}

export interface ILetterBatchNeighborhoodValidationResult {
  valid:boolean;
  message:string;
  letterBatchNeighborhoodId:string | null;
}

export interface ILetterBatchOptionsForNeighborhoodLead {
  individualId:string;
  individualName:string;
  neighborhoodId:string;
  neighborhoodName:string;
  territoryName:string;
  marketName:string;
  marketId:string;
  letterBatches:ILetterBatch[];
}