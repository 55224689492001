import { datadogRum } from '@datadog/browser-rum';
import Toast from 'components/toast';
import useToast from 'components/toast/useToast';
import useInterceptor from 'hooks/interceptorHook/useInterceptor';
import FlushCache from 'pages/caching';
import CustomerUploadV2 from 'pages/customerUploadV2';
import ServiceProviderInvoicingDetails from 'pages/invoicing/serviceProviderInvoicingDetails';
import { NeighborhoodLaunchTooling } from 'pages/neighborhoodLaunchTooling';
import NeighborhoodDetails from 'pages/neighborhoods/details/neighborhoodDetail';
import ServiceInstanceDetail from 'pages/neighborhoodServiceOffering/serviceInstances/serviceInstanceDetail';
import ServiceInstances from 'pages/neighborhoodServiceOffering/serviceInstances/serviceInstances';
import NeighborhoodUpload from 'pages/neighborhoodUpload';
import { ProviderCoverageArea } from 'pages/providerCoverageArea';
import { RebookServiceNotifications } from 'pages/rebookServiceNotifications';
import PasswordReset from 'pages/reset';
import { RewardCodeManagement } from 'pages/rewards';
import ServiceOfferingDetails from 'pages/serviceOffering/serviceOfferingDetails';
import ServiceOfferings from 'pages/serviceOffering/serviceOfferings';
import ServiceTypeDetails from 'pages/serviceTypesV2/detail';
import { default as ServiceCategoryDetailsV2 } from 'pages/serviceTypesV2/serviceCategoryDetails';
import SignIn from 'pages/signIn';
import ProviderUserDetails from 'pages/userManagement/providerUserDetails';
import UserDetails from 'pages/userManagement/userDetails';
import Users from 'pages/userManagement/table/users';
import UserReconciliation from 'pages/userReconciliation';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import DiscountScheduleDetails from './pages/discountSchedule/discountScheduleDetails';
import DiscountSchedules from './pages/discountSchedule/discountSchedules';
import HomePage from './pages/home/home';
import Layout from './pages/layout';
import Neighborhoods from './pages/neighborhoods/neighborhoods';
import NeighborhoodServiceOfferingDetail from './pages/neighborhoodServiceOffering/neighborhoodServiceOfferingDetail';
import NeighborhoodServiceOfferings from './pages/neighborhoodServiceOffering/neighborhoodServiceOfferings';

import ServiceProviders from './pages/serviceProvider/table';
import HomeSaleUpload from 'pages/homeSaleUpload';
import AdditionalServiceItemOfferingUpload from 'pages/additionalServiceItemOfferingUpload';
import NeighborhoodSeedingTooling from 'pages/neighborhoodSeedingTooling/NeighborhoodSeedingTooling';
import Individuals from 'pages/individuals/individuals';
import IndividualDetails from 'pages/individuals/IndividualDetails';
import { Territories, TerritoryDetails } from 'pages/territories';
import { TerritorySeedingTemplates } from 'pages/territorySeedingTemplates';
import { DefaultErrorPage } from 'pages/error';

import { MarketDetails, Markets } from 'pages/markets/';
import TerritoryUpload from 'pages/territoryUpload';
import { SeedFromTemplate } from 'pages/seedFromTemplate';
import { TsoiManagementBatchServiceProviders, TsoiManagementBatchServiceTypes, TsoiManagementBatchSummary, TsoiManagementNewBatch } from 'pages/tsoiManagement';
import TsoiManagementBatches from 'pages/tsoiManagement/tsoiManagementBatches';
import ServiceTypeInfoTabs from 'pages/serviceTypesV2/table';
import { TerritoryPrep } from 'pages/territoryPrep';
import MigrationsAndProcesses from 'pages/migrations';
import { ProviderRecommendations } from 'pages/providerRecommendations';
import HomeUpload from 'pages/homeUpload/HomeUpload';
import { CreateLetterBatchNeighborhoodForneighborhoodLead, LetterBatchDetail, LetterBatchNeighborhoodDetail, LetterBatchTable } from 'pages/letterBatches';
import AddressContactsUpload from 'pages/addressContactsUpload/AddressContactsUpload';
import OneTimeNewsletter from 'pages/oneTimeNewsletter/OneTimeNewsletter';
import NeighborhoodBannerLDConfig from 'pages/launchDarklyConfig/NeighborhoodBannerLDConfig';
import ShortLink from 'pages/shortLink/ShortLink';
import InviteNeighbors from 'pages/inviteNeighbors/InviteNeighbors';
import { AddProviderToTemplates } from 'pages/addProviderToTemplates';
import { RemoveProviderFromTemplates } from 'pages/removeProviderFromTemplates';
import ProviderIntegrationDetails from 'pages/serviceProvider/integrationDetails/ProviderIntegrationDetails';
import ServiceProviderLaunch from 'pages/neighborhoodLaunchTooling/providerLaunch/ServiceProviderLaunch';
import MarcUpload from 'pages/marcUpload';
import NeighborhoodRestructuring from 'pages/neighborhoodRestructuring/NeighborhoodRestructuring';
import ServiceProviderDetails from 'pages/serviceProvider/serviceProviderDetails/serviceProviderDetails';
import { ProviderSetupMode } from 'pages/providerSetup/ProviderOnboardingStepper';
import ProviderOnboarding from 'pages/providerSetup/ProviderOnboarding';
import ManageServiceProviderDeletion from 'pages/serviceProvider/manageDeletion/ManageServiceProviderDeletion';
import { TransferServiceProviderData } from 'pages/serviceProvider/transferData';
import RecommendMeOnStreetFairTesting from 'pages/rmosf_testing/RecommendMeOnStreetFairTesting';
import NeighborhoodMailersConfig from 'pages/neighborhoodMailersConfig/NeighborhoodMailersConfig';


function App() {
  const { createWarnToast } = useToast();
  const { isIntercepting, initializeInterceptor } = useInterceptor(createWarnToast);
  if (!isIntercepting) {
    initializeInterceptor();
  }

  if (window.REACT_APP_NODE_ENV !== 'local') {
    datadogRum.init({
      applicationId: '72fc4d29-3080-46fa-837f-2703ab7494c9',
      clientToken: 'puba06600435b9d002c40c080b66ae27747',
      site: 'datadoghq.com',
      service: 'admin-portal',
      env: `${window.REACT_APP_NODE_ENV}`,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [/https:\/\/.*\.streetfair\.app/],
    });
    datadogRum.startSessionReplayRecording();
  }


  return (
    <>
      <Routes>
        <Route path='/error' element={<DefaultErrorPage/>} />
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/neighborhoods" element={<Neighborhoods />} />
          <Route path="/neighborhoods/create/neighborhood-details" element={<NeighborhoodDetails />} />
          <Route path="/neighborhoods/:neighborhoodId/neighborhood-details" element={<NeighborhoodDetails />} />
          <Route path="/neighborhoodRestructuring" element={<NeighborhoodRestructuring/>} />
          <Route path="/service-providers" element={<ServiceProviders />} />
          <Route path="/service-providers/create/service-provider-details" element={<ServiceProviderDetails />} />
          <Route path="/service-providers/:serviceProviderId/service-provider-details" element={<ServiceProviderDetails />} />
          <Route path='/service-providers/:serviceProviderId/integration-details' element={<ProviderIntegrationDetails />} />
          <Route path='/service-providers/:serviceProviderId/manage-deletion' element={<ManageServiceProviderDeletion />} />
          <Route path='/service-providers/:serviceProviderId/transfer-service-provider-data' element={<TransferServiceProviderData />} />
          <Route path="/neighborhood-service-offerings" element={<NeighborhoodServiceOfferings />} />
          <Route path="/neighborhood-service-offering-detail" element={<NeighborhoodServiceOfferingDetail />} />
          <Route path="/neighborhood-service-offering-detail/:neighborhoodServiceOfferingId" element={<NeighborhoodServiceOfferingDetail />} />
          <Route path="/neighborhood-service-offering-detail/:neighborhoodServiceOfferingId/service-instances" element={<ServiceInstances />}/>
          <Route path="/neighborhood-service-offering-detail/:neighborhoodServiceOfferingId/service-instance-details/:serviceInstanceId" element={<ServiceInstanceDetail />}/>
          <Route path="/neighborhood-service-offering-detail/:neighborhoodServiceOfferingId/service-instance-details" element={<ServiceInstanceDetail />}/>
          <Route path="/discount-schedules" element={<DiscountSchedules />} />
          <Route path="/service-providers/:serviceProviderId/discount-schedules" element={<DiscountScheduleDetails />} />
          <Route path="/service-providers/:serviceProviderId/discount-schedules/:discountScheduleId" element={<DiscountScheduleDetails />} />
          <Route path="/service-offerings" element={<ServiceOfferings />} />
          <Route path="/service-providers/:serviceProviderId/service-offering-details" element={<ServiceOfferingDetails />} />
          <Route path="/service-providers/:serviceProviderId/service-offering-details/:serviceOfferingId" element={<ServiceOfferingDetails />} />
          <Route path="/service-providers/:serviceProviderId/service-offering-details/:serviceOfferingId/clone" element={<ServiceOfferingDetails clone={true}/>} />
          <Route path="/users" element={<Users/>}/>
          <Route path="/customerUpload" element={<CustomerUploadV2/>}/>
          <Route path="/neighborhoodUpload" element={<NeighborhoodUpload/>}/>
          <Route path="/territoryUpload" element={<TerritoryUpload/>}/>
          <Route path="/homeSaleUpload" element={<HomeSaleUpload/>}/>
          <Route path="/homeUpload" element={<HomeUpload/>}/>
          <Route path="/addressContactsUpload" element={<AddressContactsUpload/>}/>
          <Route path="/oneTimeNewsletter" element={<OneTimeNewsletter/>}/>
          <Route path="/additionalServiceItemOfferingUpload" element={<AdditionalServiceItemOfferingUpload/>}/>
          <Route path="/providerInvoices" element={<ServiceProviderInvoicingDetails/>}/>
          <Route path="/userReconciliation" element={<UserReconciliation/>}/>
          <Route path="/users/create/user-details" element={<UserDetails/>}/>
          <Route path="/users/create/provider-user-details" element={<ProviderUserDetails/>}/>
          <Route path="/users/:userId/user-details" element={<UserDetails/>}/>

          <Route path="/serviceTypeInfoTabs" element={<ServiceTypeInfoTabs/>}/>

          <Route path="/serviceCategoriesV2/:serviceCategoryId/service-category-details/:mode" element={<ServiceCategoryDetailsV2/>}/>
          <Route path="/serviceCategoriesV2/:serviceCategoryId/service-category-details/:mode/markets/:marketId" element={<ServiceCategoryDetailsV2/>}/>
          <Route path="/serviceCategoriesV2/:serviceCategoryId/service-category-details/:mode/markets/:marketId/territories/:territoryId" element={<ServiceCategoryDetailsV2/>}/>
          <Route path="/serviceTypes/:serviceTypeId/service-type-details/:mode" element={<ServiceTypeDetails/>}/>
          <Route path="/serviceTypes/:serviceTypeId/service-type-details/:mode/markets/:marketId" element={<ServiceTypeDetails/>}/>
          <Route path="/serviceTypes/:serviceTypeId/service-type-details/:mode/markets/:marketId/territories/:territoryId" element={<ServiceTypeDetails/>}/>


          <Route path="/neighborhoodLaunchTooling" element={<NeighborhoodLaunchTooling/>}/>
          <Route path="/neighborhoodLaunchTooling/:providerId/launch" element={<ServiceProviderLaunch/>}/>
          <Route path="/rebookServiceAdmin" element={<RebookServiceNotifications/>}/>
          <Route path="/providerCoverageArea" element={<ProviderCoverageArea/>}/>
          <Route path="/providerCoverageArea/:providerId" element={<ProviderCoverageArea/>}/>
          <Route path="/migrations" element={<MigrationsAndProcesses/>}/>
          <Route path="/marcUpload" element={<MarcUpload/>}/>
          <Route path="/rewards/codeManagement" element={<RewardCodeManagement/>}/>
          <Route path="/flush-cache" element={<FlushCache/>}/>
          <Route path="/providerOnboarding/" element={<ProviderOnboarding step={0} />}/>
          <Route path="/providerOnboarding/:providerId" element={<ProviderOnboarding step={0}/>}/>
          <Route path="/providerOnboarding/create" element={<ProviderOnboarding step={0} mode={ProviderSetupMode.create} />}/>
          <Route path="/providerOnboarding/:providerId/summary" element={<ProviderOnboarding step={3}/>}/>
          <Route path="/providerOnboarding/:providerId/service-offerings" element={<ProviderOnboarding step={1}/>}/>
          <Route path="/providerOnboarding/:providerId/service-offerings/:serviceOfferingId" element={<ProviderOnboarding step={1}/>}/>
          <Route path="/providerOnboarding/:providerId/service-offerings/:serviceOfferingId/discount-schedules" element={<ProviderOnboarding step={2}/>}/>
          <Route path="/providerOnboarding/:providerId/service-offerings/:serviceOfferingId/discount-schedules/:discountScheduleId" element={<ProviderOnboarding step={2}/>}/>
          <Route path="/providerRecommendations" element={<ProviderRecommendations/>}/>
          <Route path="/individuals" element={<Individuals/>}/>
          <Route path="/individuals/:individualId" element={<IndividualDetails/>}/>
          <Route path="/individuals/:individualId/queueLaunchBatch" element={<IndividualDetails queueLaunchBatch={true}/>}/>
          <Route path="/individuals/:individualId/markIdentityVerified" element={<IndividualDetails markIdentityVerified={true}/>}/>
          <Route path="/individuals/:individualId/markAsCalled" element={<IndividualDetails markAsCalled={true}/>}/>
          <Route path="/individuals/:individualId/cancelApplication" element={<IndividualDetails cancelLaunchLeaderApplication={true}/>}/>
          <Route path="/individuals/:individualId/pauseApplication" element={<IndividualDetails pauseLaunchLeaderApplication={true}/>}/>
          <Route path="/individuals/:individualId/viewInCustomerIo" element={<IndividualDetails viewInCustomerIo={true}/>}/>
          <Route path='/markets' element={<Markets/>} />
          <Route path='/markets/:marketId' element={<MarketDetails isNew={false}/>} />
          <Route path='/markets/new' element={<MarketDetails isNew/>} />
          <Route path='/territories' element={<Territories/>} />
          <Route path='/territories/:territoryId' element={<TerritoryDetails isNew={false}/>} />
          <Route path='/territories/new' element={<TerritoryDetails isNew/>} />
          <Route path='/territorySeedingTemplates' element={<TerritorySeedingTemplates/>} />
          <Route path='/addProviderToTemplates' element={<AddProviderToTemplates/>} />
          <Route path='/removeProviderFromTemplates' element={<RemoveProviderFromTemplates/>} />
          <Route path='/territoryPrep' element={<TerritoryPrep/>} />
          <Route path="/neighborhoodSeedingTooling" element={<NeighborhoodSeedingTooling/>}/>
          <Route path='/seedFromTemplate' element={<SeedFromTemplate/>} />
          <Route path='/tsoiManagementBatches' element={<TsoiManagementBatches />} />
          <Route path='/tsoiManagementBatches/new' element={<TsoiManagementNewBatch />} />
          <Route path='/tsoiManagementBatches/:batchId/territory/:territoryId' element={<TsoiManagementBatches />} />
          <Route path='/tsoiManagementBatches/:batchId/territory/:territoryId/serviceTypes' element={<TsoiManagementBatchServiceTypes />}/>
          <Route path='/tsoiManagementBatches/:batchId/territory/:territoryId/serviceProviders' element={<TsoiManagementBatchServiceProviders />} />
          <Route path='/tsoiManagementBatches/:batchId/summary' element={<TsoiManagementBatchSummary />} />

          <Route path='/letterBatches' element={<LetterBatchTable />} />
          <Route path='/letterBatches/new' element={<LetterBatchDetail isNew={true} />} />
          <Route path='/letterBatches/createBatchNeighborhoodForNeighborhoodLead/:individualId' element={<CreateLetterBatchNeighborhoodForneighborhoodLead />} />
          <Route path='/letterBatches/:letterBatchId' element={<LetterBatchDetail isNew={false} />} />
          <Route path='/letterBatches/:letterBatchId/letterBatchNeighborhoods/new' element={<LetterBatchNeighborhoodDetail isNew={true} />} />
          <Route path='/letterBatches/:letterBatchId/letterBatchNeighborhoods/:letterBatchNeighborhoodId' element={<LetterBatchNeighborhoodDetail isNew={false} />} />


          <Route path='/neighborhoodMailersConfig/mailerSizeOptions/create' element={<NeighborhoodMailersConfig isNew={true} />} />
          <Route path='/neighborhoodMailersConfig/mailerSizeOptions/:mailerSizeOptionId' element={<NeighborhoodMailersConfig isNew={false} />} />
          <Route path='/launchDarkly/neighborhoodBannerLDConfig' element={<NeighborhoodBannerLDConfig/>}/>

          <Route path='/shortlink' element={<ShortLink/>}/>
          <Route path='/inviteNeighbors' element={<InviteNeighbors/>}/>

          <Route path='/rmosf' element={<RecommendMeOnStreetFairTesting/>} />
        </Route>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/reset" element={<PasswordReset/>} />
      </Routes>
      <Toast/>
    </>
  );
}

export default App;

