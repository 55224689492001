import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { createEmptyMailerSizeOptionLookup, IMailerSizeOptionForm, IMailerSizeOptionLookup } from 'model/lookup/neighborhoodMailersConfig';
import { createMailerSizeOption } from 'api/neighborhoodMailersApi';
import { useNavigate } from 'react-router-dom';

const loadingKey = 'NeighborhoodMailerConfig';

type NeighborhoodMailerConfig = {
  mailerSizeOptions: IMailerSizeOptionLookup[];
  initialFormData: IMailerSizeOptionForm;
};

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodMailerConfig>('NeighborhoodMailerConfig', {
  mailerSizeOptions: [],
  initialFormData: createEmptyMailerSizeOptionLookup(),
});

export default function useNeighborhoodMailersConfig() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createErrorToast } = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
      update({
        mailerSizeOptions: [],
        initialFormData: createEmptyMailerSizeOptionLookup(),
      });
    };
  }, []);

  async function onSubmit(form: IMailerSizeOptionForm) {
    onLoading();
    try {
      const response = await createMailerSizeOption(form);
      createInfoToast('Successfully saved neighborhood mailer config');
    } catch (e) {
      createErrorToast('Failed to save neighborhood mailer config');
    }
    doneLoading();
  }

  return {
    ...get(),
    onSubmit,
    loadingKey,
  };
}
