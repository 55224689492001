import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDropdownOption } from 'model/dropdown';
import { searchServiceProviders } from 'api/serviceProviderApi';
import { ISearchPage, IServiceProviderSearchRequest, IStreetFairSearchRequest } from 'model/search';
import debounce from 'lodash.debounce';
import { IServiceProvider } from 'model/serviceProvider';
import useMarkets from 'hooks/useMarkets';

const loadingKey = 'ServiceProviderAutocompleteStore';
type ServiceProviderAutocompleteStore = {
  options: IDropdownOption[];

}

const { get, update, registerListener, unregisterListener } = createStore<ServiceProviderAutocompleteStore>('ServiceProviderAutocompleteStore', {
  options: [],

});

const debouncedSearchServiceProviders = debounce(
  async (dto:IStreetFairSearchRequest, callback:any) => {
    try {
      const res = await searchServiceProviders(dto);
      callback(res.data);
    } catch (err) {
      console.error(err);
    }

  }, 400);

export default function useServiceProviderAutocomplete() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading, getLoadingState } = useLoading(loadingKey);
  const { selectedMarket } = useMarkets();
  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        options: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function initWithValue(formContext: any, fieldName: string, id:string) {
    onLoading();
    const dto:IServiceProviderSearchRequest = {
      query: '',
      ids: [id],
      pageNumber: 0,
      pageSize: 100,
      offset: 0,
      marketId: selectedMarket?.id,
    };
    debouncedSearchServiceProviders(dto, (data) => {
      const page = data as ISearchPage<IServiceProvider>;


      update({
        ...get(),
        options: page.items.map((item) => {
          return {
            key: item.id,
            optionText: item.name,
            optionValue: item.id,
            ancillary: item,
          };
        }),

      });
      let selectedOption = page.items.filter((item) => item.id === id).map((item) => {
        return {
          key: item.id,
          optionText: item.name,
          optionValue: item.id,
          ancillary: item,
        };
      })[0] ?? null;

      if (selectedOption !== null) {
        formContext.setValue(fieldName, selectedOption, { shouldValidate: false, shouldDirty: false, shouldTouch: false });
      }
      doneLoading();
    });
  }

  async function onSearchServiceProviders(search:string) {
    onLoading();
    const dto:IServiceProviderSearchRequest = {
      query: search,
      pageNumber: 0,
      pageSize: 100,
      offset: 0,
      marketId: selectedMarket?.id,
    };
    debouncedSearchServiceProviders(dto, (data) => {
      const page = data as ISearchPage<IServiceProvider>;
      update({
        ...get(),
        options: page.items.map((item) => {
          return {
            key: item.id,
            optionText: item.name,
            optionValue: item.id,
            ancillary: item,
          };
        }),
      });
      doneLoading();
    });

  }


  return {
    ...get(),
    getLoadingState,
    onSearchServiceProviders,
    initWithValue,
  };
}