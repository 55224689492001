import { AutocompleteChangeReason, Card, CardContent, Typography } from '@mui/material';
import useManageServiceProviderDeletion from '../hookStore/useManageServiceProviderDeletion';
import TransferReviewSearchBox from './TransferReviewSearchBox';
import { IProviderStreetfairReview } from 'model/reviews/providerStreetFairReview';
import { useCallback, useState } from 'react';
import { IDropdownOption } from 'model/dropdown';
import { PrimaryButton, SecondaryButton } from 'components/buttons';

export interface ITransferReviewSearchBoxProps {
  review:IProviderStreetfairReview;
}

export default function TransferReview(
  { review }: ITransferReviewSearchBoxProps,
) {


  return (
    <Card variant="outlined" sx={{ maxWidth: '450px' }}>
      <CardContent>
        <Typography variant="body1">ID: {review.id}</Typography>
        <Typography variant="body1">Rating: {review.rating}</Typography>
        <Typography variant="body1">Comment: {review.comment}</Typography>
        <Typography variant="body1">Neighborhood: {review.neighborhoodName}</Typography>
        <Typography variant="body1">Date: {review.reviewDate}</Typography>
        <Typography variant="body1">Reviewer: {review.firstName} {review.lastName}</Typography>
      </CardContent>
    </Card>
  );
}