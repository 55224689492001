import { Alert, Button, Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './users.module.css';
import useUsersTable from '../hookStore/useUsersTable';
import SearchUsersInput from './SearchUsersInput';
import FindUserByEmailButton from './FindUserByEmailButton';
import { useTableSearchByStorageKey } from 'components/grid/useTableSearch';
import UsersTable, { quickSearchStorageKey } from './UsersTable';
import FindUsersByServiceProvider from './FindUsersByServiceProvider';


const loadingKey = 'usersTable';
export default function Users() {
  const navigate = useNavigate();
  const [isLoadAllUsersDisabled, setIsLoadAllUsersDisabled] = useState<boolean>(true);
  const { onDeleteUser, getAllUsers } = useUsersTable();


  useEffect(() => {
    if (window.REACT_APP_NODE_ENV !== 'local') {
      setTimeout(() => {
        setIsLoadAllUsersDisabled(false);
      }, 5000);
    } else {
      setTimeout(() => {
        setIsLoadAllUsersDisabled(false);
      }, 0);
    }
  }, []);


  //admin+consumer@example.com
  return (
    <Loading loadingKey={loadingKey}>
      <ConfirmationModalContextProvider onConfirm={onDeleteUser}>
        <Grid container spacing={2} className='pageGridContainer'>
          <Grid item xs={12}>
            <PageHeader headerText={'Users'} />
          </Grid>
          <Grid container item spacing={1} alignItems='start'>
            <Grid item xs='auto'>
              <Button
                variant='outlined'
                onClick={() => navigate('/users/create/provider-user-details')}>Create Provider User</Button>
            </Grid>
            <Grid item xs={2}>
              <SearchUsersInput/>
            </Grid>
            <Grid item xs='auto'>
              <FindUserByEmailButton/>
            </Grid>
            <Grid item xs='auto' sx={{ minWidth: '300px' }}>
              <FindUsersByServiceProvider/>
            </Grid>
            <div style={{ flexGrow: '1' }}></div>
            <Grid item xs='auto'>
              <Alert severity='warning'>Load all users is an expensive query</Alert>
            </Grid>
            <Grid item xs='auto'>
              <Button
                disabled={isLoadAllUsersDisabled}
                variant='outlined'
                onClick={() => getAllUsers()}
              >Load All Users</Button>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <UsersTable/>
          </Grid>
        </Grid>
        <ConfirmationModal
          title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Delete user</div>)}
          message={(onConfirmArgs:any) => (
            <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmDeleteModal}>
              <Grid item xs>
                        You are about to delete {onConfirmArgs.id}. This will not delete the user in Userfront.
              </Grid>
            </Grid>
          )}
        />
      </ConfirmationModalContextProvider>
    </Loading>
  );
}