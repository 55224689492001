
export interface IIndividual {
  id: IndividualId;
  firstName?:string;
  lastName?:string;
  streetAddress?:string;
  zipCode?:string;
  phone?:string;
  createdDate?:string;
  referredBy?:string;
  rewardsBalance?:number;
  launchLeaderStatus?:string;
  launchLeaderApplicationOrigin?:string;
  launchLeaderAcceptedOfferDate?:string;
  rewards?:any[];
  provider: boolean;
}

export interface IndividualId {
  id:string;
  email:string;
}

export function createEmptyIndividual():IIndividual {
  return {
    id: {
      id: '',
      email: '',
    },
    firstName: '',
    lastName: '',
    streetAddress: '',
    zipCode: '',
    phone: '',
    createdDate: '',
    rewardsBalance: 0,
    provider: false,
  };
}

export interface IIndividualReward {
  amount: number;
  status: string|null;
}

export class LaunchLeaderStatusTypes {
  static get APPLICATION_RECEIVED():string {
    return 'APPLICATION_RECEIVED';
  }
  static get IN_PROGRESS():string {
    return 'IN_PROGRESS';
  }
  static get DONE():string {
    return 'DONE';
  }
  static get CANCELLED():string {
    return 'CANCELLED';
  }
  static get BACKLOG():string {
    return 'BACKLOG';
  }
  static get PAY_REWARDS():string {
    return 'PAY_REWARDS';
  }
  static get QUEUE_BATCH():string {
    return 'QUEUE_BATCH';
  }
  static get MANUAL_UPDATE_REQUIRED():string {
    return 'MANUAL_UPDATE_REQUIRED';
  }
  static get CALL_NEIGHBORHOOD_LEAD():string {
    return 'CALL_NEIGHBORHOOD_LEAD';
  }
  static get VERIFY_IDENTITY():string {
    return 'VERIFY_IDENTITY';
  }

  static list = [
    { id: LaunchLeaderStatusTypes.APPLICATION_RECEIVED, name: 'APPLICATION_RECEIVED' },
    { id: LaunchLeaderStatusTypes.IN_PROGRESS, name: 'IN_PROGRESS' },
    { id: LaunchLeaderStatusTypes.DONE, name: 'DONE' },
    { id: LaunchLeaderStatusTypes.CANCELLED, name: 'CANCELLED' },
    { id: LaunchLeaderStatusTypes.BACKLOG, name: 'BACKLOG' },
    { id: LaunchLeaderStatusTypes.PAY_REWARDS, name: 'PAY_REWARDS' },
    { id: LaunchLeaderStatusTypes.QUEUE_BATCH, name: 'QUEUE_BATCH' },
    { id: LaunchLeaderStatusTypes.MANUAL_UPDATE_REQUIRED, name: 'MANUAL_UPDATE_REQUIRED' },
    { id: LaunchLeaderStatusTypes.CALL_NEIGHBORHOOD_LEAD, name: 'CALL_NEIGHBORHOOD_LEAD' },
    { id: LaunchLeaderStatusTypes.VERIFY_IDENTITY, name: 'VERIFY_IDENTITY' },
  ];
}

export class IndividualRewardTypes {
  static get LAUNCH_LEADER_REFERRAL():string {
    return 'LAUNCH_LEADER_REFERRAL';
  }
  static get LAUNCH_LEADER_APPLICATION():string {
    return 'LAUNCH_LEADER_APPLICATION';
  }
  static get NEIGHBOR_REFERRAL():string {
    return 'NEIGHBOR_REFERRAL';
  }
}

export interface IIndividualRewardAuditHistory {
  creatorId: string;
  createDate: string;
  context?: string;
  amount: number;
  rewardType?: string;
  actionType: string;
  endingBalance?: number;
}