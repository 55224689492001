import HookSelect from 'components/reactHookForm/HookSelect';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FormControl, FormHelperText, Grid, Icon, IconButton, InputLabel, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CrmIntegrationStatus } from 'model/serviceProvider';

const options = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

export default function DebugSelect() {
  const formCtx = useFormContext();
  const [dropdownOptions] = useState<any[]>(options);
  const { isError, getErrorMessage } = useErrorState(formCtx);

  return (
    <FormControl variant='standard' fullWidth error={isError('debug')}>
      <InputLabel htmlFor={'debug'}>Debug</InputLabel>
      <HookSelect
        control={formCtx.control}
        name={'debug'}
        id={'debug'}
        menuItems={dropdownOptions} />
      <FormHelperText>{getErrorMessage('debug')}</FormHelperText>
    </FormControl>
  );
}