import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import Loading from 'components/Layout/Loading';
import { useLocation, useParams } from 'react-router-dom';
import useManageServiceProviderDeletion from './hookStore/useManageServiceProviderDeletion';
import TransferReviews from './transferReviews/TransferReviews';
import { useEffect } from 'react';

export default function ManageServiceProviderDeletion() {
  const { serviceProviderId } = useParams();
  const { loadingKey, init } = useManageServiceProviderDeletion();

  useEffect(() => {
    void init();
  }
  , [serviceProviderId]);

  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container sx={{ padding: '10px' }}>
        <Grid item xs={12}>
          <TransferReviews/>
        </Grid>
        <Grid container item xs={12} className='pageGridContainer'>
          <Grid container item xs={12} sx={{
            'marginBottom': '10px',
            '&': {
              maxWidth: '1300px',
            },
          }}
          >
          </Grid>

        </Grid>
      </Grid>

    </Loading>
  );
}