import { FormControl, FormHelperText, Grid, Icon, IconButton, InputLabel, Tooltip } from '@mui/material';
import HookSelect from 'components/reactHookForm/HookSelect';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { AnnualReminderCadence } from 'model/serviceType';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import HelpIcon from '@mui/icons-material/Help';

export interface IAnnualReminderCadenceSelectProps {
  disabled?: boolean;
}
export default function AnnualReminderCadenceSelect({ disabled }:IAnnualReminderCadenceSelectProps) {
  const formCtx = useFormContext();
  const [cadences] = useState<any[]>(AnnualReminderCadence.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);

  let formState = formCtx.getValues();
  if (formState.neighborhoodNudgeEnabled !== true) {
    return <></>;
  }
  return (
    <Grid container item xs={12}>
      <Grid item xs={6}>
        <FormControl variant='standard' fullWidth error={isError('neighborhoodNudgeLookBackInDays')}>
          <InputLabel htmlFor={'neighborhood-nudge-look-back-in-days'}>Annual Reminder Cadence (optional)</InputLabel>
          <HookSelect
            disabled={disabled}
            control={formCtx.control}
            name={'neighborhoodNudgeLookBackInDays'}
            id={'neighborhood-nudge-look-back-in-days'}
            menuItems={cadences} />
          <FormHelperText>{getErrorMessage('neighborhoodNudgeLookBackInDays')}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid>
        <Tooltip title="Only set this for annual reminders" arrow>
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}