import Axios from 'axios';
import { ITrendingServiceTestForm } from 'model/trending_service_test/trending_service_test';
import { IExcelUploadForm } from 'model/upload';

const apiUrl = window.REACT_APP_BASE_API_URI;

//here temporarily should eventually be deleted


export function trendingServiceTest(form: ITrendingServiceTestForm) {
  //add form fields as query parameters

  return Axios.get(`${apiUrl}/migrations/v2/trendingServiceTest`, {
    params: form,
  });
}

export function sendTestProxiedMessage() {
  return Axios.get(`${apiUrl}/serviceProvider/v2/sendTestProxiedMessage`);
}

export function migrateLeafCleanup() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateLeafCleanup`);
}

export function migrateReviews() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateReviews`);
}

export function migrateIndividualRelevancyCode() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateIndividualRelevancyCode`);
}

export function migrateRelevantCustomerSKAndServiceOfferingId() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateRelevantCustomerSKAndServiceOfferingId`);
}

export function migrateLatLongCustomers() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateLatLongCustomers`);
}

export function migrateLatLongSubscribers() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateLatLongSubscribers`);
}

export function migrateServiceOfferingCustomFields() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceOfferingCustomFields`);
}

export function migrateServiceProviderHasReviews() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceProviderHasReviews`);
}

export function reconcileCustomersWithMissingServiceOfferingIds() {
  return Axios.get(`${apiUrl}/migrations/v2/reconcileCustomersWithMissingServiceOfferingIds`);
}

export function migrateSubscriberProviderUploadedField() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateSubscriberProviderUploadedField`);
}

export function migrateServiceProviderWithMarketId() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceProviderWithMarketId`);
}

export function migrateServiceProviderNameOnCustomers() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceProviderNameOnCustomers`);
}

export function migrateServiceProviderNameOnSubscribers() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceProviderNameOnSubscribers`);
}

export function migrateServiceTypes() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceTypes`);
}

export function migrateLocalServiceDate() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateLocalServiceDate`);
}

export function updateAllNsosToSetServiceTypeIndex() {
  return Axios.get(`${apiUrl}/migrations/v2/updateAllNsosToSetServiceTypeIndex`);
}

export function migrateCoverageAreasOnServiceOfferings() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateCoverageAreasOnServiceOfferings`);
}

export function migrateSeededCustomers() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateSeededCustomers`);
}

export function resetOGImages() {
  return Axios.get(`${apiUrl}/migrations/v2/resetOgImageForServiceProviders`);
}

export function findLatLngsForIndividuals() {
  return Axios.get(`${apiUrl}/migrations/v2/findLatLngsForIndividuals`);
}

export function migrateNeighborhoods() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateNeighborhoods`);
}

export function migrateServiceTypeIdToSeedingTemplates() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceTypeIdToSeedingTemplates`);
}

export function migrateServiceProviderConnections() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceProviderConnections`);
}

export function migratePhotosResize() {
  return Axios.get(`${apiUrl}/migrations/v2/migratePhotosResize`);
}

export function migrateCampaignTags() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateCampaignTags`);
}

export function migrateReseedProviders() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateReseedProviders`);
}

export function correctEndingBalanceOnSubtractAudits() {
  return Axios.get(`${apiUrl}/migrations/v2/correctEndingBalanceOnSubtractAudits`);
}

export function deleteTestUserConversations() {
  return Axios.get(`${apiUrl}/cometchat/deleteTestUserConversations`);
}

export function migrateServiceTypeIdOnCustomers() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceTypeIdOnCustomers`);
}

export function migrateServiceTypeIdOnSubscribers() {
  return Axios.get(`${apiUrl}/migrations/v2/migrateServiceTypeIdOnSubscribers`);
}

//potentially a permanent migration for easy delisting based on a big array list
export function delistServiceProviders() {
  return Axios.get(`${apiUrl}/migrations/v2/delistServiceProviders`);
}

export function verifiedCustomersServiceProviders() {
  return Axios.get(`${apiUrl}/migrations/v2/verifiedCustomersServiceProviders`);
}

export function hardDeleteTestUsers() {
  return Axios.get(`${apiUrl}/migrations/v2/hardDeleteTestUsers`);
}

//algolia syncs
export function syncHistoricalSubscriberDataToAlgolia() {
  return Axios.get(`${apiUrl}/migrations/v2/syncHistoricalSubscriberDataToAlgolia`);
}

export function syncHistoricalCustomerDataToAlgolia() {
  return Axios.get(`${apiUrl}/migrations/v2/syncHistoricalCustomerDataToAlgolia`);
}

export function syncNeighborhoodsToAlgolia() {
  return Axios.get(`${apiUrl}/migrations/v2/syncNeighborhoodsToAlgolia`);
}

export function syncServicetypesToAlgolia() {
  return Axios.get(`${apiUrl}/migrations/v2/syncHistoricalServiceTypesToAlgolia`);
}

export function syncHistoricalServiceSummaryDataToAlgolia() {
  return Axios.get(`${apiUrl}/migrations/v2/syncHistoricalServiceSummaryDataToAlgolia`);
}


//used from its own page
export function migrateAdditionalServiceItemOfferings(data:IExcelUploadForm) {
  var formData = new FormData();
  formData.append('template', data.template);
  return Axios.post(`${apiUrl}/migrations/v2/migrateAdditionalServiceItemOfferings`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

}