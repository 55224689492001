import { AutocompleteChangeReason, Grid, Tooltip, Typography } from '@mui/material';
import { manualScheduleRecommendMeOnStreetFair, manualStartExecutionRecommendMeOnStreetFair } from 'api/rmosfApi';
import { PrimaryButton } from 'components/buttons';
import InfoIcon from '@mui/icons-material/Info';
import { HookDurationField, HookTextField } from 'components/reactHookForm';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import useToast from 'components/toast/useToast';
import { CrmIntegrationRecommendMeOnStreetFairRequestDTO, CrmIntegrationRecommendMeOnStreetFairRequestDTOForm, IStartRecommendMeOnStreetFairWorkflowRequest } from 'model/rmosf/requests';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import { createUninitializedDropdownOption, IDropdownOption } from 'model/dropdown';
import ServiceOfferingSelect from './ServiceOfferingSelect';
import useManualScheduledRecommendMeOnStreetFair from './useManualScheduledRecommendMeOnStreetFair';

export default function ManualCrmScheduledRmosfForm() {
  const { createErrorToast } = useToast();
  const { findAndSetExistingServiceOfferings } = useManualScheduledRecommendMeOnStreetFair();
  const formContext = useForm<CrmIntegrationRecommendMeOnStreetFairRequestDTOForm>({
    mode: 'onTouched',
    defaultValues: {
      customerId: '',
      delay: '',
      serviceProviderId: createUninitializedDropdownOption(),
      neighborhoodId: createUninitializedDropdownOption(),
      serviceOfferingId: createUninitializedDropdownOption(),
      targetEmail: '',
    },
  });

  const onSubmit: SubmitHandler<CrmIntegrationRecommendMeOnStreetFairRequestDTOForm> = async (formData) => {
    const data: CrmIntegrationRecommendMeOnStreetFairRequestDTO = {
      delay: formData.delay,
      serviceProviderId: formData.serviceProviderId.optionValue,
      targetEmail: formData.targetEmail,
      neighborhoodId: formData.neighborhoodId.optionValue,
      serviceOfferingId: formData.serviceOfferingId.optionValue,
      customerId: formData.customerId,
    };
    await manualScheduleRecommendMeOnStreetFair(formData.serviceProviderId.optionValue!, data);
  };

  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid container spacing={2} >

            <Grid item xs={12} sx={{ marginTop: '64px', marginBottom: '16px' }}>
              <Grid container alignContent={'center'} alignItems={'center'} spacing={1}>
                <Grid item xs={'auto'} >
                  <Typography variant='h6'>Manual one time scheduled (e.g. CRM service request origination)</Typography>

                </Grid>
                <Grid item sx={{ marginLeft: '32px' }}>
                  <PrimaryButton type='submit'>Submit</PrimaryButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '16px' }}>
              <Typography variant='body2'>
                This emits the past customer request to review event (does not directly send the email) somewhere past the specified time.
                the service date is stored as LocalDate so we automatically assume at least an offset of 10hrs from the beginning of the day. Any delay specified
                here is added onto that and compared to the current server time. If the server determines the specifid time is in the past, then it will reject the request.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <ServiceProviderAutocomplete
                    label={'service provider'}
                    fieldName='serviceProviderId'
                    formless={false}
                    placeholder={'select one service provider'}
                    required={true}
                    postOnChange={(formCtx: any, option: IDropdownOption | null, reason: AutocompleteChangeReason, details:any) => {
                      console.log('service provider changed');
                      if (reason === 'selectOption') {
                        void findAndSetExistingServiceOfferings(formCtx, option?.optionValue!);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NeighborhoodAutocomplete
                    required={false}
                    fieldName='neighborhoodId'
                    label='Neighborhood'
                    placeholder='Search a neighborhood'
                    formless={false}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceOfferingSelect/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container alignContent={'center'} alignItems={'center'} spacing={1}>
                    <Grid item xs>
                      <HookTextField
                        name='targetEmail'
                        label='Target Email (Optional)'
                      />
                    </Grid>
                    <Grid item xs='auto' sx={{ marginRight: '10px' }}>
                      <TooltipIcon
                        title='Specify an email for testing'
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container alignContent={'center'} alignItems={'center'} spacing={1}>
                    <Grid item xs>
                      <HookTextField
                        name='customerId'
                        label='Customer ID (Optional)'
                      />
                    </Grid>
                    <Grid item xs='auto' sx={{ marginRight: '10px' }}>
                      <TooltipIcon
                        title='Specify an exact customer ID to use for sending RMOSF'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='body1' sx={{ marginBottom: '10px' }}>Delay</Typography>
              <HookDurationField
                required
                name='delay'
                label='Delay'
              />
            </Grid>
          </Grid>


        </form>
      </FormProvider>
    </>
  );
}