import { EditButton } from 'components/buttons';
import styles from './serviceProviders.module.css';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { Merge } from '@mui/icons-material';

export default function MergeCell({ id, name }) {
  const navigate = useNavigate();

  function handleClick() {
    navigate(`/service-providers/${id}/transfer-service-provider-data`);
  }
  return (
    <Tooltip title={'transfer service provider data'} placement='top'>
      <IconButton onClick={handleClick} className={styles.actionButton}>
        <Merge/>
      </IconButton>
    </Tooltip>
  );
}
