import { Grid, Typography } from '@mui/material';
import { IMarketForm, createEmptyMarketForm } from 'model/markets';
import { useEffect } from 'react';
import { useForm, FormProvider, useFormContext, useWatch } from 'react-hook-form';
import SubmitButton from './formComponents/SubmitButton';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useMarkets from 'hooks/useMarkets';
import { HookTextField } from 'components/reactHookForm';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from 'components/buttons';
import useMarketDetails from './hookStore/useMarketDetails';
import StateAbbrSelect from './formComponents/StateAbbrSelect';
import useToast from 'components/toast/useToast';
import { MetadataConstants } from 'constants/MetadataConstants';
import TimezoneSelect from './formComponents/TimezoneSelect';
import HookPhoneField from 'components/reactHookForm/HookPhoneField';
import { createValidatePhone } from 'util/rhfValidateFunctions';

export interface IMarketFormProps {
  isNew:boolean;
}

export interface IMarketFormInternalProps {
  initialFormData: IMarketForm;
}
function validatePhone(arg) {
  if (!arg) {
    return true;
  }
  return createValidatePhone('Should be of form xxx-xxx-xxxx or xxxyyyzzzz')(arg);
}

export const marketDetailsModalKey = 'marketDetailsModalKey';

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { save } = useMarketDetails();
  const navigate = useNavigate();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Market'}
      body='Save changes?'
      modalKey={marketDetailsModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12}>
            <Typography variant='body1'>This market will become available for assignment to service providers, territories, and neighborhoods</Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await save(data);
        navigate('/markets');

      }}

    />
  );
}

function MarketFormInternal({ initialFormData }:IMarketFormInternalProps) {
  const navigate = useNavigate();
  const formContext = useForm<IMarketForm>({
    mode: 'onChange',
    defaultValues: createEmptyMarketForm(),
  });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const selectedMarketId = useWatch({ control: formContext.control, name: 'id' });
  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container spacing={1} >
          <Grid item xs={12} sm={6} md={3}>
            <HookTextField name='name' label='Name' disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId} required/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HookTextField name='parentMarket' label='Parent Market' required disabled/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StateAbbrSelect/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HookTextField name='marketMapUrl' label='Map URL' disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HookTextField name='marketTerritoryMapUrl' label='Market Territory Map URL' disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}/>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
              Return Address for Letters
              </Grid>
              <Grid item xs={12} sm={6}>
                <HookTextField
                  name='returnAddressStreetAddress'
                  label='Street Address'
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <HookTextField
                  name='returnAddressCity'
                  label='City'
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <HookTextField
                  name='returnAddressState'
                  label='State'
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <HookTextField
                  name='returnAddressZip'
                  label='Zip'
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>

              <Grid item xs={12} sm={'auto'}>
                <HookTextField
                  name='coverageAreaLatitude'
                  label='Latitude'
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <HookTextField
                  name='coverageAreaLongitude'
                  label='Longitude'
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TimezoneSelect/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <HookTextField
                  name='marketSpecialistId'
                  label='Market Specialist Id (UUID)'
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <HookTextField
                  name='twilioProxyServiceSid'
                  label='Twilio Proxy Service Sid'
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <HookTextField
                  name='neighborhoodLeadVoicemailAudioFileName'
                  label='Neighborhood Lead Voicemail Audio File Name'
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>


              <Grid item xs={12} sm={2}>
                <HookPhoneField
                  label='Phone'
                  name='phone'
                  required={true}
                  validationRules={{
                    validate: validatePhone,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <HookTextField
                  name='namedKey'
                  required={true}
                  label='Integrations - Named Key '
                  disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} alignItems='center' sx={{ marginTop: '10px' }}>
            <Grid item xs='auto' sx={{ marginRight: '10px' }}>
              <SubmitButton/>
            </Grid>
            <Grid item xs='auto'>
              <SecondaryButton
                onClick={() => {
                  formContext.reset(initialFormData);
                  navigate('/markets');
                }}>
                <>Cancel</>
              </SecondaryButton>
            </Grid>
          </Grid>
          <ModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function MarketDetailsForm({ isNew }: IMarketFormProps) {
  const { formData, init } = useMarketDetails();
  useEffect(() => {
    void init(isNew);
  }, [isNew]);
  return (
    <MarketFormInternal initialFormData={formData}/>
  );
}