import { Button, Grid, Paper, Typography } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { ServiceProviderFormContextProvider } from 'contexts/serviceProvider/useServiceProviderForm';
import {
  IInternalServiceProvider,
  createEmptyInternalServiceProvider,
} from 'model/serviceProvider';
import { useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider, FieldErrors } from 'react-hook-form';
import useMarkets from 'hooks/useMarkets';
import FormButtons from './formComponents/FormButtons';
import useServiceProviderDetail from './hookStore/useServiceProviderDetails';
import { useNavigate } from 'react-router-dom';

import useModal from 'components/modal/useModal';
import Loading, { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import NumbersOnDoNotCallListAlertModal, { modalStoreKey } from 'components/ServiceProviderModals/NumbersOnDoNotCallListAlertModal';
import CoreSettings from './formComponents/core/CoreSettings';
import AdvancedSettings from './formComponents/advanced/AdvancedSettings';
import ViewIntegrationDetailsButton from './ViewIntegrationDetailsButton';
import SimilarServiceProviderConfirmationModal, { similarServiceProviderModalKey } from 'components/ServiceProviderModals/SimilarServiceProviderConfirmationModal';


export interface IServiceProviderDetailsFormInternalProps {
  initialFormData: IInternalServiceProvider;
}

function ServiceProviderDetailsFormInternal({
  initialFormData,
}) {
  const { openModal } = useModal(modalStoreKey);
  const { openModal: openSimilarModal } = useModal(similarServiceProviderModalKey);

  const formContext = useForm<IInternalServiceProvider>({ mode: 'onTouched', defaultValues: createEmptyInternalServiceProvider() });
  const { onSaveServiceProvider, maybeCheckNumbersAgainstDNCList, checkSimilarProviders, loadingKey } = useServiceProviderDetail();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createWarnToast } = useToast();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);


  const onSubmit : SubmitHandler<IInternalServiceProvider> = async data => {
    onLoading();

    if (!data.id) {
      const similarProviders = await checkSimilarProviders(data);
      if (similarProviders && similarProviders.length > 0) {
        openSimilarModal({
          serviceProviders: similarProviders,
          providerName: data.name,
          onConfirmCallback: async () => {
            await continueSave (data);
          },
          onCancelCallback: () => {
            doneLoading();
          },
        });
      } else {
        await continueSave(data);
      }
    } else {
      await continueSave(data);
    }
  };

  const continueSave = async (data: IInternalServiceProvider) => {
    var invalidNumbersResp = await maybeCheckNumbersAgainstDNCList(data)
      .catch((_e) => {
        createWarnToast('Failed to check numbers against DNC list. Saving without checking.');
        return [];
      });
    if (invalidNumbersResp && invalidNumbersResp.length > 0) {
      openModal({
        flaggedNumbers: invalidNumbersResp,
        providerName: data.name,
        onConfirmCallback: async () => {
          await onSaveServiceProvider(data);
          doneLoading();
        },
        onCancelCallback: () => {
          doneLoading();
        },
      });
    } else {
      await onSaveServiceProvider(data);
      doneLoading();
    }
  };

  const onInvalid = (errors: FieldErrors, event?: React.BaseSyntheticEvent) => {

    var errorMessages: any[]= [];
    let focusedFirst = false;
    for (const key in errors) {
      if (errors[key] && key && errors[key]!.message) {
        if (!focusedFirst) {
          (errors[key]!.ref as any).focus();
        }
        errorMessages.push({
          field: key,
          message: errors[key]!.message,
        });
      }
    }
    createWarnToast('Please correct the errors in the form before saving.', (
      <Grid container>
        {
          errorMessages.map((line, index) => (
            <Grid item xs={12}>
              <Typography key={index} variant='body2'>Field: {line.field}, message: {line.message}</Typography>
            </Grid>
          ))
        }
      </Grid>
    ));
  };

  return (
    <Loading loadingKey={loadingKey}>
      <ServiceProviderFormContextProvider>
        <FormProvider {...formContext}>
          <form onSubmit={formContext.handleSubmit(onSubmit, onInvalid)}>
            <Grid container sx={{
              '&&&': {
                position: 'relative',
              },
            }}>
              <Grid container >

                <Paper
                  style={{
                    position: 'sticky',
                    top: 0,
                    padding: '12px 10px 12px 10px',
                    zIndex: 1,
                    width: '100%',
                    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2)',
                  }}
                  elevation={0}
                >
                  <Grid container item xs={12} alignItems={'center'} justifyContent={'flex-end'}>
                    <Grid item xs>
                      <PageHeader headerText={'Manage service provider'} suppressHeaderMargin sx={{
                        '&&': {
                          borderBottom: 'none',
                          paddingBottom: '0px',
                          paddingLeft: '10px',
                        },
                      }} />
                    </Grid>
                    <Grid item xs='auto' >
                      <FormButtons/>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid item xs={12}>
                  <Grid container sx={{ padding: '20px 16px 0 16px' }}>
                    <CoreSettings/>
                  </Grid>
                  <Grid item xs={12} sx={{ padding: '20px 16px 25px 16px' }}>
                    <AdvancedSettings/>
                  </Grid>
                  <Grid item xs={12} sx={{ padding: '20px 16px 100px 16px' }}>
                    <ViewIntegrationDetailsButton/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <NumbersOnDoNotCallListAlertModal />
        <SimilarServiceProviderConfirmationModal/>
      </ServiceProviderFormContextProvider>
    </Loading>
  );
}

export default function ServiceProviderDetails() {
  const { formData, init } = useServiceProviderDetail();
  const { market } = useMarkets();
  useEffect(() => {
    void init(market);
  }, [market]);
  return (
    <ServiceProviderDetailsFormInternal initialFormData={formData}/>
  );
}