import { DateRange } from '@mui/x-date-pickers-pro';
import { IGeoJSONCoordinates } from './maps';

export class LaunchLeaderType {
  static get ADJACENT():string {
    return 'ADJACENT';
  }
  static get GENERIC():string {
    return 'GENERIC';
  }
  static get RESIDENT():string {
    return 'RESIDENT';
  }
  static get HOA():string {
    return 'HOA';
  }
  static get NOT_SET():string {
    return 'NOT_SET';
  }
  static get MINING():string {
    return 'MINING';
  }

  static list = [
    { id: LaunchLeaderType.ADJACENT, name: 'Adjacent' },
    { id: LaunchLeaderType.GENERIC, name: 'Generic' },
    { id: LaunchLeaderType.RESIDENT, name: 'Resident' },
    { id: LaunchLeaderType.HOA, name: 'HOA' },
    { id: LaunchLeaderType.MINING, name: 'Mining' },
    { id: LaunchLeaderType.NOT_SET, name: 'Not Set' },
  ];
}

export class NeighborhoodStatusType {
  static get ALPHA():string {
    return 'ALPHA';
  }
  static get BETA():string {
    return 'BETA';
  }
  static get PENDING():string {
    return 'PENDING';
  }
  static get APPLICATION_RECEIVED():string {
    return 'APPLICATION_RECEIVED';
  }
  static get ACTIVE():string {
    return 'ACTIVE';
  }

  static get LAUNCHED():string {
    return 'LAUNCHED';
  }

  static list = [
    { id: NeighborhoodStatusType.ALPHA, name: 'Alpha' },
    { id: NeighborhoodStatusType.BETA, name: 'Beta' },
    { id: NeighborhoodStatusType.PENDING, name: 'Pending' },
    { id: NeighborhoodStatusType.APPLICATION_RECEIVED, name: 'Application Received' },
    { id: NeighborhoodStatusType.ACTIVE, name: 'Active' },
    { id: NeighborhoodStatusType.LAUNCHED, name: 'Launched' },
  ];
}

export class NewsletterCadenceType {
  static get OFF():string {
    return 'OFF';
  }
  static get MONDAY():string {
    return 'MONDAY';
  }
  static get TUESDAY():string {
    return 'TUESDAY';
  }
  static get WEDNESDAY():string {
    return 'WEDNESDAY';
  }
  static get THURSDAY():string {
    return 'THURSDAY';
  }
  static get FRIDAY():string {
    return 'FRIDAY';
  }
  static get SATURDAY():string {
    return 'SATURDAY';
  }
  static get SUNDAY():string {
    return 'SUNDAY';
  }

  static list = [
    { id: NewsletterCadenceType.OFF, name: 'Off' },
    { id: NewsletterCadenceType.MONDAY, name: 'Monday' },
    { id: NewsletterCadenceType.TUESDAY, name: 'Tuesday' },
    { id: NewsletterCadenceType.WEDNESDAY, name: 'Wednesday' },
    { id: NewsletterCadenceType.THURSDAY, name: 'Thursday' },
    { id: NewsletterCadenceType.FRIDAY, name: 'Friday' },
    { id: NewsletterCadenceType.SATURDAY, name: 'Saturday' },
    { id: NewsletterCadenceType.SUNDAY, name: 'Sunday' },
  ];
}

export class NeighborhoodCommunicationType {
  static get MONTHLY_NEWSLETTER():string {
    return 'MONTHLY_NEWSLETTER';
  }
  static get NEW_FEATURE_ALERT():string {
    return 'NEW_FEATURE_ALERT';
  }
  static list = [
    { id: NeighborhoodCommunicationType.MONTHLY_NEWSLETTER, name: 'Monthly Newsletter' },
    { id: NeighborhoodCommunicationType.NEW_FEATURE_ALERT, name: 'New Feature Alert' },
  ];
}

export class NeighborhoodFollowUpNlLetterOverride {
  static get DEFAULT():string {
    return 'DEFAULT';
  }
  static get FORCE_ENABLED():string {
    return 'FORCE_ENABLED';
  }
  static get FORCE_DISABLED():string {
    return 'FORCE_DISABLED';
  }

  static list = [
    { id: NeighborhoodFollowUpNlLetterOverride.DEFAULT, name: 'Default' },
    { id: NeighborhoodFollowUpNlLetterOverride.FORCE_ENABLED, name: 'Force Enabled' },
    { id: NeighborhoodFollowUpNlLetterOverride.FORCE_DISABLED, name: 'Force Disabled' },
  ];
}

export interface INeighborhood {
  id?: string;
  objectID?:string;
  name: string;
  legacyId?: number;
  launchDate?:string;
  city?: string;
  state?: string;
  zipCode?: string;
  zipCodes?: string[];
  zipCodeTerritory?:string;
  providerCoverageZone?:string;
  status: string;
  latitude?: string;
  longitude?: string;
  applicantName?: string;
  applicationComment?: string;
  applicantEmail?: string;
  newsletterCadence?:string;
  neighborhoodGeometryWKT: string | null;
  hideLaunchLeaderOffer?: boolean;
  hideFbRequestOffer: boolean;
  marketId: string;
  startingNeighborCount:number | null;
  neighborhoodImageGuid: string;
  inviteCode?: string;
  onboardingCompletion: number | null;
  waitlistNumber: number | null;
  maxHappeningSoonCardCount: number | null;
  timezoneOffset: string | null;
  houseHoldCount:number | null;
  launchDateTimestamp?:string;
  neighborhoodCommunications?:any;
  unlockRequired?:boolean;
  deleteDate?:Date;
}

export interface IInternalNeighborhood extends INeighborhood{
  houseHoldCount:number | null;
  launchLeaderType:string | null;
  envelop:string | null;
  address:string | null;
  lastReconciled:Date | null;
  rating:number | null;
  recruitingLaunchLeader: boolean;
  launchLeaderAcquisitionCampaignStartDate?:Date;
  launchLeaderAcquisitionCampaignEndDate?:Date;
  isExperimental?: boolean | null;
  experimentTag?:string | null;
  facebookGroups: INeighborhoodFacebookGroup[] | null;
  lastSkipTracedTimestamp?:string;
  facebookPostDate?:Date | null;
  followUpLetterOverride?:string | null;
}

export interface IInternalNeighborhoodForm extends IInternalNeighborhood {
  miningDateRange?:DateRange<Date>;
}


export interface INeighborhoodCommunicationForm {
  communicationTemplate: string;
  neighborhoodServiceOfferingInstanceId: string;
  shouldSendToLoggedInUser:boolean;
  overrideEmail?:string;

}

export namespace NeighborhoodUtils{
  export function createEmptyInternalNeighborhood():IInternalNeighborhood {
    return {
      id: '',
      name: '',
      legacyId: -1,
      city: '',
      state: '',
      zipCode: '',
      zipCodes: [],
      status: '',
      latitude: '',
      longitude: '',
      applicantName: '',
      applicationComment: '',
      applicantEmail: '',
      neighborhoodGeometryWKT: '',
      hideFbRequestOffer: false,
      hideLaunchLeaderOffer: false,
      marketId: '',
      neighborhoodImageGuid: '',
      houseHoldCount: null,
      launchLeaderType: LaunchLeaderType.NOT_SET,
      envelop: '',
      address: '',
      startingNeighborCount: null,
      onboardingCompletion: null,
      waitlistNumber: null,
      maxHappeningSoonCardCount: null,
      timezoneOffset: null,
      newsletterCadence: NewsletterCadenceType.OFF,
      lastReconciled: null,
      rating: null,
      recruitingLaunchLeader: false,
      isExperimental: false,
      experimentTag: '',
      facebookGroups: [],
      unlockRequired: false,
      followUpLetterOverride: NeighborhoodFollowUpNlLetterOverride.DEFAULT,
    };
  }
  export function createEmptyInternalNeighborhoodForm():IInternalNeighborhoodForm {
    let baseline = NeighborhoodUtils.createEmptyInternalNeighborhood();

    return {
      ...baseline,
      miningDateRange: [null, null],
    };
  }
}


export class NeighborhoodRating {

  static list = [
    { id: null, name: 'clear' },
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
  ];
}

export interface INeighborhoodFacebookGroup {
  groupId: string | null;
  groupName: string | null;
}

export interface IGeoJSONNeighborhood {
  neighborhoodId:string;
  name:string;
  geoJson?:IGeoJSONCoordinates;
}