import { INeighborhood } from 'model/neighborhood';

export interface IHookCheckboxable {
  checked:boolean;
}

export interface ITerritoryPrepForm {
  territoryNeighborhoods:(INeighborhood & IHookCheckboxable)[];
  finalPrep:boolean;
}

export function createEmptyTerritoryPrepForm() {
  return {
    territoryNeighborhoods: [],
  };
}

export function convertTerritoryPrepFormToDTO(form:ITerritoryPrepForm):ITerritoryPrepRequestDTO {
  return {
    neighborhoodIds: form.territoryNeighborhoods.filter(x => x && x.checked).map(x => x.id!),
    finalPrep: form.finalPrep,
  };
}

export interface ITerritoryPrepRequestDTO {
  neighborhoodIds:string[];
  finalPrep:boolean;
}