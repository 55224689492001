import { Card, CardContent, Typography } from '@mui/material';
import { NeighborhoodOverlapMap } from 'components/gmaps';
import { findBounds, findCenter } from 'components/gmaps/util';
import { IProviderCoverageAreaDTO } from 'model/providerCoverageArea';

export default function TransferCoverageArea(
  { coverageArea }: {coverageArea: IProviderCoverageAreaDTO},
) {
  var geojson = coverageArea.providerCoverageArea.coverageAreaGeoJSON;
  const showMap = geojson !== null;
  if (!showMap) {
    return (
      <Card variant="outlined" sx={{ maxWidth: '450px' }}>
        <CardContent>
          <Typography variant="body1">ID: {coverageArea.providerCoverageArea.id}</Typography>
          <Typography variant="body1">No coverage area polygon</Typography>
        </CardContent>
      </Card>
    );
  }
  const bounds = findBounds(geojson!);
  const center = findCenter(bounds);
  return (
    <Card variant="outlined" sx={{ maxWidth: '600px' }}>
      <CardContent>
        <Typography variant="body1">ID: {coverageArea.providerCoverageArea.id}</Typography>

        <NeighborhoodOverlapMap
          bounds={bounds}
          center={center}
          geoJson={geojson!}
          zoom={20}
        />

      </CardContent>
    </Card>
  );
}