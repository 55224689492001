
export interface IEditableUser {
  id?: string;
  name?:string;
  organizations?:string[];
  roles?:string[];
  accounts?:string[];
  serviceProviders?:string[];
  individuals?:string[];

}

export function createEmptyUser():IEditableUser {
  return {
    id: '',
    name: '',
    organizations: [],
    roles: [],
    accounts: [],
    serviceProviders: [],
    individuals: [],
  };
}

export interface IServiceProviderUserDto{
  id?:string;
  email:string;
  firstName:string;
  lastName:string;
  serviceProviderId:string;
}

export interface IProviderSignUpLinkGenerationResult {
  success:boolean;
  errorMessage:string | null;
  oneTimeSignUpLink:string | null;
}

export interface IExistingProviderUserResponse {
  serviceProviderId:string;
  providerUsers: IProviderUserLite[];
}

export interface IProviderUserLite {
  email:string;
  uuid: string;
  message:string | null;
}

export function createEmptyExistingProviderUserResponse():IExistingProviderUserResponse {
  return {
    serviceProviderId: '',
    providerUsers: [],
  };
}