import { Button } from '@mui/material';
import useUsersTable from '../hookStore/useUsersTable';

export default function FindUserByEmailButton() {
  const { onFindUserByEmail } = useUsersTable();
  return (
    <Button
      variant='outlined'
      onClick={() => onFindUserByEmail()}>
      <>Find User</>
    </Button>
  );
}