import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { HookTextField, HookCheckbox, HookPriceField } from 'components/reactHookForm';
import { ManageServiceTypeMode } from 'model/serviceCategory';

import { useFormContext } from 'react-hook-form';
import styles from './serviceCategoryTypeDetails.module.css';
import { ServiceTypeImageDropzone } from './formFields/ServiceTypeImageDropzone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tags from './formFields/Tags';
import useServiceTypeDetail from '../hookStore/useServiceTypeDetail';
import { useParams } from 'react-router-dom';
import { ServiceTypePrioritySelect } from './formFields/ServiceTypePrioritySelect';
import SearchResultCardStyleSelect from './formFields/SearchResultCardStyleSelect';
import ServiceTypeImage from './ServiceTypeImage';
import TagCount from './TagCount';
import HookPercentField from 'components/reactHookForm/HookPercentField';
import Aliases from './formFields/Aliases';
import AliasCount from './AliasCount';
import AnnualReminderCadenceSelect from './formFields/AnnualReminderCadence';

export default function ServiceTypeFields() {
  const { control, getValues } = useFormContext();
  const { mode } = useParams();

  const {

  } = useServiceTypeDetail();
  let isNew = mode === ManageServiceTypeMode.CREATE;
  const shouldDisableDefaultFields = mode === ManageServiceTypeMode.EDIT_MARKET || mode === ManageServiceTypeMode.EDIT_TERRITORY;
  const shouldDisablePricingFields = mode === ManageServiceTypeMode.CREATE || mode === ManageServiceTypeMode.EDIT_TERRITORY;
  return (
    <Grid container className={styles.serviceTypeWrapper}>
      <Grid container item spacing={1} alignItems='top' >
        <Grid item xs={4}>
          <HookTextField
            name={'name'}
            label='Service Type Name'
            disabled={shouldDisableDefaultFields || !isNew}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <HookTextField
            name={'displayName'}
            label='Service Type Display Name'
            disabled={shouldDisableDefaultFields}
          />
        </Grid>
        <Grid item xs={4}>
          <ServiceTypePrioritySelect
            disabled={mode === ManageServiceTypeMode.EDIT_TERRITORY}
          />
        </Grid>
        <Grid item xs={4}>
          <SearchResultCardStyleSelect
            disabled={shouldDisableDefaultFields}/>
        </Grid>
        <Grid item xs={4}>
          <HookCheckbox
            name={'enabled'}
            label='Enabled'
          />
        </Grid>
        <Grid container item xs={4}>
          <HookCheckbox
            name={'isRebookEnabled'}
            label='Rebook Enabled?'
            extraProps={{
              disabled: shouldDisableDefaultFields,
            }}
          />
        </Grid>
        <Grid container item alignItems={'top'} spacing={1}>
          <Grid container item xs={4} paddingBottom={'10px'}>
            <HookCheckbox
              name={'neighborhoodNudgeEnabled'}
              label='Neighborhood Nudge Enabled?'
              extraProps={{
                disabled: shouldDisableDefaultFields,
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <AnnualReminderCadenceSelect disabled={shouldDisableDefaultFields} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ marginTop: '10px' }}>StreetFair pricing (overridable at market level only)</Typography>
        </Grid>
        <Grid item xs={2}>
          <HookPercentField
            disabled={shouldDisablePricingFields}
            name='recurringPercent'
            label='Recurring %'
            allowDecimalPoints={0}
            validationRules={{
              validate: function(value) {
                if (value == null || value == undefined || value == '') {
                  return null;
                }
                if (value === '0') {
                  return 'Percent Off must be greater than 0.';
                }
                if (value > 100) {
                  return 'Percent Off must be less than 100.';
                }

                return null;
              },

            }}
          />
        </Grid>
        <Grid item xs={2}>
          <HookPercentField
            disabled={shouldDisablePricingFields}
            name='oneTimePercent'
            label='One Time %'
            allowDecimalPoints={0}
            validationRules={{
              validate: function(value) {
                if (value == null || value == undefined || value == '') {
                  return null;
                }
                if (value === '0') {
                  return 'Percent Off must be greater than 0.';
                }
                if (value > 100) {
                  return 'Percent Off must be less than 100.';
                }

                return null;
              },

            }}
          />
        </Grid>
        <Grid item xs={2}>
          <HookPriceField
            disabled={shouldDisablePricingFields}
            name='takeRateCap'
            label='Take Rate Cap'
            allowNegative={false}
          />
        </Grid>
        <Grid item xs={2}>
          <HookPriceField
            disabled={shouldDisablePricingFields}
            name='introductoryOffer'
            label='Introductory Offer'
            allowNegative={false}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
        <Grid item xs>
          {!shouldDisableDefaultFields && (
            <ServiceTypeImageDropzone />
          )}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid container item xs='auto' flexDirection={'column'} justifyContent={'center'} alignContent={'center'}>
          <Grid item xs='auto'>
            <ServiceTypeImage/>
          </Grid>
          <Grid item xs='auto'>
            <Typography variant='body1' component='div' sx={{ fontSize: '10px', textAlign: 'center' }}>Placeholder card color</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Accordion sx={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <TagCount/>
          </AccordionSummary>
          <AccordionDetails>
            <Tags/>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid container item xs={12} paddingTop={'20px'}>
        <Accordion sx={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <AliasCount/>
          </AccordionSummary>
          <AccordionDetails>
            <Aliases/>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}