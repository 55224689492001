import { IDropdownOption } from './dropdown';

export interface IMarketZoneIdDTO {
  zoneId:string;
}

export interface IMarket {
  id:string;
  name:string;
  parentMarket:string;
  stateAbbr:string;
  marketMapUrl?:string;
  marketTerritoryMapUrl?:string;
  returnAddressStreetAddress:string;
  returnAddressCity:string;
  returnAddressState:string;
  returnAddressZip:string;
  coverageAreaLatitude:number;
  coverageAreaLongitude:number;
  marketSpecialistId:string;
  twilioProxyServiceSid:string;
  timezoneId:string;
  phone:string;
  namedKey:string;
  neighborhoodLeadVoicemailAudioFileName:string | null;
}

export interface IMarketForm {
  id:string | null;
  name: string;
  stateAbbr:StateAbbreviations | null;
  parentMarket:string;
  marketMapUrl?:string;
  marketTerritoryMapUrl?:string;
  returnAddressStreetAddress:string;
  returnAddressCity:string;
  returnAddressState:string;
  returnAddressZip:string;
  coverageAreaLatitude:number;
  coverageAreaLongitude:number;
  marketSpecialistId:string;
  twilioProxyServiceSid:string;
  timezoneId:string;
  phone:string;
  namedKey:string;
  neighborhoodLeadVoicemailAudioFileName:string | null;
}

export function createEmptyMarketForm():IMarketForm {
  return {
    id: null,
    name: '',
    stateAbbr: '',
    parentMarket: 'USA',
    marketMapUrl: '',
    marketTerritoryMapUrl: '',
    returnAddressStreetAddress: '',
    returnAddressCity: '',
    returnAddressState: '',
    returnAddressZip: '',
    coverageAreaLatitude: 0,
    coverageAreaLongitude: 0,
    marketSpecialistId: '',
    twilioProxyServiceSid: '',
    timezoneId: '',
    phone: '',
    namedKey: '',
    neighborhoodLeadVoicemailAudioFileName: null,
  };
}

export namespace MarketUtils {
  export function convertToDropdownOptions(markets:IMarket[]):IDropdownOption[] {
    return markets.map(m => {
      return {
        key: m.id,
        optionValue: m.id,
        optionText: m.name,
        ancillary: m,
      };
    });
  }

  export function convertToMap(markets:IMarket[]):Map<string, IMarket> {
    const map = new Map<string, IMarket>();
    markets.forEach(m => {
      map.set(m.id, m);
    });
    return map;
  }

}


export class StateAbbreviations {

  static get AL():string {
    return 'AL';
  }
  static get KY():string {
    return 'KY';
  }
  static get OH():string {
    return 'OH';
  }
  static get AK():string {
    return 'AK';
  }
  static get LA():string {
    return 'LA';
  }
  static get OK():string {
    return 'OK';
  }
  static get AZ():string {
    return 'AZ';
  }
  static get ME():string {
    return 'ME';
  }
  static get OR():string {
    return 'OR';
  }
  static get AR():string {
    return 'AR';
  }
  static get MD():string {
    return 'MD';
  }
  static get PA():string {
    return 'PA';
  }
  static get AS():string {
    return 'AS';
  }
  static get MA():string {
    return 'MA';
  }
  static get PR():string {
    return 'PR';
  }
  static get CA():string {
    return 'CA';
  }
  static get MI():string {
    return 'MI';
  }
  static get RI():string {
    return 'RI';
  }
  static get CO():string {
    return 'CO';
  }
  static get MN():string {
    return 'MN';
  }
  static get SC():string {
    return 'SC';
  }
  static get CT():string {
    return 'CT';
  }
  static get MS():string {
    return 'MS';
  }
  static get SD():string {
    return 'SD';
  }
  static get DE():string {
    return 'DE';
  }
  static get MO():string {
    return 'MO';
  }
  static get TN():string {
    return 'TN';
  }
  static get DC():string {
    return 'DC';
  }
  static get MT():string {
    return 'MT';
  }
  static get TX():string {
    return 'TX';
  }
  static get FL():string {
    return 'FL';
  }
  static get NE():string {
    return 'NE';
  }
  static get TT():string {
    return 'TT';
  }
  static get GA():string {
    return 'GA';
  }
  static get NV():string {
    return 'NV';
  }
  static get UT():string {
    return 'UT';
  }
  static get GU():string {
    return 'GU';
  }
  static get NH():string {
    return 'NH';
  }
  static get VT():string {
    return 'VT';
  }
  static get HI():string {
    return 'HI';
  }
  static get NJ():string {
    return 'NJ';
  }
  static get VA():string {
    return 'VA';
  }
  static get ID():string {
    return 'ID';
  }
  static get NM():string {
    return 'NM';
  }
  static get VI():string {
    return 'VI';
  }
  static get IL():string {
    return 'IL';
  }
  static get NY():string {
    return 'NY';
  }
  static get WA():string {
    return 'WA';
  }
  static get IN():string {
    return 'IN';
  }
  static get NC():string {
    return 'NC';
  }
  static get WV():string {
    return 'WV';
  }
  static get IA():string {
    return 'IA';
  }
  static get ND():string {
    return 'ND';
  }
  static get WI():string {
    return 'WI';
  }
  static get KS():string {
    return 'KS';
  }
  static get MP():string {
    return 'MP';
  }
  static get WY():string {
    return 'WY';
  }


  static list = [
    { name: 'Alabama', id: StateAbbreviations.AL },
    { name: 'Kentucky', id: StateAbbreviations.KY },
    { name: 'Ohio', id: StateAbbreviations.OH },
    { name: 'Alaska', id: StateAbbreviations.AK },
    { name: 'Louisiana', id: StateAbbreviations.LA },
    { name: 'Oklahoma', id: StateAbbreviations.OK },
    { name: 'Arizona', id: StateAbbreviations.AZ },
    { name: 'Maine', id: StateAbbreviations.ME },
    { name: 'Oregon', id: StateAbbreviations.OR },
    { name: 'Arkansas', id: StateAbbreviations.AR },
    { name: 'Maryland', id: StateAbbreviations.MD },
    { name: 'Pennsylvania', id: StateAbbreviations.PA },
    { name: 'American Samoa', id: StateAbbreviations.AS },
    { name: 'Massachusetts', id: StateAbbreviations.MA },
    { name: 'Puerto Rico', id: StateAbbreviations.PR },
    { name: 'California', id: StateAbbreviations.CA },
    { name: 'Michigan', id: StateAbbreviations.MI },
    { name: 'Rhode Island', id: StateAbbreviations.RI },
    { name: 'Colorado', id: StateAbbreviations.CO },
    { name: 'Minnesota', id: StateAbbreviations.MN },
    { name: 'South Carolina', id: StateAbbreviations.SC },
    { name: 'Connecticut', id: StateAbbreviations.CT },
    { name: 'Mississippi', id: StateAbbreviations.MS },
    { name: 'South Dakota', id: StateAbbreviations.SD },
    { name: 'Delaware', id: StateAbbreviations.DE },
    { name: 'Missouri', id: StateAbbreviations.MO },
    { name: 'Tennessee', id: StateAbbreviations.TN },
    { name: 'District of Columbia', id: StateAbbreviations.DC },
    { name: 'Montana', id: StateAbbreviations.MT },
    { name: 'Texas', id: StateAbbreviations.TX },
    { name: 'Florida', id: StateAbbreviations.FL },
    { name: 'Nebraska', id: StateAbbreviations.NE },
    { name: 'Trust Territories', id: StateAbbreviations.TT },
    { name: 'Georgia', id: StateAbbreviations.GA },
    { name: 'Nevada', id: StateAbbreviations.NV },
    { name: 'Utah', id: StateAbbreviations.UT },
    { name: 'Guam', id: StateAbbreviations.GU },
    { name: 'New Hampshire', id: StateAbbreviations.NH },
    { name: 'Vermont', id: StateAbbreviations.VT },
    { name: 'Hawaii', id: StateAbbreviations.HI },
    { name: 'New Jersey', id: StateAbbreviations.NJ },
    { name: 'Virginia', id: StateAbbreviations.VA },
    { name: 'Idaho', id: StateAbbreviations.ID },
    { name: 'New Mexico', id: StateAbbreviations.NM },
    { name: 'Virgin Islands', id: StateAbbreviations.VI },
    { name: 'Illinois', id: StateAbbreviations.IL },
    { name: 'New York', id: StateAbbreviations.NY },
    { name: 'Washington', id: StateAbbreviations.WA },
    { name: 'Indiana', id: StateAbbreviations.IN },
    { name: 'North Carolina', id: StateAbbreviations.NC },
    { name: 'West Virginia', id: StateAbbreviations.WV },
    { name: 'Iowa', id: StateAbbreviations.IA },
    { name: 'North Dakota', id: StateAbbreviations.ND },
    { name: 'Wisconsin', id: StateAbbreviations.WI },
    { name: 'Kansas', id: StateAbbreviations.KS },
    { name: 'Northern Mariana Islands', id: StateAbbreviations.MP },
    { name: 'Wyoming', id: StateAbbreviations.WY },
  ];
}


