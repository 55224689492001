import _ from 'lodash';
import Delete from '@mui/icons-material/Delete';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useProviderIntegrationDetails from '../../hookStore/useProviderIntegrationDetails';
import useToast from 'components/toast/useToast';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { Button, Grid, Icon, IconButton, Tooltip } from '@mui/material';
import { useLoading } from 'components/Layout/Loading';
import { useParams } from 'react-router-dom';
import { IDeleteProviderIntegrationDTO } from 'model/service_provider_config/service_provider_integration_config';
import { deleteIntegration } from 'api/serviceProviderApi';

interface DeleteButtonProps {
  partner: string;
  type: string;
}

export const deleteIntegrationConfirmationModalKey = 'DeleteIntegrationConfirmationModalKey';
function DeleteIntegrationConfirmationModal({ partner, type }: DeleteButtonProps) {
  const { loadingKey } = useProviderIntegrationDetails();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createSuccessToast, createErrorToast } = useToast();

  return <HookConfirmationModal
    title={() => `Delete ${_.capitalize(partner)} Integration`}
    body={`Delete integration? This will delete the ${partner} integration.`}
    modalKey={deleteIntegrationConfirmationModalKey}
    message={(confirmArgs, cancelModal) => (
      <Grid container spacing={1} alignItems='center' justifyContent='center' paddingTop={'15px'} >
        <Grid item xs={12} paddingBottom={'25px'}>
        Confirm delete of the {partner} {type} integration
        </Grid>
      </Grid>
    )}
    onConfirm={async (formData) => {
      try {
        onLoading();
        // const payload = {
        //   apiKey: formData.apiKey,
        //   webhookSecret: formData.webhookSecret,
        // };

        if (serviceProviderId) {
          let request:IDeleteProviderIntegrationDTO = {
            partner: partner,
            type: type,
          };

          const resp = await deleteIntegration(serviceProviderId, request);
          if (resp.status === 200) {
            createSuccessToast('Integration Deleted!');
          } else {
            createErrorToast('Error deleting integration');
          }
        }
      } catch (error) {
        console.error(`error deleting ${partner} ${type} integration`, error);
        createErrorToast('Error deleting integration');
      } finally {
        doneLoading();
      }
    }}
  />;
}

export default function DeleteButton({ partner, type }: DeleteButtonProps) {
  const { openModal } = useConfirmationModal(deleteIntegrationConfirmationModalKey);

  return (
    <>
      <Button
        color={'error'}
        variant='outlined'
        startIcon={<Delete />}
        onClick={() => {
          openModal({ partner, type });
        // deleteIntegrationDetails(partner, type);
        }}
      >
        Delete
      </Button>
      <DeleteIntegrationConfirmationModal partner={partner} type={type} />
    </>
  );
}