
import { findUserById, saveUser } from 'api/userApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { createEmptyUser, IEditableUser } from 'model/user';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type UserDetailsStore = {
  formData: IEditableUser;
}
const loadingKey = 'UserDetailsStore';

const { get, update, registerListener, unregisterListener } = createStore<UserDetailsStore>('UserDetailsStore', {
  formData: createEmptyUser(),
});

export default function useUserDetailsStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();
  const navigate = useNavigate();
  const { userId } = useParams();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        formData: createEmptyUser(),
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    if (!userId) {
      createErrorToast('User ID is required');
      return;
    }
    const res = await findUserById(userId);
    if (res.data) {
      update({
        formData: res.data,
      });
    } else {
      update({
        formData: createEmptyUser(),
      });
    }
    doneLoading(300);
  }

  async function onSaveUser(data:IEditableUser) {
    const dataToSave = { ...data };
    try {
      await saveUser(dataToSave);
      createSuccessToast('User saved successfully');
      navigate('/users');
    } catch (e:any) {
      console.error(e.response.data.message);
      createErrorToast(`Failed to save user: ${e.response.data.message}`);
    }

  }

  return {
    loadingKey,
    ...get(),
    init,
    onSaveUser,

  };
}