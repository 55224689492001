import { useNavigate } from 'react-router-dom';
import ServiceProviderAutocomplete, { IFormlessForm } from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import { ProviderSetupRecentProviders } from 'util/storage/providerSetupRecentProviders';
import useServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/useServiceProviderAutocomplete';
import { HookSingleAsyncAutocomplete } from 'components/reactHookForm';
import { AutocompleteInputChangeReason, Box, createFilterOptions, FormControl } from '@mui/material';
import { IDropDownOptionCreatable } from 'model/dropdown';
import { FormProvider, useForm } from 'react-hook-form';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useEffect } from 'react';
import useMarkets from 'hooks/useMarkets';
import { formatMonthDayYearTime } from 'util/dateUtil';

const filter = createFilterOptions<IDropDownOptionCreatable>();

export function ProviderSelect() {
  const navigate = useNavigate();
  const { options, onSearchServiceProviders, initWithValue, getLoadingState } = useServiceProviderAutocomplete();
  const { isLoading } = getLoadingState();
  const { selectedMarket } = useMarkets();
  const formContext = useForm<IFormlessForm>({
    mode: 'onTouched',
    defaultValues: {
      selectedServiceProvider: null,
    },
  });
  const { getErrorMessage } = useErrorState(formContext);
  useEffect(() => {
    if (!selectedMarket) {
      return;
    }
  }, [selectedMarket]);

  const postOnChange = (_formCtx, val, reason, details) => {
    if (reason === 'selectOption' && val?.inputValue) {
      navigate(`/providerOnboarding/create?create-provider-name=${encodeURIComponent(val?.inputValue.trim())}`);
    } else if (reason === 'selectOption') {
      let providerId = val?.optionValue;
      let providerName = val?.optionText;
      // jump the user to the summary step so they can see what they have completed
      // for this provider so far
      ProviderSetupRecentProviders.addProvider({ id: providerId, name: providerName });
      navigate(`/providerOnboarding/${providerId}/summary`);
    }
  };

  return (
    <FormProvider {...formContext}>
      <FormControl variant='standard' fullWidth error={false}>
        <HookSingleAsyncAutocomplete
          placeholder='Find or create a service provider'
          name={'providerId'}
          label="Find Service Provider"
          postOnChange={postOnChange}
          extraProps={{
            filterOptions: (opts, params) => {
              const filtered = filter(opts, params);
              const { inputValue } = params;
              //this is a pretty stringent creation check. we do not want users to create a new service provider if one exists
              // Suggest the creation of a new value
              const isExisting = filtered.length > 0;
              const hasAlphabet = /[a-zA-Z]/.test(inputValue);
              if (!isLoading && inputValue !== '' && !isExisting && hasAlphabet) {
                filtered.push({
                  key: inputValue,
                  optionValue: inputValue,
                  optionText: `Add "${inputValue}"`,
                  inputValue: inputValue,
                });
              }
              return filtered;
            },
            selectOnFocus: true,
            clearOnBlur: true,
            handleHomeEndKeys: true,
            getOptionLabel: (option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return `${option.optionText} (${option.ancillary.status})`;
            },
            renderOption: (props, option, { selected }) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return (<li {...props}>{option}</li>);
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return <li {...props}>Add "{option.inputValue}"</li>;
              }
              // Regular option
              return (<li {...props} style={{ display: 'block' }}>
                <Box sx={{ display: 'block', fontSize: '14px' }}>
                  {`${option.optionText} (${option.ancillary.status})`}
                </Box>
                <Box sx={{ display: 'block', fontSize: '11px' }}>
                  {option.ancillary.id}
                </Box>
                <Box sx={{ display: 'block', fontSize: '11px' }}>
                  Created: {option.ancillary.createDate ? formatMonthDayYearTime(new Date(option.ancillary.createDate)) : 'N/A'}
                </Box>
              </li>);
            },
            freeSolo: true,
          }}
          dropdownOptions={options}
          onInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
            if (value) {
              void onSearchServiceProviders(value);
            }
          }}
        />
      </FormControl>
    </FormProvider>
  );
}
