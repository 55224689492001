import { Grid, Typography } from '@mui/material';
import useTransferServiceProviderData from '../hookStore/useTransferServiceProviderData';
import { PrimaryButton } from 'components/buttons';
import TransferUser from './TransferUser';

export default function TranferUsers() {
  const {
    existingProviderUsers,
    onTransferProviderUsers,
    transferServiceProviderId,
  } = useTransferServiceProviderData();

  if (existingProviderUsers.length === 0) {
    return null;
  }

  return (
    <div>
      <Typography variant="h6">Existing Users</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2} >
            <Grid item >
              <PrimaryButton
                disabled={!transferServiceProviderId}
                onClick={onTransferProviderUsers}
              >
              Transfer All
              </PrimaryButton>
            </Grid>

          </Grid>
        </Grid>
        {existingProviderUsers.map(x => (
          <Grid item xs='auto' key={x.uuid}>
            <TransferUser user={x}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}