import Axios from 'axios';
import { CrmIntegrationRecommendMeOnStreetFairRequestDTO, IStartRecommendMeOnStreetFairWorkflowRequest } from 'model/rmosf/requests';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function manualStartExecutionRecommendMeOnStreetFair(serviceProviderId:string, data: IStartRecommendMeOnStreetFairWorkflowRequest) {
  return Axios.post(`${apiUrl}/rmosf/manualStartExecutionRmosf/${serviceProviderId}`, data);
}

export function manualScheduleRecommendMeOnStreetFair(serviceProviderId:string, data: CrmIntegrationRecommendMeOnStreetFairRequestDTO) {
  return Axios.post(`${apiUrl}/rmosf/manualScheduleRecommendMeOnStreetFair/${serviceProviderId}`, data);
}
