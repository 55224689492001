import HookSelect from 'components/reactHookForm/HookSelect';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FormControl, FormHelperText, Grid, Icon, IconButton, InputLabel, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CrmIntegrationStatus } from 'model/serviceProvider';


export default function CrmStatusSelect() {
  const formCtx = useFormContext();
  const [statuses] = useState<any[]>(CrmIntegrationStatus.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);

  return (
    <FormControl variant='standard' fullWidth error={isError('status')}>
      <InputLabel htmlFor={'status'}>Status</InputLabel>
      <HookSelect
        control={formCtx.control}
        name={'status'}
        id={'status'}
        menuItems={statuses} />
      <FormHelperText>{getErrorMessage('status')}</FormHelperText>
    </FormControl>
  );
}