import { DataGrid } from '@mui/x-data-grid';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import { useEffect, useState } from 'react';
import { columns, columnTotalWidth } from './usersTableConfig';
import useUsersTable from '../hookStore/useUsersTable';

export const quickSearchStorageKey = 'userManagement';
export default function UsersTable() {

  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  const { rows, searchText, setRowsWithStoredFilter, requestSearch, clearStoredSearch } = useTableSearch(quickSearchStorageKey);
  const { users } = useUsersTable();

  useEffect(() => {
    setRowsWithStoredFilter(users);
  }, [users]);
  return (
    <DataGrid
      rows={rows}
      components={{ Toolbar: QuickSearchToolbar }}
      componentsProps={{
        toolbar: {
          quickSearchStorageKey,
          value: searchText,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
            requestSearch(users, event.target.value),
          clearSearch: () => requestSearch(users, ''),
        },
      }}
      columns={columns}
      autoHeight={true}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      style={{
        minWidth: '500px',
        maxWidth: `${columnTotalWidth}px`,
      }}
    />
  );
}