import { KeyButton } from 'components/buttons';
import useModal from 'components/modal/useModal';
import { getServiceProviderKey } from 'api/serviceProviderApi';
import { ICreateApiKeyModal } from './modal/CreateApiKeyModal';
import styles from './serviceProviders.module.css';

export default function ApiKeyGenCell({ id, name }:any) {
  const { openModal } = useModal('createServiceProviderApiKeyModal');

  return (
    <KeyButton
      tooltipTitle={`Create API key for ${name}`}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
      onClick={async () => {
        const apiKeyRes = await getServiceProviderKey(id);
        const hasApiKey = apiKeyRes.data != '' && apiKeyRes.data != null;
        const state:ICreateApiKeyModal = {
          id,
          name,
          hasApiKey,
          createdApiKey: apiKeyRes.data,
        };
        openModal(state);
      }}
    />
  );
}