
import { FormControl, FormHelperText } from '@mui/material';
import { HookSingleAutocomplete } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { UNSELECTED_OPTION } from 'model/dropdown';
import { useFormContext } from 'react-hook-form';
import useRecommendMeOnStreetFairTesting from './useManualScheduledRecommendMeOnStreetFair';


export interface IServiceOfferingSelectProps {

}

export default function ServiceOfferingSelect({ }:IServiceOfferingSelectProps) {
  const { serviceOfferingOptions } = useRecommendMeOnStreetFairTesting();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  let rules:any = {
    required: {
      value: true, message: 'Required',
    },
  };
  return (
    <FormControl variant='standard' fullWidth error={isError('serviceOfferingId')}>
      <HookSingleAutocomplete
        rules={rules}
        name='serviceOfferingId'
        label='Service Offering'
        dropdownOptions={serviceOfferingOptions}
        placeholder='Search service offering'
        postOnChange={(formContext, value, reason, details) => {

        }}
        extraProps={{
          disabled: serviceOfferingOptions.filter(x => x.optionValue !== UNSELECTED_OPTION).length === 0,
        }}
      />
      <FormHelperText>{getErrorMessage('serviceOfferingId')}</FormHelperText>
    </FormControl>
  );
}