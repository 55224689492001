import { CrmIntegrationStatus, CrmIntegrationType, CrmPartners, IGenericIntegrationForm } from './serviceProvider';

export class IntegrationServicePartnerTags {
  static get SERVICETITAN() {
    return 'serviceTitan';
  }
}

export class IntegrationStatus {
  static get PENDING() {
    return 'PENDING';
  }
  static get READY() {
    return 'READY';
  }
  static get ACTIVE() {
    return 'ACTIVE';
  }
  static get DISABLED() {
    return 'DISABLED';
  }

  static list = [
    { id: IntegrationStatus.ACTIVE, name: 'Active' },
    { id: IntegrationStatus.DISABLED, name: 'Disabled' },
    { id: IntegrationStatus.PENDING, name: 'Pending' },
    { id: IntegrationStatus.READY, name: 'Ready' },
  ];

}

export interface IServiceTitanForm extends IGenericIntegrationForm {
  tenantId: string;
  clientId: string;
  clientSecret: string;
  displayGenericFormFields: boolean;
  businessUnitIds?: string;
}

export const createEmptyServiceTitanForm = (): IServiceTitanForm => ({
  partner: CrmPartners.SERVICETITAN,
  tenantId: '',
  clientId: '',
  clientSecret: '',
  status: '',
  type: CrmIntegrationType.GET_OFF_PLATFORM_JOBS,
  debug: false,
  displayGenericFormFields: false,
  businessUnitIds: '',
});

export interface IHouseCallProMaxForm extends IGenericIntegrationForm {
  apiKey: string;
  webhookSecret: string;
  displayGenericFormFields: boolean;
}

export const createEmptyHouseCallProMaxForm = (): IHouseCallProMaxForm => ({
  apiKey: '',
  webhookSecret: '',
  partner: CrmPartners.HOUSECALL_PRO,
  status: '',
  type: CrmIntegrationType.GET_OFF_PLATFORM_JOBS,
  debug: false,
  displayGenericFormFields: false,
});