import { AutocompleteChangeReason, Card, CardContent, Grid, Typography } from '@mui/material';
import useManageServiceProviderDeletion from '../hookStore/useManageServiceProviderDeletion';
import TransferReviewSearchBox from './TransferReviewSearchBox';
import TransferReview from './TransferReview';
import { IDropdownOption } from 'model/dropdown';
import { PrimaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';

export default function TransferReviews() {
  const {
    reviews,
    onTransferReviews,
    reviewTransferServiceProviderId,
    setReviewTransferServiceProviderId,
    onDelete,
  } = useManageServiceProviderDeletion();
  const navigate = useNavigate();
  return (
    <div>
      <Typography variant="h6">Reviews</Typography>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2} >
            <Grid item xs='auto' sx={{ minWidth: '300px' }}>
              <TransferReviewSearchBox
                postOnChange={ (formCtx: any, selectedOption: IDropdownOption | null, reason: AutocompleteChangeReason, details: any) => {
                  const selectedServiceProviderId = selectedOption?.optionValue;
                  if (selectedServiceProviderId) {
                    setReviewTransferServiceProviderId(selectedServiceProviderId);
                  }

                }}
              />
            </Grid>
            <Grid item sx={{ paddingTop: '20px' }}>
              <PrimaryButton
                disabled={!reviewTransferServiceProviderId}
                onClick={async () => {
                  if (reviewTransferServiceProviderId) {
                    await onTransferReviews();
                  }
                }
                }
              >
              Transfer
              </PrimaryButton>
            </Grid>
            <Grid item sx={{ paddingTop: '20px' }}>
              <PrimaryButton
                disabled={reviews.length > 0}
                onClick={async () => {
                  await onDelete();
                  navigate('/service-providers');
                }
                }
              >
              Delete Provider
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
        {reviews.map(review => (
          <Grid item xs='auto' key={review.id}>
            <TransferReview review={review} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}