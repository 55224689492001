import Axios, { AxiosResponse } from 'axios';
import { DynamoPage, IProviderStreetfairReview } from 'model/reviews/providerStreetFairReview';
import { IFindReviewsForProviderRequest } from 'model/reviews/providerStreetFairReviewRequest';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function transferReviews(fromServiceProviderId: string, toServiceProviderId: string): Promise<AxiosResponse<void>> {
  return Axios.post(
    `${apiUrl}/merge/v3/from/${fromServiceProviderId}/to/${toServiceProviderId}/transferReviews`,
  );
}

export function transferProviderUsers(fromServiceProviderId: string, toServiceProviderId: string): Promise<AxiosResponse<void>> {
  return Axios.post(
    `${apiUrl}/merge/v3/from/${fromServiceProviderId}/to/${toServiceProviderId}/transferProviderUsers`,
  );
}

export function transferServiceOfferings(fromServiceProviderId: string, toServiceProviderId: string): Promise<AxiosResponse<void>> {
  return Axios.post(
    `${apiUrl}/merge/v3/from/${fromServiceProviderId}/to/${toServiceProviderId}/transferServiceOfferings`,
  );
}

export function transferProviderCoverageAreas(fromServiceProviderId: string, toServiceProviderId: string): Promise<AxiosResponse<void>> {
  return Axios.post(
    `${apiUrl}/merge/v3/from/${fromServiceProviderId}/to/${toServiceProviderId}/transferProviderCoverageAreas`,
  );
}

export function transferPartnershipAgreement(fromServiceProviderId: string, toServiceProviderId: string): Promise<AxiosResponse<void>> {
  return Axios.post(
    `${apiUrl}/merge/v3/from/${fromServiceProviderId}/to/${toServiceProviderId}/transferPartnershipAgreement`,
  );
}