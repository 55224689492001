import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import _ from 'lodash';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { findInternalServiceProviderById, getServiceProviderIntegrationDetails } from 'api/serviceProviderApi';
import { CrmIntegrationStatus, CrmIntegrationType, CrmPartners, ICrmIntegrationDetails, IGenericIntegrationForm, IInternalServiceProvider, INeighborhoodNudgeForm, NeighborhoodNudgeDefaults, createEmptyGenericIntegrationFormForPartner, createEmptyInternalServiceProvider, createEmptyNeighborhoodNudgeForm } from 'model/serviceProvider';
import { createEmptyHouseCallProMaxForm, createEmptyServiceTitanForm, IHouseCallProMaxForm, IServiceTitanForm } from 'model/serviceProviderCrmIntegration';

const loadingKey = 'ProviderIntegrationDetailStore';
type ProviderIntegrationDetailStore = {
  serviceProvider: IInternalServiceProvider;
  integrationDetails: any;
  // TODO: it probably makes sense at this point to break out
  // these integrations into their own stores
  serviceTitanFormData: IServiceTitanForm;
  houseCallProMaxFormData: IHouseCallProMaxForm;
  emailFormData: IGenericIntegrationForm;
  zapierFormData: IGenericIntegrationForm;
  neighborhoodNudgeFormData: INeighborhoodNudgeForm;
  jobberFormData: IGenericIntegrationForm;
}

const { get, update, registerListener, unregisterListener } = createStore<ProviderIntegrationDetailStore>('ProviderIntegrationDetailStore', {
  serviceProvider: createEmptyInternalServiceProvider(),
  integrationDetails: {},
  serviceTitanFormData: createEmptyServiceTitanForm(),
  houseCallProMaxFormData: createEmptyHouseCallProMaxForm(),
  emailFormData: createEmptyGenericIntegrationFormForPartner('email'),
  zapierFormData: createEmptyGenericIntegrationFormForPartner('zapier'),
  neighborhoodNudgeFormData: createEmptyNeighborhoodNudgeForm(),
  jobberFormData: createEmptyGenericIntegrationFormForPartner('jobber'),
});

export default function useProviderIntegrationDetails() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      let serviceProvider = createEmptyInternalServiceProvider();
      let crmIntegrationConfig = {} as any;
      let serviceTitanFormData = createEmptyServiceTitanForm();
      let houseCallProMaxFormData = createEmptyHouseCallProMaxForm();
      let emailFormData = createEmptyGenericIntegrationFormForPartner(CrmPartners.EMAIL);
      let zapierFormData = createEmptyGenericIntegrationFormForPartner(CrmPartners.ZAPIER);
      let neighborhoodNudgeFormData = createEmptyNeighborhoodNudgeForm();
      let jobberFormData = createEmptyGenericIntegrationFormForPartner(CrmPartners.JOBBER);
      let crmIntegrationDetails: ICrmIntegrationDetails[] = [];

      if (serviceProviderId) {
        const response = await findInternalServiceProviderById(serviceProviderId);

        if (response.data) {
          serviceProvider = response.data;
          crmIntegrationDetails = serviceProvider?.crmIntegrationDetails ?? [];
          // Build out email form data
          let emailOffPlatformRequestsIntegration = _.find(
            crmIntegrationDetails,
            { partner: CrmPartners.EMAIL, type: CrmIntegrationType.GET_OFF_PLATFORM_JOBS },
          );
          if (!_.isEmpty(emailOffPlatformRequestsIntegration)) {
            emailFormData.debug = emailOffPlatformRequestsIntegration.debug;
            if (emailOffPlatformRequestsIntegration.status) {
              emailFormData.status = emailOffPlatformRequestsIntegration.status;
            }
            emailFormData.type = emailOffPlatformRequestsIntegration.type;
            let emailServiceTypes = emailOffPlatformRequestsIntegration.settings.serviceTypes ?? [];
            emailFormData.serviceTypes = emailOffPlatformRequestsIntegration.settings.serviceTypes;
            let emailDefaultServiceType = _.find(emailServiceTypes, { default: true });
            if (emailDefaultServiceType) {
              emailFormData.defaultServiceType = emailDefaultServiceType.serviceTypeId;
            }
          }

          // Build out zapier form data
          let zapierIntegration = _.find(
            crmIntegrationDetails,
            { partner: CrmPartners.ZAPIER, type: CrmIntegrationType.GET_OFF_PLATFORM_JOBS },
          );
          if (!_.isEmpty(zapierIntegration)) {
            zapierFormData.debug = zapierIntegration.debug;
            if (zapierIntegration.status) {
              zapierFormData.status = zapierIntegration.status;
            }
            zapierFormData.type = zapierIntegration.type;
            let zapierServiceTypes = zapierIntegration.settings.serviceTypes ?? [];
            zapierFormData.serviceTypes = zapierIntegration.settings.serviceTypes;
            let zapierDefaultServiceType = _.find(zapierServiceTypes, { default: true });
            if (zapierDefaultServiceType) {
              zapierFormData.defaultServiceType = zapierDefaultServiceType.serviceTypeId;
            }
          }

          // Build out neighborhood nudge form data
          let neighborhoodNudgeIntegration = _.find(
            crmIntegrationDetails,
            { partner: CrmPartners.STREETFAIR, type: CrmIntegrationType.NOTIFY_NEARBY_NEIGHBORS },
          );
          if (!_.isEmpty(neighborhoodNudgeIntegration)) {
            let neighborhoodNudgeSettings = neighborhoodNudgeIntegration.settings;
            if (!_.isEmpty(neighborhoodNudgeSettings)) {
              neighborhoodNudgeFormData.status = neighborhoodNudgeIntegration.status ?? CrmIntegrationStatus.DISABLED;
              neighborhoodNudgeFormData.debug = neighborhoodNudgeIntegration.debug;
              neighborhoodNudgeFormData.type = neighborhoodNudgeIntegration.type;

              neighborhoodNudgeFormData.recontactDays = neighborhoodNudgeSettings.recontactDays;
              neighborhoodNudgeFormData.recontactDaysRepeat = neighborhoodNudgeSettings.recontactDaysRepeat;
              neighborhoodNudgeFormData.maxPerBatch = neighborhoodNudgeSettings.maxPerBatch ?? NeighborhoodNudgeDefaults.MAX_PER_BATCH;
              neighborhoodNudgeFormData.businessOwnerFirstName = neighborhoodNudgeSettings.businessOwnerFirstName;
            }
          }

          // Build out jobber form data
          let jobberIntegration = _.find(
            crmIntegrationDetails,
            { partner: CrmPartners.JOBBER, type: CrmIntegrationType.GET_OFF_PLATFORM_JOBS },
          );
          if (!_.isEmpty(jobberIntegration)) {
            jobberFormData.debug = jobberIntegration.debug;
            if (jobberIntegration.status) {
              jobberFormData.status = jobberIntegration.status;
            }
            jobberFormData.type = jobberIntegration.type;
            let jobberServiceTypes = jobberIntegration.settings.serviceTypes ?? [];
            jobberFormData.serviceTypes = jobberIntegration.settings.serviceTypes;
            let jobberDefaultServiceType = _.find(jobberServiceTypes, { default: true });
            if (jobberDefaultServiceType) {
              jobberFormData.defaultServiceType = jobberDefaultServiceType.serviceTypeId;
            }
          }
        } else {
          createErrorToast('Error loading service provider');
        }

        const providerCrmDetails = await getServiceProviderIntegrationDetails(serviceProviderId);
        if (providerCrmDetails.data) {
          crmIntegrationConfig = providerCrmDetails.data?.serviceProvider?.integrationDetails;
        }

      }

      if (crmIntegrationConfig?.serviceTitan) {
        let { tenantId, refreshToken } = crmIntegrationConfig.serviceTitan;
        serviceTitanFormData.tenantId = tenantId;

        if (!!refreshToken) {
          let [clientId, clientSecret] = refreshToken.split(':');
          if (clientId && clientSecret) {
            serviceTitanFormData.clientId = clientId;
            serviceTitanFormData.clientSecret = clientSecret;
          }
        }

        let serviceTitanIntegration = _.find(
          crmIntegrationDetails,
          { partner: CrmPartners.SERVICETITAN, type: CrmIntegrationType.GET_OFF_PLATFORM_JOBS },
        );

        if (!_.isEmpty(tenantId) && !_.isEmpty(refreshToken) && !_.isEmpty(serviceTitanIntegration)) {
          serviceTitanFormData.displayGenericFormFields = true; // indicates that the integration has been configured
          serviceTitanFormData.status = serviceTitanIntegration.status ?? '';
          serviceTitanFormData.debug = serviceTitanIntegration.debug;
          serviceTitanFormData.type = serviceTitanIntegration.type;

          let serviceTitanServiceTypes = serviceTitanIntegration.settings?.serviceTypes ?? [];
          serviceTitanFormData.serviceTypes = serviceTitanServiceTypes;
          let serviceTitanDefaultServiceType = _.find(serviceTitanServiceTypes, { default: true });
          if (serviceTitanDefaultServiceType) {
            serviceTitanFormData.defaultServiceType = serviceTitanDefaultServiceType.serviceTypeId;
          }

          let multiProviderMapping = serviceTitanIntegration.settings?.multiProviderMapping;
          if ( !_.isEmpty(multiProviderMapping?.value) && multiProviderMapping?.key == 'businessUnitId') {
            serviceTitanFormData.businessUnitIds = multiProviderMapping?.value;
          }
        }
      }

      if (crmIntegrationConfig?.housecallPro) {
        let { apiKey, webhookSecret } = crmIntegrationConfig.housecallPro;
        houseCallProMaxFormData.apiKey = apiKey;
        houseCallProMaxFormData.webhookSecret = webhookSecret;

        // if api key and webhook secret are present, the integration values
        // stored on the ServiceProvider record should be present
        let hcpIntegrationdetailsItem = _.find(
          crmIntegrationDetails,
          { partner: CrmPartners.HOUSECALL_PRO, type: CrmIntegrationType.GET_OFF_PLATFORM_JOBS },
        );
        if (!_.isEmpty(apiKey) && !_.isEmpty(webhookSecret) && !_.isEmpty(hcpIntegrationdetailsItem)) {
          houseCallProMaxFormData.displayGenericFormFields = true; // indicates that the integration has been configured
          houseCallProMaxFormData.status = hcpIntegrationdetailsItem.status ?? '';
          houseCallProMaxFormData.debug = hcpIntegrationdetailsItem.debug;
          houseCallProMaxFormData.type = hcpIntegrationdetailsItem.type;
          let hcpServiceTypes = hcpIntegrationdetailsItem.settings.serviceTypes ?? [];
          houseCallProMaxFormData.serviceTypes = hcpIntegrationdetailsItem.settings.serviceTypes;
          let hcpDefaultServiceType = _.find(hcpServiceTypes, { default: true });
          if (hcpDefaultServiceType) {
            houseCallProMaxFormData.defaultServiceType = hcpDefaultServiceType.serviceTypeId;
          }
        }
      }

      update({
        ...get(),
        serviceProvider: serviceProvider,
        integrationDetails: crmIntegrationConfig,
        serviceTitanFormData: serviceTitanFormData,
        houseCallProMaxFormData: houseCallProMaxFormData,
        emailFormData: emailFormData,
        zapierFormData: zapierFormData,
        neighborhoodNudgeFormData: neighborhoodNudgeFormData,
        jobberFormData: jobberFormData,
      });

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  /**
   * Same thing as init
   */
  async function refresh() {
    await init();
  }


  return {
    ...get(),
    loadingKey,
    init,
    refresh,
  };
}