import CrmStatusSelect from '../components/CrmStatusSelect';
import DebugSelect from '../components/DebugSelect';
import DeleteButton from '../components/delete/DeleteButton';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import SaveIcon from '@mui/icons-material/Save';
import { HookTextField } from 'components/reactHookForm';
import { Grid, Typography } from '@mui/material';
import { CrmPartners, CrmIntegrationType, INeighborhoodNudgeForm } from 'model/serviceProvider';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { PrimaryButton } from 'components/buttons';
import { NeighborhoodNudgesConfirmationModal, neighborhoodNudgesConfirmationModalKey } from './NeighborhoodNudgesConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import HookLargeIntegerField from 'components/reactHookForm/HookLargeIntegerField';

export default function NeighborhoodNudges() {
  const { neighborhoodNudgeFormData } = useProviderIntegrationDetails();
  const formContext = useForm<INeighborhoodNudgeForm>({ mode: 'onTouched', defaultValues: neighborhoodNudgeFormData });
  const { openModal } = useConfirmationModal(neighborhoodNudgesConfirmationModalKey);

  useEffect(() => {
    formContext.reset(neighborhoodNudgeFormData);
  }, [
    neighborhoodNudgeFormData,
  ]);

  const onSave = () => {
    const formData = formContext.getValues();
    openModal(formData);
  };

  return (
    <>
      <FormProvider {...formContext}>
        <Grid container>
          <Grid container item alignItems={'center'} paddingBottom={'15px'}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Neighborhood Nudges</Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }} paddingLeft={'8px'}>({neighborhoodNudgeFormData.status})</Typography>
          </Grid>

          <Grid container item spacing={1}>

            <Grid item xs={12} lg={3}>
              <CrmStatusSelect />
            </Grid>
            <Grid item xs={12} lg={3}>
              <DebugSelect />
            </Grid>

            <Grid container item spacing={2}>
              <Grid item xs={12} lg={3}>
                <HookLargeIntegerField
                  name='recontactDays'
                  label='Recontact Days'
                  required={false}
                  maxLength={4}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <HookLargeIntegerField
                  name='recontactDaysRepeat'
                  label='Recontact Days Repeat'
                  maxLength={4}
                  required={false}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={2}>
              <Grid item xs={12} lg={3}>
                <HookLargeIntegerField
                  name='maxPerBatch'
                  label='Max Per Batch'
                  maxLength={4}
                  defaultValue={100}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <HookTextField
                  name='businessOwnerFirstName'
                  label='Business Owner First Name'
                  required={false}
                  defaultValue={null}
                />
              </Grid>
            </Grid>

          </Grid>

          <Grid container item paddingTop={'20px'} alignItems={'center'} spacing={2}>
            <Grid item xs={12} lg={2} >
              <PrimaryButton
                type='submit'
                onClick={onSave}
                onSubmit={onSave}
                startIcon={<SaveIcon />}
              >
                    Save
              </PrimaryButton>
            </Grid>
            <Grid item xs={12} lg={3}>
              <DeleteButton partner={CrmPartners.STREETFAIR} type={CrmIntegrationType.NOTIFY_NEARBY_NEIGHBORS} />
            </Grid>
          </Grid>
          <NeighborhoodNudgesConfirmationModal />
        </Grid>
      </FormProvider>
    </>
  );
}
