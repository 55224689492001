import { Grid, Typography } from '@mui/material';
import useTransferServiceProviderData from '../hookStore/useTransferServiceProviderData';
import ProviderPartnershipData from './ProviderPartnershipData';
import { PrimaryButton } from 'components/buttons';

export default function TransferProviderPartnershipData() {
  const { serviceProvider, transferServiceProvider, transferServiceProviderId, onTransferProviderPartnership } = useTransferServiceProviderData();
  if (!transferServiceProvider) {
    return null;
  }
  return (
    <Grid container sx={{ marginBottom: '32px' }}>
      <Grid item xs={12}>
        <Typography variant='h6'>Transfer Partnership Agreement</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <ProviderPartnershipData
          serviceProvider={serviceProvider}
          title="From Service Provider"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ProviderPartnershipData
          serviceProvider={transferServiceProvider}
          title="To Service Provider"
        />
      </Grid>
      <Grid item >
        <PrimaryButton
          disabled={!transferServiceProviderId}
          onClick={onTransferProviderPartnership}
        >
          Transfer
        </PrimaryButton>
      </Grid>
    </Grid>
  );
}