import _ from 'lodash';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useParams } from 'react-router-dom';
import Loading, { useLoading } from 'components/Layout/Loading';
import useMarkets from 'hooks/useMarkets';
import useProviderIntegrationDetails from './hookStore/useProviderIntegrationDetails';
import { useEffect, useState } from 'react';
import ServiceTitan from './serviceTitan/ServiceTitan';
import HouseCallProMax from './houseCallProMax/HouseCallProMax';
import Zapier from './zapier/Zapier';
import Email from './email/Email';
import TabPanel from 'components/tabs/SimpleTabPanel';
import NeighborhoodNudges from './neighborhoodNudges/NeighborhoodNudges';
import { CrmIntegrationStatus, CrmIntegrationType } from 'model/serviceProvider';
import Jobber from './jobber/Jobber';

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProviderIntegrationDetails() {
  const { serviceProviderId } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const {
    init,
    loadingKey,
    serviceProvider,
  } = useProviderIntegrationDetails();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  useEffect(() => {
    void init();
  }, [serviceProviderId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const crmIntegrationDetails = serviceProvider?.crmIntegrationDetails ?? [];
  const activeIntegrationTags = crmIntegrationDetails.reduce((acc, curr) => {
    const isActive = curr.status === 'ACTIVE';
    const isStreetFair = curr.partner === 'streetfair';

    if (isActive && curr.partner && !isStreetFair) {
      acc.push(_.capitalize(curr.partner));
    }
    if (isActive && isStreetFair && curr.type === CrmIntegrationType.NOTIFY_NEARBY_NEIGHBORS) {
      acc.push('Neighborhood Nudges (email)');
    }
    if (isActive && isStreetFair && curr.type === CrmIntegrationType.NOTIFY_NEARBY_NEIGHBORS_SMS) {
      acc.push('neighborhood nudges (SMS)');
    }
    if (isActive && isStreetFair && curr.type === CrmIntegrationType.NOTIFY_NEARBY_NEIGHBORS_POSTCARD) {
      acc.push('Neighborhood Nudges (Postcard)');
    }
    return acc;
  }, [] as string[]);

  return (
    <>
      <Loading loadingKey={loadingKey}>
        <Grid container spacing={1} className='pageGridContainer'>
          <PageHeader headerText={'Provider Integration Details'} />
          <Grid container item xs={12} columnSpacing={1}>
            <Grid item>
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Provider:</Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>{serviceProvider?.name}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} columnSpacing={1}>
            <Grid item>
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Active Integrations:</Typography>
            </Grid>
            <Grid item paddingBottom={'25px'}>
              <Typography variant='body1'>{activeIntegrationTags.join(', ')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} paddingBottom={'25px'}>
            <Tabs value={tabValue} onChange={handleChange} aria-label='tabs'>
              <Tab label="Neighborhood Nudges" {...a11yProps(0)} />
              <Tab label="Email" {...a11yProps(1)} />
              <Tab label="HouseCall Pro Max" {...a11yProps(2)} />
              <Tab label="Jobber" {...a11yProps(3)} />
              <Tab label="Service Titan" {...a11yProps(4)} />
              <Tab label="Zapier" {...a11yProps(5)} />
            </Tabs>
          </Grid>
          <Grid>
            <TabPanel value={tabValue} index={0}>
              <NeighborhoodNudges />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Email />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <HouseCallProMax />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <Jobber />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <ServiceTitan />
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <Zapier />
            </TabPanel>
          </Grid>
        </Grid>
      </Loading>
    </>
  );
}