import { Card, CardContent, Typography } from '@mui/material';

export default function TransferUser(
  { user }: {user: any},
) {
  return (
    <Card variant="outlined" sx={{ maxWidth: '450px' }}>
      <CardContent>
        <Typography variant="body1">ID: {user.uuid}</Typography>
        <Typography variant="body1">Email:
          {user.email}
        </Typography>
      </CardContent>
    </Card>
  );
}