import { Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import { useEffect } from 'react';
import { CrmIntegrationType, CrmPartners, IGenericIntegrationForm } from 'model/serviceProvider';
import CrmStatusSelect from '../components/CrmStatusSelect';
import DebugSelect from '../components/DebugSelect';
import DeleteButton from '../components/delete/DeleteButton';
import CrmDefaultServiceTypesSelect from '../components/CrmDefaultServiceTypesSelect';
import { PrimaryButton } from 'components/buttons';
import { zapierConfirmationModalKey, ZapierConfirmationModal } from './ZapierConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { Save } from '@mui/icons-material';

export default function Zapier() {
  const { zapierFormData } = useProviderIntegrationDetails();
  const formContext = useForm<IGenericIntegrationForm>({ mode: 'onTouched', defaultValues: zapierFormData });
  const { openModal } = useConfirmationModal(zapierConfirmationModalKey);

  useEffect(() => {
    formContext.reset(zapierFormData);
  }, [
    zapierFormData,
  ]);

  const onSave = () => {
    const formState = formContext.getValues();
    openModal(formState);
  };

  return (
    <>
      <FormProvider {...formContext}>
        <Grid container>
          <Grid container item alignItems={'center'} paddingBottom={'15px'}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Zapier</Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }} paddingLeft={'8px'}>({zapierFormData.status})</Typography>
          </Grid>

          <Grid container item spacing={1}>
            <Grid item xs={12} lg={3}>
              <CrmStatusSelect />
            </Grid>
            <Grid item xs={12} lg={3}>
              <DebugSelect />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} lg={6}>
                <CrmDefaultServiceTypesSelect />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item paddingTop={'25px'} alignItems={'center'} spacing={2}>
            <Grid item xs={12} lg={3} paddingRight={'30px'}>
              <PrimaryButton
                onClick={onSave}
                startIcon={<Save />}
              >
                Save
              </PrimaryButton>
            </Grid>
            <Grid item xs={12} lg={3}>
              <DeleteButton partner={CrmPartners.ZAPIER} type={CrmIntegrationType.GET_OFF_PLATFORM_JOBS} />
            </Grid>
          </Grid>
        </Grid>
        <ZapierConfirmationModal />
      </FormProvider>
    </>
  );
}