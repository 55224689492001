import { TextField, TextFieldProps, Slider, Grid, Typography } from '@mui/material';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { Controller, useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';

export interface IHookDurationFieldProps {
  name: string;
  label: string;
  required?: boolean | any;
  maxLength?: string | number;
  validationRules?: any;
  textFieldProps?: any;
  postOnChange?: Function;
}

const durationRegex = /^P(?!$)(\d+Y)?(\d+M)?(\d+D)?(T(?=\d)(\d+H)?(\d+M)?(\d+(\.\d+)?S)?)?$/;

export default function HookDurationField({
  name,
  label,
  required,
  maxLength,
  validationRules,
  postOnChange,
  defaultValue = '',
  ...textFieldProps
}: IHookDurationFieldProps | TextFieldProps | any) {
  const methods = useFormContext();
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  const [duration, setDuration] = useState({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (defaultValue) {
      const match = durationRegex.exec(defaultValue);
      if (match) {
        setDuration({
          years: parseInt(match[1]) || 0,
          months: parseInt(match[2]) || 0,
          days: parseInt(match[3]) || 0,
          hours: parseInt(match[5]) || 0,
          minutes: parseInt(match[6]) || 0,
          seconds: parseFloat(match[7]) || 0,
        });
      }
    }
  }, [defaultValue]);

  const handleSliderChange = (field: string, value: number) => {
    setDuration((prev) => ({ ...prev, [field]: value }));
    const newValue = `P${duration.years ? `${duration.years}Y` : ''}${duration.months ? `${duration.months}M` : ''}${duration.days ? `${duration.days}D` : ''}T${duration.hours ? `${duration.hours}H` : ''}${duration.minutes ? `${duration.minutes}M` : ''}${duration.seconds ? `${duration.seconds}S` : ''}`;
    methods.setValue(name, newValue);
    if (postOnChange) {
      postOnChange(newValue);
    }
  };

  const rules: any = {
    required: {
      value: required || false,
      message: 'Required',
    },
    maxLength: {
      value: maxLength || 0,
      message: `Must be ${maxLength} characters or less`,
    },
    validate: (value: string) => durationRegex.test(value) || 'Invalid duration format for ISO 8601. Example: P1Y2M3DT4H5M6S',
    ...validationRules,
  };
  if (!maxLength) {
    delete rules.maxLength;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <>
          <Grid container spacing={2}>
            {['years', 'months', 'days', 'hours', 'minutes', 'seconds'].map((field) => (
              <Grid item xs={12} md={4} key={field}>
                <Typography gutterBottom>{field.charAt(0).toUpperCase() + field.slice(1)}</Typography>
                <Slider
                  value={duration[field]}
                  onChange={(e, newValue) => handleSliderChange(field, newValue as number)}
                  aria-labelledby={`${field}-slider`}
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={field === 'years' ? 100 : field === 'months' ? 12 : field === 'days' ? 31 : 60}
                />
                <Typography variant="body2" color="textSecondary">
                    Selected value: {duration[field]}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <TextField
            fullWidth
            id={`${name}-input`}
            variant='standard'
            label={label}
            inputRef={ref}
            error={isError(name)}
            helperText={(isError(name) && getErrorMessage(name)) || ' '}
            value={`P${duration.years}Y${duration.months}M${duration.days}DT${duration.hours}H${duration.minutes}M${duration.seconds}S`}
            onChange={onChange}
            onBlur={onBlur}
            {...textFieldProps}
            style={{ display: 'none' }} // Hide the text field
          />
        </>
      )}
    />
  );
}