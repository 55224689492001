import { Grid, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useNeighborhoodMailerConfig from './hookStore/useNeighborhoodMailersConfig';
import NeighborhoodMailersConfigForm from './NeighborhoodMailersConfigForm';

export interface INeighborhoodMailersConfigProps {
  isNew: boolean;
}

export default function NeighborhoodMailersConfig({
  isNew,
}: INeighborhoodMailersConfigProps) {
  const { loadingKey } = useNeighborhoodMailerConfig();
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'Neighborhood Mailers Config'} />
        </Grid>
        <Grid item xs={12}>
          <NeighborhoodMailersConfigForm isNew={isNew} />
        </Grid>
      </Grid>
    </Loading>
  );
}