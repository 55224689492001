import { Grid } from '@mui/material';
import { saveJobberIntegration } from 'api/serviceProviderApi';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import { ISaveJobberIntegrationDTO } from 'model/service_provider_config/service_provider_integration_config';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import { useLoading } from 'components/Layout/Loading';
import { useParams } from 'react-router-dom';
import useToast from 'components/toast/useToast';

export const jobberConfirmationModalKey = 'JobberConfirmationModal';

export function JobberConfirmationModal() {
  const { loadingKey, refresh } = useProviderIntegrationDetails();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createSuccessToast, createErrorToast } = useToast();

  return <HookConfirmationModal
    title={() => 'Save Jobber Configuration'}
    body='Save changes?'
    modalKey={jobberConfirmationModalKey}
    message={(confirmArgs, cancelModal) => (
      <Grid container spacing={1} alignItems='center' justifyContent='center' paddingTop={'15px'} >
        <Grid item xs={12} paddingBottom={'25px'}>
          Save changes to the Jobber Configuration?
        </Grid>
      </Grid>
    )}
    onConfirm={async (formData) => {
      try {
        onLoading();
        const payload:ISaveJobberIntegrationDTO = {
          status: formData.status,
          debug: formData.debug,
          defaultServiceTypeId: formData.defaultServiceType,
        };

        if (serviceProviderId) {
          const resp = await saveJobberIntegration(serviceProviderId, payload);

          if (resp.status === 200) {
            createSuccessToast('Jobber Configuration Saved!');
            await refresh();
          } else {
            createErrorToast('Error saving Jobber Configuration');
          }
        }
      } catch (error) {
        console.error('error saving Jobber config', error);
        createErrorToast('Error saving Jobber Configuration');
      } finally {
        doneLoading();
      }
    }}
  />;
}
