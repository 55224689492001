import { Grid } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';

export function FormButtons() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={1} justifyItems={'flex-end'} justifyContent={'flex-end'}>
      <Grid item xs='auto'>
        <PrimaryButton
          type='submit'
        >
        Save
        </PrimaryButton>
      </Grid>
      <Grid item xs='auto'>
        <SecondaryButton
          onClick={() => navigate('/users')}
        >
        Cancel
        </SecondaryButton>
      </Grid>
    </Grid>
  );
}
