import { Grid } from '@mui/material';
import { saveNeighborhoodNudgeIntegration } from 'api/serviceProviderApi';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import { ISaveNeighborhoodNudgeIntegrationDTO } from 'model/service_provider_config/service_provider_integration_config';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import { useLoading } from 'components/Layout/Loading';
import { useParams } from 'react-router-dom';
import useToast from 'components/toast/useToast';

export const neighborhoodNudgesConfirmationModalKey = 'NeighborhoodNudgesConfirmationModal';

export function NeighborhoodNudgesConfirmationModal() {
  const { loadingKey, refresh } = useProviderIntegrationDetails();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createSuccessToast, createErrorToast } = useToast();

  return <HookConfirmationModal
    title={() => 'Save Neighborhood Nudges Configuration'}
    body='Save changes?'
    modalKey={neighborhoodNudgesConfirmationModalKey}
    message={(confirmArgs, cancelModal) => (
      <Grid container spacing={1} alignItems='center' justifyContent='center' paddingTop={'15px'} >
        <Grid item xs={12} paddingBottom={'25px'}>
          Save changes to the Neighborhood Nudges Configuration?
        </Grid>
      </Grid>
    )}
    onConfirm={async (formData) => {
      try {
        onLoading();
        const payload:ISaveNeighborhoodNudgeIntegrationDTO = {
          recontactDays: formData.recontactDays,
          recontactDaysRepeat: formData.recontactDaysRepeat,
          maxPerBatch: formData.maxPerBatch,
          businessOwnerFirstName: formData.businessOwnerFirstName,
          status: formData.status,
          debug: formData.debug,
        };

        if (serviceProviderId) {
          const resp = await saveNeighborhoodNudgeIntegration(serviceProviderId, payload);

          if (resp.status === 200) {
            createSuccessToast('Neighborhood Nudges Configuration Saved!');
            await refresh();
          } else {
            createErrorToast('Error saving Neighborhood Nudges Configuration');
          }
        }
      } catch (error) {
        console.error('error saving NN config', error);
        createErrorToast('Error saving Neighborhood Nudges Configuration');
      } finally {
        doneLoading();
      }
    }}
  />;
}
