
import styles from '../providerOnboarding.module.css';
import { Typography, Grid } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';
import AdditionalRelevantPlatformRatings from 'pages/serviceProvider/serviceProviderDetails/formComponents/advanced/components/AdditionalRelevantPlatformRatings';
import FacebookRatingInfo from 'pages/serviceProvider/serviceProviderDetails/formComponents/advanced/components/FacebookRatingInfo';
import RatingInfo from 'pages/serviceProvider/serviceProviderDetails/formComponents/advanced/components/RatingInfo';


export default function RatingInformation() {
  return <><Typography variant='h6' className={styles.sectionTitle}>Rating Information</Typography><Grid container item xs={12} sx={{ flexDirection: 'column' }}>
    <Grid item xs={6}>
      <HookTextField
        label='Google Place Id'
        name='googlePlaceId' />
    </Grid>
    <Grid item xs={12}>
      <RatingInfo/>
    </Grid>
    <Grid item xs={12}>
      <FacebookRatingInfo />
    </Grid>
    <Grid>
      <AdditionalRelevantPlatformRatings />
    </Grid>
  </Grid></>;
}