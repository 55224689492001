import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AddNewServiceProviderButton() {
  const navigate = useNavigate();

  const addNewButtonClick = useCallback(() => {
    navigate('/service-providers/create/service-provider-details');
  }, []);


  return (
    <Button variant="contained" onClick={addNewButtonClick}>Add New</Button>
  );
}