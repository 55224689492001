import { AutocompleteChangeReason, Card, CardContent, Grid, Typography } from '@mui/material';
import TransferReview from './TransferReview';
import { IDropdownOption } from 'model/dropdown';
import { PrimaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';
import useTransferServiceProviderData from '../hookStore/useTransferServiceProviderData';

export default function TransferReviews() {
  const {
    reviews,
    onTransferReviews,
    transferServiceProviderId,
  } = useTransferServiceProviderData();

  if (reviews.length === 0) {
    return null;
  }

  return (
    <div>
      <Typography variant="h6">Reviews</Typography>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2} >
            <Grid item sx={{ paddingTop: '20px' }}>
              <PrimaryButton
                disabled={!transferServiceProviderId}
                onClick={onTransferReviews}
              >
              Transfer All
              </PrimaryButton>
            </Grid>

          </Grid>
        </Grid>
        {reviews.map(review => (
          <Grid item xs='auto' key={review.id}>
            <TransferReview review={review} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}