import { Box, Card, CardContent, Typography } from '@mui/material';
import useTransferServiceProviderData from '../hookStore/useTransferServiceProviderData';
import { activeOrGreater } from 'util/serviceProviderUtil';

export default function FromServiceProviderSummary() {
  const { serviceProvider } = useTransferServiceProviderData();
  return (
    <Box sx={{ marginBottom: '32px' }}>
      <Typography variant="h6">From Service Provider</Typography>
      <Card variant="outlined" sx={{ maxWidth: '450px' }}>
        <CardContent>
          <>
            <Typography variant="body1">ID: {serviceProvider?.id}</Typography>
            <Typography variant="body1">name: {serviceProvider?.name}</Typography>
            <Typography variant="body1">status: {serviceProvider?.status}</Typography>
            {serviceProvider?.status && activeOrGreater(serviceProvider?.status) && (
              <a href={`/service-providers/${serviceProvider?.id}/service-provider-details`} target="_blank" style={{ color: 'red' }}>Invalid status for transfer: edit here</a>
            )}
            {!serviceProvider?.status || !activeOrGreater(serviceProvider?.status) && (
              <a href={`/service-providers/${serviceProvider?.id}/service-provider-details`} target="_blank" >edit</a>
            )}

          </>
        </CardContent>
      </Card>
    </Box>
  );
}