import { AutocompleteChangeReason } from '@mui/material';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import { IDropdownOption } from 'model/dropdown';

export interface ITransferReviewSearchBoxProps {
  postOnChange: (formCtx: any, selectedOption: IDropdownOption | null, reason: AutocompleteChangeReason, details: any) => void;
}

export default function TransferReviewSearchBox({
  postOnChange,
}: ITransferReviewSearchBoxProps,
) {
  return (
    <ServiceProviderAutocomplete
      label={'Transfer to Service Provider'}
      formless={true}
      placeholder={''}
      postOnChange={postOnChange}
      required={false}/>
  );
}