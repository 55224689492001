
import { AutocompleteChangeReason, AutocompleteInputChangeReason, Box, FormControl, FormHelperText } from '@mui/material';
import { HookSingleAsyncAutocomplete } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import useServiceProviderAutocomplete from './useServiceProviderAutocomplete';
import { IDropdownOption } from 'model/dropdown';
import { useEffect } from 'react';
import useMarkets from 'hooks/useMarkets';
import { formatMonthDayYearTime } from 'util/dateUtil';


export interface IServiceProviderAutocompleteProps {
  label:string;
  fieldName?: string;
  formless: boolean;
  placeholder:string;
  required: boolean;
  controlledValue?: string;
  suppressEmptyStringSearch?:boolean;
  postOnChange?: (formCtx: any, selectedOption: IDropdownOption | null, reason: AutocompleteChangeReason, details: any) => void;
  rules?:any;
}

export interface IFormlessForm {
  selectedServiceProvider:IDropdownOption | null;
}

const renderOption = (props, option, { selected }) => {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return (<li {...props}>{option}</li>);
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return <li {...props}>Add "{option.inputValue}"</li>;
  }
  // Regular option
  return (<li {...props} style={{ display: 'block' }}>
    <Box sx={{ display: 'block', fontSize: '14px' }}>
      {`${option.optionText} (${option.ancillary.status})`}
    </Box>
    <Box sx={{ display: 'block', fontSize: '11px' }}>
      {option.ancillary.id}
    </Box>
    <Box sx={{ display: 'block', fontSize: '11px' }}>
      Created: {option.ancillary.createDate ? formatMonthDayYearTime(new Date(option.ancillary.createDate)) : 'N/A'}
    </Box>
  </li>);
};

export default function ServiceProviderAutocomplete({
  label,
  fieldName,
  formless,
  placeholder,
  required,
  controlledValue,
  suppressEmptyStringSearch,
  rules,
  postOnChange,
}:IServiceProviderAutocompleteProps) {
  if (!formless && !fieldName) {
    throw new Error('fieldName is required when formless is false');
  }
  let _rules: any;
  if (rules) {
    _rules = rules;
  } else if (required) {
    rules = {
      required: {
        value: true, message: 'Required',
      },
    };
  }
  const { options, onSearchServiceProviders, initWithValue } = useServiceProviderAutocomplete();
  const { selectedMarket } = useMarkets();

  if (!formless) {

    const formCtx = useFormContext();
    const { isError, getErrorMessage } = useErrorState(formCtx);

    useEffect(() => {
      if (!selectedMarket) {
        return;
      }
      if (controlledValue && options.length === 0) {
        void initWithValue(formCtx, fieldName!, controlledValue);
      }
    }, [controlledValue, selectedMarket]);

    return (
      <FormControl variant='standard' fullWidth error={formless ? false : isError(fieldName!)}>
        <HookSingleAsyncAutocomplete
          rules={_rules}
          name={fieldName!}
          label={label}
          dropdownOptions={options}
          placeholder={placeholder}
          onInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
            if (!suppressEmptyStringSearch || value) {
              void onSearchServiceProviders(value);
            }
          }}
          postOnChange={(formContext, value, reason, details) => {
            if (postOnChange) {
              postOnChange(formContext, value as IDropdownOption, reason, details);
            }

          }}
          extraProps={{
            renderOption: renderOption,
          }}
        />
        <FormHelperText>{getErrorMessage(fieldName!) || ' '}</FormHelperText>
      </FormControl>
    );
  } else {
    const formContext = useForm<IFormlessForm>({
      mode: 'onTouched',
      defaultValues: {
        selectedServiceProvider: null,
      },
    });
    const { isError, getErrorMessage } = useErrorState(formContext);
    useEffect(() => {
      if (!selectedMarket) {
        return;
      }
      if (controlledValue && options.length === 0) {
        void initWithValue(formContext, 'selectedServiceProvider', controlledValue);
      }
    }, [controlledValue, selectedMarket]);

    return (
      <FormProvider {...formContext}>
        <FormControl variant='standard' fullWidth error={formless ? false : isError('selectedServiceProvider')}>
          <HookSingleAsyncAutocomplete
            rules={_rules}
            name={'selectedServiceProvider'}
            label={label}
            dropdownOptions={options}
            placeholder={placeholder}
            onInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
              if (!suppressEmptyStringSearch || value) {
                void onSearchServiceProviders(value);
              }
            }}
            postOnChange={(formCtx, value, reason, details) => {
              if (postOnChange) {
                postOnChange(formCtx, value as IDropdownOption, reason, details);
              }
            }}
            extraProps={{
              renderOption: renderOption,
            }}
          />
          <FormHelperText>{getErrorMessage('selectedServiceProvider') || ' '}</FormHelperText>
        </FormControl>
      </FormProvider>
    );
  }

}