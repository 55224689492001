import { DataGrid } from '@mui/x-data-grid';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import useToast from 'components/toast/useToast';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMarkets from 'hooks/useMarkets';
import { columns, columnTotalWidth } from './tableConfig';
import { debounce } from '@mui/material';
import { ISearchPage, IServiceProviderSearchRequest, IStreetFairSearchRequest } from 'model/search';
import { searchServiceProviders } from 'api/serviceProviderApi';
import { IServiceProvider } from 'model/serviceProvider';
import { AxiosResponse } from 'axios';
import useServiceProviderTable from './hookStore/useServiceProviderTable';

export const quickSearchStorageKey = 'serviceProviders';

const debouncedSearchServiceProviders = debounce(async (dto:IStreetFairSearchRequest,
  callback:(serviceProviderSearchResultType) => any): Promise<AxiosResponse<ISearchPage<IServiceProvider>, any>> => {
  const res = await searchServiceProviders(dto);
  callback(res.data);
  return res;
}, 250);


export default function ServiceProviderTable() {
  const navigate = useNavigate();
  const { selectedMarket } = useMarkets();
  const { createErrorToast, createSuccessToast } = useToast();
  const { triggerSearchTableCount } = useServiceProviderTable();
  const {
    searchResultsLoading,
    rows,
    rowCountState,
    searchText,
    paginationModel,
    setRowsWithStoredFilter,
    setSearchResultsLoading,
    // requestSearch,
    clearStoredSearch,
    setRowCountState,
    requestAsyncSearch,
    onPageChange,
    onPageSizeChange,
    setPaginationModel,
  } = useTableSearch(quickSearchStorageKey, [], 10);


  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);


  async function _findAllServiceProviders() {
    setSearchResultsLoading(true);
    var searchRequest:IServiceProviderSearchRequest = {
      offset: 0,
      pageNumber: paginationModel.page,
      pageSize: paginationModel.pageSize,
      query: searchText,
      marketId: selectedMarket?.id,
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    debouncedSearchServiceProviders(searchRequest, (data:ISearchPage<IServiceProvider>) => {
      setRowCountState(data.availablePagesCount * data.pageSize);
      setRowsWithStoredFilter(data.items);
      setSearchResultsLoading(false);
    });
  }

  useEffect(() => {
    if (selectedMarket) {
      void _findAllServiceProviders();
    }
  }, [paginationModel, selectedMarket]);

  useEffect(() => {
    if (triggerSearchTableCount > 0) {
      void _findAllServiceProviders();
    }
  }, [triggerSearchTableCount]);

  return (
    <DataGrid
      loading={searchResultsLoading}
      pagination={true}
      rowsPerPageOptions={[10, 20, 50]}
      page={paginationModel.page}
      pageSize={paginationModel.pageSize}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      getRowId={(row) => row.objectID ?? row.id}
      paginationMode='server'
      rowCount={rowCountState}
      rows={rows}
      components={{ Toolbar: QuickSearchToolbar }}
      componentsProps={{
        toolbar: {
          quickSearchStorageKey,
          value: searchText,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            void requestAsyncSearch(debouncedSearchServiceProviders,
              {
                offset: 0,
                pageNumber: paginationModel.page,
                pageSize: paginationModel.pageSize,
                query: event.target.value,
                marketId: selectedMarket?.id,
              },
            );
          },
          clearSearch: () => {
            setPaginationModel({
              page: 0,
              pageSize: paginationModel.pageSize,
            });
            clearStoredSearch();
            void requestAsyncSearch(debouncedSearchServiceProviders,
              {
                offset: 0,
                pageNumber: 0,
                pageSize: paginationModel.pageSize,
                query: '',
                marketId: selectedMarket?.id,
              });
          },
        },
      }}
      columns={columns}
      autoHeight={true}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      style={{
        minWidth: '500px',
        maxWidth: `${columnTotalWidth}px`,
      }}
    />
  );
}