import { Grid, Typography } from '@mui/material';
import useTransferServiceProviderData from '../hookStore/useTransferServiceProviderData';
import { IInternalServiceProvider } from 'model/serviceProvider';

export interface IProviderPartnershipDataProps {
  serviceProvider: IInternalServiceProvider | null;
  title: string;
}

export default function ProviderPartnershipData({ serviceProvider, title }: IProviderPartnershipDataProps) {
  return (
    <Grid container sx={{ marginBottom: '32px' }}>
      <Grid item xs={12}>
        <div>
          <Typography variant='h6'>{title}</Typography>
          <Typography variant='body1'>Provider ID: {serviceProvider?.id}</Typography>
          <Typography variant='body1'>Provider Name: {serviceProvider?.name}</Typography>
          <Typography variant='body1'>
            <strong>Agreed to Conditions:</strong> {serviceProvider?.agreedToConditions}
          </Typography>
          <Typography variant='body1'>
            <strong>Custom Conditions:</strong> {serviceProvider?.customConditions}
          </Typography>
          <Typography variant='body1'>
            <strong>Agreed to Partnership Date:</strong> {serviceProvider?.agreedToPartnershipDate}
          </Typography>
          <Typography variant='body1'>
            <strong>Agreed to Partnership:</strong> {serviceProvider?.agreedToPartnership ? 'True' : 'False'}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}