
import styles from './CopyToClipboard.module.css';
import CopyToClipboardButton from './CopyToClipboardButton';

export default function CopyToClipboardCell({ val, copyText }:any) {
  return (
    <div className={styles.flexWrapper}>
      <div>{val}</div>
      <div className={styles.spacer}></div>
      <div>
        <CopyToClipboardButton
          val={copyText ?? val}
        />
      </div>
    </div>
  );
}