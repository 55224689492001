import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import ApiKeyGenCell from './ApiKeyGenCell';
import EditCell from './EditCell';
import ToggleCell from './toggleCell';
import DeleteCell from './DeleteCell';
import MergeCell from './MergeCell';


export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderCell: (params) => {
      return <a target="_blank" href={`${window.REACT_APP_PROVIDER_BASE_URI}/v1/${params.row.id ?? params.row.objectID}`} >{params.value}</a>;
    },
  },
  {
    field: 'contactPersonPhone',
    headerName: 'Phone',
    width: 125,
    renderCell: (params:any) => {
      return <span>{params.row.contactPersonPhone || 'No phone set'}</span>;
    },
  },
  {
    field: 'contactPersonEmail',
    headerName: 'Email',
    width: 250,
    renderCell: (params:any) => {
      return <span>{params.row.contactPersonEmail || 'No email set'}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 125,
    renderCell: (params:any) => {
      return <span>{params.row.status}</span>;
    },
  },
  {
    field: 'id',
    headerName: 'GUID',
    width: 350,
    renderCell: (params:any) => {
      return (<CopyToClipboardCell val={params.id ?? params.row.objectID}/>);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 250,
    renderCell: (params:any) => {


      return (
        <Grid container item xs={12} justifyContent='center' alignItems='center'>
          <ApiKeyGenCell id={params.id ?? params.row.objectID} name={params.row.name}/>
          <ToggleCell
            id={params.id ?? params.row.objectID}
            name={params.row.name}
            wasEnabled={!params.row.disabledNeighborhoodServiceOfferingsDate}
          />
          <MergeCell id={params.id ?? params.row.objectID} name={params.row.name}/>
          <EditCell id={params.id ?? params.row.objectID} name={params.row.name}/>
          <DeleteCell id={params.id ?? params.row.objectID} name={params.row.name}/>
        </Grid>
      );
    },
  },
];
export const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);