import { syncStreetName, uploadPropStreamFile } from 'api/launchToolingApi';
import { uploadHomeSales, uploadNeighborhoodWKT } from 'api/neighborhoodApi';
import { deleteUser, findAllUsers, findUserByEmail, findUsersByServiceProvider } from 'api/userApi';
import { useTableSearchByStorageKey } from 'components/grid/useTableSearch';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { quickSearchStorageKey } from '../table/UsersTable';

type UsersTable = {
  users: any[];
  isLoadAllUsersDisabled: boolean;
  searchedEmail: string;
}
const loadingKey = 'UsersTable';

const { get, update, registerListener, unregisterListener } = createStore<UsersTable>('UsersTable', {
  users: [],
  isLoadAllUsersDisabled: true,
  searchedEmail: '',
});

export default function useUsersTable() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();
  const { tableSearch } = useTableSearchByStorageKey(quickSearchStorageKey);

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        users: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
    });
    doneLoading(300);
  }

  function updateSearchedEmail(email:string) {
    update({
      ...get(),
      searchedEmail: email,
    });
  }

  async function onFindUsersByServiceProvider(selectedServiceProviderId?:string | null) {
    onLoading();
    if (!selectedServiceProviderId) {
      createErrorToast('Please select a service provider');
      return;
    }
    const res = await findUsersByServiceProvider( selectedServiceProviderId);
    update({
      ...get(),
      users: res.data,
    });
    doneLoading();
  }


  async function onFindUserByEmail() {
    const { searchedEmail } = get();
    if (!searchedEmail) {
      createErrorToast('Please enter email');
      return;
    }
    console.log('searchedEmail', searchedEmail);
    onLoading();
    const _users = await findUserByEmail(searchedEmail);
    update({
      ...get(),
      users: _users.data,
    });
    doneLoading();
  }


  async function getAllUsers() {
    if (window.REACT_APP_NODE_ENV !== 'local') {
      update({
        ...get(),
        isLoadAllUsersDisabled: true,
      });
      onLoading();
      const _users = await findAllUsers();
      update({
        ...get(),
        users: _users.data,
      });
      doneLoading();
      setTimeout(function() {
        update({
          ...get(),
          isLoadAllUsersDisabled: false,
        });
      }, 10000);
    }
  }


  async function onDeleteUser({ id }) {
    if (id) {
    //   await deleteUser(id);
      tableSearch.clearStoredSearch();
      createInfoToast('User with id: ' + id + ' deleted');
    }
  }

  return {
    loadingKey,
    ...get(),
    init,
    updateSearchedEmail,
    onFindUserByEmail,
    onDeleteUser,
    getAllUsers,
    onFindUsersByServiceProvider,
  };
}