import { Grid } from '@mui/material';
import { saveEmailIntegration, saveZapierIntegration } from 'api/serviceProviderApi';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import { ISaveEmailIntegrationDTO } from 'model/service_provider_config/service_provider_integration_config';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import { useLoading } from 'components/Layout/Loading';
import { useParams } from 'react-router-dom';
import useToast from 'components/toast/useToast';

export const emailConfirmationModalKey = 'EmailConfirmationModal';

export function EmailConfirmationModal() {
  const { loadingKey, refresh } = useProviderIntegrationDetails();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createSuccessToast, createErrorToast } = useToast();

  return <HookConfirmationModal
    title={() => 'Save Email Configuration'}
    body='Save changes?'
    modalKey={emailConfirmationModalKey}
    message={(confirmArgs, cancelModal) => (
      <Grid container spacing={1} alignItems='center' justifyContent='center' paddingTop={'15px'} >
        <Grid item xs={12} paddingBottom={'25px'}>
          Save changes to the Email Configuration?
        </Grid>
      </Grid>
    )}
    onConfirm={async (formData) => {
      try {
        onLoading();
        const payload:ISaveEmailIntegrationDTO = {
          status: formData.status,
          debug: formData.debug,
          defaultServiceTypeId: formData.defaultServiceType,
        };

        if (serviceProviderId) {
          const resp = await saveEmailIntegration(serviceProviderId, payload);

          if (resp.status === 200) {
            createSuccessToast('Email Configuration Saved!');
            await refresh();
          } else {
            createErrorToast('Error saving Email Configuration');
          }
        }
      } catch (error) {
        console.error('error saving email config', error);
        createErrorToast('Error saving Email Configuration');
      } finally {
        doneLoading();
      }
    }}
  />;
}
