import { Grid } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import DisableNsoModal, { } from './modal/DisableNsoModal';
import CreateApiKeyModal from './modal/CreateApiKeyModal';
import ServiceProviderTable from './ServiceProviderTable';
import AddNewServiceProviderButton from './AddNewServiceProviderButton';
import DeleteServiceProviderModal from './modal/DeleteServiceProviderModal';

export default function ServiceProviders() {
  return (
    <>
      <Grid container spacing={1} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'SERVICE PROVIDERS'} />
        </Grid>
        <Grid item xs={12}>
          <AddNewServiceProviderButton/>
        </Grid>
        <Grid item xs={12}>
          <ServiceProviderTable/>
        </Grid>
      </Grid>
      <DeleteServiceProviderModal/>
      <DisableNsoModal/>
      <CreateApiKeyModal/>
    </>
  );
}