
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip, IconButton } from '@mui/material';
import useToast from 'components/toast/useToast';

export interface CopyToClipboardButtonProps {
  val: string;
}

export default function CopyToClipboardButton({ val }: CopyToClipboardButtonProps) {
  const {
    createInfoToast,
  } = useToast();
  return (<Tooltip title="Copy to clipboard">
    <IconButton onClick={() => {
      void navigator.clipboard.writeText(val);
      createInfoToast('Copied to clipboard');
    }}>
      <ContentCopyIcon
        className='copyIcon'
      />
    </IconButton>
  </Tooltip>);
}