import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { Alert, Grid, Typography } from '@mui/material';
import { IServiceProvider } from 'model/serviceProvider';

export const similarServiceProviderModalKey = 'SimilarServiceProviderConfirmationModal';

export interface ISimilarServiceProviderConfirmationModal extends IModal {
  serviceProviders: IServiceProvider[];
  onConfirmCallback: () => void;
  onCancelCallback: () => void;
}
export function createEmptySimilarServiceProviderConfirmationModal() :ISimilarServiceProviderConfirmationModal {
  return {
    open: false,
    serviceProviders: [],
    onConfirmCallback: () => {},
    onCancelCallback: () => {},
  };
}

export default function SimilarServiceProviderConfirmationModal() {
  const { closeModal, getModalState } = useModal(similarServiceProviderModalKey);
  var modalState = getModalState() as ISimilarServiceProviderConfirmationModal;

  var {
    serviceProviders = [],
  } = modalState;

  const onSubmit = async (data:any) => {
    try {
      modalState.onConfirmCallback();
    } catch (error) {
      console.error(error);
    }
    closeModal(createEmptySimilarServiceProviderConfirmationModal());
  };

  return (
    <GenericModal
      modalStoreKey={similarServiceProviderModalKey}
      title={'WARNING: Similar Service Provider(s) Found!'}
      modalContent={
        (
          <Grid container item justifyContent='center' rowSpacing={2}>
            <Alert severity='warning'>
                The following service provider(s) are similar to the one you are trying to save:
              {serviceProviders.map((x, i) => (
                <Typography key={i} variant='body1'>- {x.name}</Typography>
              ))}
            </Alert>
            <Grid item xs={11}>
              <Typography variant='body1'>Do you want to proceed with saving?</Typography>
            </Grid>
          </Grid>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => {
        closeModal(createEmptySimilarServiceProviderConfirmationModal());
        modalState.onCancelCallback();
      }}
      primaryButtonText='Yes, Proceed'
      secondaryButtonText='No, Cancel'
      overrideSx={{
        '& .MuiPaper-root': {
          width: '600px',
        },
      }}
    />
  );
}