import { Chip, Grid, IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { RemoveButton, EditButton } from 'components/buttons';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import { ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import { useContext } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import styles from './users.module.css';
import CopyToClipboardButton from 'components/DataGridCell/CopyToClipboardButton';

//TODO: need to update the delete logic. it is incomplete.
// function DeleteCell({ id: id, name: name }) {
//   const confirmationModalCtx = useContext(ConfirmationModalContext);
//   return (
//     <RemoveButton
//       tooltipTitle='Remove user'
//       onClick={() => confirmationModalCtx.openModal({ id, name })}
//       tooltipProps={{
//         placement: 'top',
//       }}
//       iconButtonProps={{
//         className: styles.actionButton,
//       }}
//     />
//   );
// }

function EditCell({ id, name }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/users/${id}/user-details`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

export const columns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'email',
    width: 400,
    renderCell: (params: any) => {
      if (!params.row.email && !params.row.individualId) {
        return <Chip label="Unverified" variant='filled'></Chip>;
      }
      return (
        <div className={styles.flexWrapper}>
          <div>{params.row.email ?? params.row.individualId}</div>
          <div className={styles.spacer}></div>
          <CopyToClipboardButton
            val={(params.row.email ?? params.row.individualId ?? '').toString()}
          />
        </div>
      );
    },
  },
  {
    field: 'userType',
    headerName: 'User Type',
    width: 200,
    renderCell: (params: any) => {
      const userType = params.row.userType;
      if (userType === 'ROLE_ADMIN') {
        return <div>Admin Site User</div>;
      }
      if (userType === 'ROLE_CONSUMER') {
        return <div>Consumer Site User</div>;
      }
      if (userType === 'ROLE_PROVIDER') {
        return <div>Provider Site User</div>;
      }
      return <div>Not associate to site</div>;
    },
  },
  {
    field: 'id',
    headerName: 'GUID',
    width: 450,
    renderCell: (params: any) => {
      return (<CopyToClipboardCell val={params.id}/>);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: (params:any) => {
      return (
        <Grid container item xs={12} justifyContent='flex-end' alignItems='center' sx={{ paddingRight: '20px' }}>
          <EditCell id={params.id} name={params.row.name} />
          {/* <DeleteCell id={params.id} name={params.row.name}/> */}
        </Grid>
      );
    },
  },
];
export const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);