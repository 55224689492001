
import { Grid, Typography } from '@mui/material';
import ManualWorkflowRmosfForm from './ManualWorkflowRmosfForm';
import ManualCrmScheduledRmosfForm from './manual_crm_scheduled/ManualCrmScheduledRmosfForm';

export default function RecommendMeOnStreetFairTesting() {

  return (
    <>
      <Grid container sx={{ padding: '24px' }}>
        <Grid item xs={12} sx={{ marginBottom: '24px' }}>
          <Typography variant='h5'>Recommend me On StreetFair (Past Customer Request to Review) testing</Typography>
        </Grid>
        <ManualWorkflowRmosfForm/>
        <ManualCrmScheduledRmosfForm/>
      </Grid>
    </>
  );
}