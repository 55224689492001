import { Grid, Typography } from '@mui/material';
import useTransferServiceProviderData from '../hookStore/useTransferServiceProviderData';
import { PrimaryButton } from 'components/buttons';
import TransferCoverageArea from './TransferCoverageArea';

export default function TranferCoverageAreas() {
  const {
    onTransferCoverageAreas,
    transferServiceProviderId,
    coverageAreas,
  } = useTransferServiceProviderData();

  if (!coverageAreas.length) {
    return null;
  }

  return (
    <div>
      <Typography variant="h6">Coverage Areas</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2} >
            <Grid item >
              <PrimaryButton
                disabled={!transferServiceProviderId}
                onClick={onTransferCoverageAreas}
              >
              Transfer All
              </PrimaryButton>
            </Grid>

          </Grid>
        </Grid>
        {coverageAreas.map(x => (
          <Grid item xs='auto' key={x.providerCoverageArea.id}>
            <TransferCoverageArea coverageArea={x}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}