import { PrimaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';
import useTransferServiceProviderData from '../hookStore/useTransferServiceProviderData';

export default function DeleteServiceProvider() {
  const { onDelete, canDelete } = useTransferServiceProviderData();
  const navigate = useNavigate();
  return (
    <PrimaryButton
      disabled={!canDelete()}
      onClick={async () => {
        await onDelete();
        navigate('/service-providers');
      }
      }
    >
        Delete Provider
    </PrimaryButton>
  );
}