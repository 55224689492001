
import { createValidateEmail } from 'util/rhfValidateFunctions';
import { sortByString } from 'util/sortFunctions';
import { InputLabel, Grid, FormControl, FormHelperText } from '@mui/material';
import { findAllServiceProviders } from 'api/serviceProviderApi';
import { saveProviderUser } from 'api/userApi';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { HookSelect, HookTextField } from 'components/reactHookForm';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { IServiceProviderUserDto } from 'model/user';
import { useState, useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

const validateEmail = createValidateEmail();
const sortByName = sortByString('name');

function FormButtons() {
  const navigate = useNavigate();
  return (
    <>
      <PrimaryButton
        type='submit'
      >
            Save
      </PrimaryButton>
      <SecondaryButton
        onClick={() => navigate('/users')}
      >
            Cancel
      </SecondaryButton>
    </>
  );
}


function ServiceProviderSelect() {
  const formCtx = useFormContext();
  const [serviceProviders, setServiceProviders] = useState<any[]>([]);
  useEffect(() => {
    async function getServiceProviders() {
      const providers = await findAllServiceProviders();
      const _serviceProviders = providers.data.sort(sortByName);
      setServiceProviders(_serviceProviders);
    }
    void getServiceProviders();
  }, []);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('serviceProviders')}>
      <InputLabel htmlFor="service-providers">Service Providers</InputLabel>
      <HookSelect
        control={formCtx.control}
        name='serviceProviderId'
        id='service-providers'
        menuItems={serviceProviders}
      />
      <FormHelperText>{getErrorMessage('serviceProviders')}</FormHelperText>
    </FormControl>
  );
}

export default function ProviderUserDetails() {

  const formContext = useForm<IServiceProviderUserDto>({ mode: 'onTouched' });
  const navigate = useNavigate();
  const { userId } = useParams();

  async function _saveUser(data:IServiceProviderUserDto) {
    const dataToSave = { ...data };
    await saveProviderUser(dataToSave);
    navigate('/users');
  }
  const onSubmit : SubmitHandler<IServiceProviderUserDto> = data => {
    void _saveUser(data);
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={2} className='pageGridContainer'>
          <Grid container item xs={12}>
            <PageHeader headerText={'Provider User Details'}/>
          </Grid>
          <Grid item xs={4}>
            <HookTextField
              label='Email'
              name='email'
              required
              validationRules={{
                validate: validateEmail,
              }}
            />
            <HookTextField
              label='First Name'
              name='firstName'
            />
            <HookTextField
              label='Last Name'
              name='lastName'
            />
          </Grid>
          <Grid item xs={4}>
            <ServiceProviderSelect/>
          </Grid>
          <Grid container item xs={12}>
            <FormButtons/>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}