import { getServiceOfferingStatusCheck } from 'api/serviceOfferingApi';
import { ToggleButton } from 'components/buttons';
import useModal from 'components/modal/useModal';
import { IDisableNsoModal } from './modal/DisableNsoModal';
import styles from './serviceProviders.module.css';
import { useEffect, useState } from 'react';
import useServiceProviderTable from './hookStore/useServiceProviderTable';

export default function ToggleCell({ id, name, wasEnabled }:any) {
  const { openModal } = useModal('disableNsoModal');
  const { toggledMap } = useServiceProviderTable();
  const [ephemeralToggle, setEphemeralToggle] = useState(toggledMap[id] ?? wasEnabled);
  useEffect(() => {
    if (toggledMap[id] !== null && toggledMap[id] !== undefined && toggledMap[id] !== ephemeralToggle) {
      setEphemeralToggle(toggledMap[id] ?? false);
    }
  }, [toggledMap[id]]);


  return (
    <ToggleButton
      toggled={ephemeralToggle}
      tooltipTitle={`Toggle ${name}`}
      onClick={async () => {
        const serviceOfferingStatusCheckRes = await getServiceOfferingStatusCheck(id);
        const state:IDisableNsoModal = {
          id,
          name,
          wasEnabled: ephemeralToggle,
          serviceOfferingStatusCheckDTO: serviceOfferingStatusCheckRes.data,
        };
        openModal(state);
      }}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}