import Axios, { AxiosResponse } from 'axios';
import { DynamoPage, IProviderStreetfairReview } from 'model/reviews/providerStreetFairReview';
import { IFindReviewsForProviderRequest } from 'model/reviews/providerStreetFairReviewRequest';

const apiUrl = window.REACT_APP_BASE_API_URI;

// TODO: temporary, probably not needed long term
export function manuallySendSubscriberReviews() {
  return Axios.get(`${apiUrl}/reviews/v2/scheduling/manuallySendSubscriberReviewRequests`);
}

export function manuallySendCustomerFollowUpReviews() {
  return Axios.get(`${apiUrl}/reviews/v2/scheduling/manuallySendCustomerFollowUpReviewRequests`);
}

export function findProviderStreetfairReviews(
  serviceProviderId:string,
  data: IFindReviewsForProviderRequest,
)
  : Promise<AxiosResponse<DynamoPage<IProviderStreetfairReview>>> {
  return Axios.post(
    `${apiUrl}/reviews/v3/serviceProviders/${serviceProviderId}/findProviderStreetfairReviews`,
    data,
  );
}

