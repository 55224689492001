import { RemoveButton } from 'components/buttons';
import styles from './serviceProviders.module.css';
import useModal from 'components/modal/useModal';
import { deleteServiceProviderModalStoreKey } from './modal/DeleteServiceProviderModal';

export default function DeleteCell({ id, name }) {
  const { openModal } = useModal(deleteServiceProviderModalStoreKey);
  return (
    <RemoveButton
      tooltipTitle='Remove service provider'
      onClick={() => openModal({ id, name })}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}