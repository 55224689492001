import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { splitNeighborhoods as onSplitNeighborhoods } from 'api/neighborhoodApi';
import { ISplitNeighborhoodRequest } from 'model/neighborhoods/neighborhoodRestructuring';
import { useParams } from 'react-router-dom';
import { deleteServiceProvider, findInternalServiceProviderById, findServiceProviderById } from 'api/serviceProviderApi';
import { createEmptyInternalServiceProvider, IInternalServiceProvider } from 'model/serviceProvider';
import { IProviderStreetfairReview } from 'model/reviews/providerStreetFairReview';
import { findProviderStreetfairReviews } from 'api/reviewsApi';
import { transferReviews } from 'api/mergeApi';

const loadingKey = 'ManageServiceProviderDeletion';

type ManageServiceProviderDeletion = {
  serviceProvider: IInternalServiceProvider | null;
  reviews:IProviderStreetfairReview[];
  reviewTransferServiceProviderId?: string;
}

const { get, update, registerListener, unregisterListener } = createStore<ManageServiceProviderDeletion>('ManageServiceProviderDeletion', {
  serviceProvider: null,
  reviews: [],
});

export default function useManageServiceProviderDeletion() {

  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
      update({
        ...get(),
        serviceProvider: null,
      });
    };
  }, []);

  async function init() {
    try {
      onLoading();
      if (!serviceProviderId) {
        createErrorToast('No service provider id provided');
        return;
      }
      const res = await findInternalServiceProviderById(serviceProviderId);
      let serviceProvider:IInternalServiceProvider = createEmptyInternalServiceProvider();
      let reviews: IProviderStreetfairReview[] = [];
      if (res.status === 200) {
        serviceProvider = res.data;
      }
      const reviewsRes = await findProviderStreetfairReviews(serviceProviderId, {});
      if (reviewsRes.status === 200) {
        reviews = reviewsRes.data.items;
      }

      update({
        ...get(),
        serviceProvider: serviceProvider,
        reviews: reviews,
      });
    } catch (e) {
      createErrorToast('Failed to load service provider');
    } finally {
      doneLoading(300);
    }
  }

  async function onDelete() {
    try {
      const res = await deleteServiceProvider(serviceProviderId!);
      if (res.data.errorMessage) {
        if (res.data.invalidStatus) {
          createErrorToast(res.data.errorMessage, null, { persistent: true });
        }
      } else if (res.data.message) {
        createSuccessToast(res.data.message);
      }
    } catch (e:any) {
      console.error(`Unable to delete service provider. Message=${e.response?.data?.message}`);
      createErrorToast(`Unable to delete service provider. Message=${e.response?.data?.message}`);
    }
  }

  function setReviewTransferServiceProviderId(selectedServiceProviderId:string) {
    update({
      ...get(),
      reviewTransferServiceProviderId: selectedServiceProviderId,
    });
  };

  async function onTransferReviews() {
    try {
      onLoading();
      const { reviewTransferServiceProviderId } = get();
      const res = await transferReviews(serviceProviderId!, reviewTransferServiceProviderId!);
      if (res.status === 200) {
        update({
          ...get(),
          reviews: [],
        });
        createInfoToast('Reviews transferred successfully');
      } else {
        createErrorToast('Failed to transfer reviews');
      }

    } catch (e) {

    } finally {
      doneLoading(300);
    }
  }

  return {
    ...get(),
    init,
    onDelete,
    setReviewTransferServiceProviderId,
    onTransferReviews,
    loadingKey,
  };
}