
import { testReferAProPostReview } from 'api/communicationsApi';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { testReferralFunding } from 'api/userApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IDropdownOption } from 'model/dropdown';
import { IServiceOffering } from 'model/serviceOffering';

import { useEffect, useState } from 'react';
import { convertToDropdown, IDropdownPropertyMapper } from 'util/dropdownUtil';

type ManualScheduledRecommendMeOnStreetFair = {
  serviceOfferingOptions: IDropdownOption[];
}
const loadingKey = 'ManualScheduledRecommendMeOnStreetFair';

const { get, update, registerListener, unregisterListener } = createStore<ManualScheduledRecommendMeOnStreetFair>('ManualScheduledRecommendMeOnStreetFair', {
  serviceOfferingOptions: [],
});

export default function useManualScheduledRecommendMeOnStreetFair() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        serviceOfferingOptions: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
    });
    doneLoading(300);
  }

  async function findAndSetExistingServiceOfferings(formContext:any, serviceProviderId:string) {
    const serviceOfferingsRes = await findServiceOfferingsByProvider(serviceProviderId);
    if (serviceOfferingsRes.data) {
      update({
        ...get(),
        serviceOfferingOptions: convertServiceOfferingsForDropdown(serviceOfferingsRes.data),
      });
    } else {
      update({
        ...get(),
        serviceOfferingOptions: [],
      });
    }
  }

  function convertServiceOfferingsForDropdown(data:IServiceOffering[]): IDropdownOption[] {
    const propertyMapper:IDropdownPropertyMapper<IServiceOffering> = {
      getKey: x => x.id,
      getOptionText: x => x.name,
      getOptionValue: x => x.id,
      getAncillary: x => x,
    };
    return convertToDropdown(data, x => x.id !== null, propertyMapper);
  }

  return {
    loadingKey,
    ...get(),
    init,
    findAndSetExistingServiceOfferings,
  };
}