

import debounce from '@mui/utils/debounce';
import { escapeRegExp } from 'components/grid/QuickSearchToolbar';
import { useState } from 'react';
import { getStoredSaveQuickSearch, getStoredSearchValue, setStoredSearchValue } from './storage';
import { ISearchPage, IStreetFairSearchRequest } from 'model/search';
import { AxiosResponse } from 'axios';
import { GridCallbackDetails } from '@mui/x-data-grid';

//look up a tableSearch hook instance by its key
const tableSearchStorageMap = new Map<string, any>();

function setItem(key: string, value: any) {
  tableSearchStorageMap.set(key, value);
}

function getItem(key: string) {
  return tableSearchStorageMap.get(key);
}

function removeItem(key: string) {
  tableSearchStorageMap.delete(key);
}

function clearStorage() {
  tableSearchStorageMap.clear();
}

/**
 *  Quick hack to be able to do a useTableSearch across components and still update the same state
 * @param storageKey - the key to store the table search state
 * @returns   the table search state
 */
export function useTableSearchByStorageKey(storageKey: string) {
  const tableSearch = getItem(storageKey);
  const setTableSearch = (ts: any) => {
    setItem(storageKey, ts);
  };
  return {
    tableSearch,
    setTableSearch,
  };
}

export default function useTableSearch(storageKey?:string, searchablePropertyKeys?:string[], defaultPageSize?:number, defaultPageNumber?:number) {

  let defaultSearchText ='';
  if (storageKey) {
    const saveQuickSearch = getStoredSaveQuickSearch(storageKey);
    if (saveQuickSearch) {
      const storedSearchValue = getStoredSearchValue(storageKey);
      defaultSearchText = storedSearchValue || '';
    }
  }
  const [rows, setRows] = useState<any[]>([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [searchText, setSearchText] = useState(defaultSearchText);
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: defaultPageNumber ?? 0,
    pageSize: defaultPageSize ?? 5,
  });

  function setRowsWithStoredFilter(allRows) {
    if (searchText) {
      const filteredRows = filterRows(allRows, searchText);
      setRows(filteredRows);
    } else {
      setRows(allRows);
    }
  }

  function clearStoredSearch() {
    if (storageKey) {
      setStoredSearchValue(storageKey, '');
      setSearchText('');
    }
  }

  function requestSearch(allRows, searchValue: string) {
    setSearchText(searchValue);
    debouncedGridFilter(allRows, searchValue);
  };

  async function requestAsyncSearch<T, V extends IStreetFairSearchRequest>(
    searchRequestor:(dto:V, callback:any) => Promise<AxiosResponse<ISearchPage<T>>>,
    dto:V,

  ) {
    setSearchText(dto.query ?? '');
    setSearchResultsLoading(true);

    await searchRequestor(dto, (data:ISearchPage<T>) => {
      if (storageKey) {
        const saveQuickSearch = getStoredSaveQuickSearch(storageKey);
        if (saveQuickSearch) {
          setStoredSearchValue(storageKey, dto.query ?? '');
        }
      }
      if (data) {
        setRows(data.items);
        setRowCountState(data.availablePagesCount * data.pageSize);
        setSearchResultsLoading(false);
      }
    });
  }

  const debouncedGridFilter = debounce((allRows, searchValue:string) => {
    if (storageKey) {
      const saveQuickSearch = getStoredSaveQuickSearch(storageKey);
      if (saveQuickSearch) {
        setStoredSearchValue(storageKey, searchValue);
      }
    }
    const filteredRows = filterRows(allRows, searchValue);
    setRows(filteredRows);
  }, 300);

  function filterRows(allRows:any[], searchValue:string) {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    return allRows.filter((row: any) => {
      if (searchablePropertyKeys) {
        searchablePropertyKeys.some((field: any) => {
          if (row[field] === null || row[field] === undefined) {
            return false;
          }
          return searchRegex.test(row[field].toString());
        });
      }
      return Object.keys(row).some((field: any) => {
        if (row[field] === null || row[field] === undefined) {
          return false;
        }
        return searchRegex.test(row[field].toString());
      });
    });
  }

  function onPageChange(page: number, details: GridCallbackDetails):void {
    setPaginationModel({
      page: page,
      pageSize: paginationModel.pageSize,
    });
  }

  function onPageSizeChange(pageSize: number, details: GridCallbackDetails):void {
    setPaginationModel({
      page: paginationModel.page,
      pageSize: pageSize,
    });
  }

  const data = {
    rows,
    rowCountState,
    searchText,
    searchResultsLoading,
    paginationModel,
    setSearchResultsLoading,
    setRows,
    setRowsWithStoredFilter,
    setRowCountState,
    setPaginationModel,
    requestSearch,
    clearStoredSearch,
    requestAsyncSearch,
    onPageChange,
    onPageSizeChange,
  };
  const { setTableSearch } = useTableSearchByStorageKey(storageKey ?? '');
  setTableSearch(data);
  return data;
}