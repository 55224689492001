import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import useUsersTable from '../hookStore/useUsersTable';

export default function FindUsersByServiceProvider() {
  const { onFindUsersByServiceProvider } = useUsersTable();

  return (
    <ServiceProviderAutocomplete
      label='Service Provider'
      placeholder='Search for users by service provider'
      formless
      postOnChange={async (_formCtx, value, reason, details) => {
        if (reason === 'selectOption') {
          await onFindUsersByServiceProvider(value?.optionValue);
        }
      } } required={false}
    />
  );
}