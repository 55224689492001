import HookSelect from 'components/reactHookForm/HookSelect';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';


export default function CrmDefaultServiceTypesSelect() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const formValues = formCtx.getValues();
  const serviceTypes = formValues.serviceTypes;
  return (
    <FormControl variant='standard' fullWidth error={isError('status')}>
      <InputLabel htmlFor={'status'}>Default Service Type</InputLabel>
      <HookSelect
        control={formCtx.control}
        name={'defaultServiceType'}
        id={'defaultServiceType'}
        menuItems={serviceTypes}
        menuItemKeys={{
          key: 'serviceTypeId',
          value: 'serviceTypeId',
          displayValue: 'serviceTypeName',
        }} />
      <FormHelperText>{getErrorMessage('status')}</FormHelperText>
    </FormControl>
  );
}