import _ from 'lodash';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import HookTextField from 'components/reactHookForm/HookTextField';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { useFormContext } from 'react-hook-form';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useParams } from 'react-router-dom';
import { saveServiceTitanIntegration } from 'api/serviceProviderApi';
import DeleteButton from '../components/delete/DeleteButton';
import { CrmIntegrationType, CrmPartners } from 'model/serviceProvider';
import Save from '@mui/icons-material/Save';
import CrmDefaultServiceTypesSelect from '../components/CrmDefaultServiceTypesSelect';
import CrmStatusSelect from '../components/CrmStatusSelect';
import DebugSelect from '../components/DebugSelect';
import BusinessUnitIdsInput from './BusinessUnitIdsInput';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const servcieTitanConfirmationModalKey = 'ServiceTitanConfirmationModal';

function STConfirmationModal() {
  const { loadingKey, refresh } = useProviderIntegrationDetails();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createSuccessToast, createErrorToast } = useToast();

  return <HookConfirmationModal
    title={() => 'Save Service Titan Configuration'}
    body='Save changes? This will will make the Service Titan integration ACTIVE.'
    modalKey={servcieTitanConfirmationModalKey}
    message={(confirmArgs, cancelModal) => (
      <Grid container spacing={1} alignItems='center' justifyContent='center' paddingTop={'15px'} >
        <Grid item xs={12} paddingBottom={'25px'}>
        Save changes? This will will make the Service Titan integration ACTIVE.
        </Grid>
      </Grid>
    )}
    onConfirm={async (formData) => {
      try {
        onLoading();
        const payload = {
          tenantId: formData.tenantId,
          clientId: formData.clientId,
          clientSecret: formData.clientSecret,
          status: _.isEmpty(formData.status) ? null : formData.status,
          debug: formData.debug,
          defaultServiceTypeId: formData.defaultServiceType,
          businessUnitIds: formData.businessUnitIds,
        };

        if (serviceProviderId) {
          const resp = await saveServiceTitanIntegration(serviceProviderId, payload);

          if (resp.status === 200) {
            createSuccessToast('Service Titan Configuration Saved!');
            await refresh();
          } else {
            createErrorToast('Error saving Service Titan Configuration');
          }
        }
      } catch (error) {
        console.error('error saving ST config', error);
        createErrorToast('Error saving Service Titan Configuration');
      } finally {
        doneLoading();
      }
    }}
  />;
}

export default function ServiceTitanFormInputs() {
  const {} = useProviderIntegrationDetails();
  const { openModal } = useConfirmationModal(servcieTitanConfirmationModalKey);
  const formContext = useFormContext();
  const { createErrorToast, createSuccessToast } = useToast();
  let formState = formContext.getValues();
  let { displayGenericFormFields } = formState;

  const onSave = () => {
    if (formContext.formState.isValid) {
      const formData = formContext.getValues();
      openModal(formData);
    } else {
      createErrorToast('Please fill out all required fields');
    }
  };

  const copyToClipboard = () => {
    const clientSecret = formContext.getValues('clientSecret');
    navigator.clipboard.writeText(clientSecret).then(() => {
      createSuccessToast('Client Secret copied to clipboard');
    }).catch(() => {
      createErrorToast('Failed to copy Client Secret');
    });
  };

  return (
    <>
      <Grid container>
        { displayGenericFormFields &&
          <Grid container item spacing={1}>
            <Grid item xs={12} lg={3}>
              <CrmStatusSelect />
            </Grid>
            <Grid item xs={12} lg={3}>
              <DebugSelect />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} lg={6}>
                <CrmDefaultServiceTypesSelect />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <BusinessUnitIdsInput />
            </Grid>
          </Grid>
        }
        <Grid item xs={8}>
          <HookTextField name='tenantId' label='Tenant Id' required />
        </Grid>
        <Grid container item columnGap={1}>
          <Grid item xs={12} md={5}>
            <HookTextField name='clientId' label='Client ID' required />
          </Grid>
          <Grid item xs={12} md={5}>
            <HookTextField
              name='clientSecret'
              label='Client Secret'
              type='password'
              required
            />
          </Grid>
          <Grid item xs={12} md={1} alignSelf={'center'}>
            <Tooltip title="Copy to clipboard">
              <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container item paddingTop={'15px'} alignItems={'center'}>
          <Grid item xs={3}>
            <PrimaryButton
              type='submit'
              onSubmit={onSave}
              startIcon={<Save />}
              onClick={onSave}
            >
                Save
            </PrimaryButton>
          </Grid>
          <Grid item xs={3}>
            <DeleteButton partner={CrmPartners.SERVICETITAN} type={CrmIntegrationType.GET_OFF_PLATFORM_JOBS} />
          </Grid>
        </Grid>

      </Grid>
      <STConfirmationModal />
    </>
  );
}