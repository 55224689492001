import { Grid, Tooltip, Typography } from '@mui/material';
import { manualStartExecutionRecommendMeOnStreetFair } from 'api/rmosfApi';
import { PrimaryButton } from 'components/buttons';
import InfoIcon from '@mui/icons-material/Info';
import { HookDurationField, HookTextField } from 'components/reactHookForm';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import useToast from 'components/toast/useToast';
import { IStartRecommendMeOnStreetFairWorkflowRequest, IStartRecommendMeOnStreetFairWorkflowRequestForm } from 'model/rmosf/requests';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { createUninitializedDropdownOption } from 'model/dropdown';

export default function ManualWorkflowRmosfForm() {
  const { createErrorToast } = useToast();
  const formContext = useForm<IStartRecommendMeOnStreetFairWorkflowRequestForm>({
    mode: 'onTouched',
    defaultValues: {
      delay: '',
      serviceProviderId: createUninitializedDropdownOption(),
      testEmail: '',
    },
  });
  const onSubmit: SubmitHandler<IStartRecommendMeOnStreetFairWorkflowRequestForm> = async (formData) => {

    const data: IStartRecommendMeOnStreetFairWorkflowRequest = {
      delay: formData.delay,
      serviceProviderId: formData.serviceProviderId.optionValue,
      testEmail: formData.testEmail,
    };
    await manualStartExecutionRecommendMeOnStreetFair(formData.serviceProviderId.optionValue!, data);
  };

  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid container spacing={2} >

            <Grid item xs={12} sx={{ marginTop: '32px', marginBottom: '16px' }}>
              <Grid container alignContent={'center'} alignItems={'center'} spacing={1}>
                <Grid item xs='auto'>
                  <Typography variant='h6'>Manual Start Execution (step function)</Typography>
                </Grid>
                <Grid item sx={{ marginLeft: '32px' }}>
                  <PrimaryButton type='submit'>Submit</PrimaryButton>
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12} md={6} >
              <Grid container >
                <Grid item xs={12} md={6}>
                  <Grid container alignContent={'center'} alignItems={'center'} spacing={1}>
                    <Grid item xs>
                      <HookTextField
                        name='testEmail'
                        label='Test Email (Optional)'
                      />
                    </Grid>
                    <Grid item xs='auto' sx={{ marginRight: '10px' }}>
                      <TooltipIcon
                        title='Specify an email for testing'
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceProviderAutocomplete
                    label={'Service provider'}
                    fieldName='serviceProviderId'
                    formless={false}
                    placeholder={'select a service provider'}
                    required={true}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='body1' sx={{ marginBottom: '10px' }}>Delay</Typography>
              <HookDurationField
                name='delay'
                label='Delay'
              />
            </Grid>
          </Grid>


        </form>
      </FormProvider>
    </>
  );
}