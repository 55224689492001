import { IInternalServiceProvider } from 'model/serviceProvider';
import { formatPercent } from './numberUtil';

export function getAllServiceProviderPhoneNumbersList(data:any) {
  var phoneNumberSet = new Set<string>();

  if (data.transientAdditionalContactPhones && data.transientAdditionalContactPhones.length > 0) {
    data.transientAdditionalContactPhones.forEach(x => {
      phoneNumberSet.add(x.value);
    });
  }

  if (data.contactPersonPhone && data.contactPersonPhone.length > 0) {
    phoneNumberSet.add(data.contactPersonPhone);
  }

  if (data.bookingPhoneNumber && data.bookingPhoneNumber.length > 0) {
    phoneNumberSet.add(data.bookingPhoneNumber);
  }

  if (data.businessContactPhone && data.businessContactPhone.length > 0) {
    phoneNumberSet.add(data.businessContactPhone);
  }

  var phoneNumbersArray = Array.from(phoneNumberSet);
  return phoneNumbersArray;
}


export function activeOrGreater(status:string) {
  return status === 'ACTIVE' || status === 'LAUNCHED';
}

export function formatServiceProviderForForm(serviceProvider, createProviderName?: string | null ) {

  const formattedData = { ...serviceProvider };

  if (!!serviceProvider.recurringPercent) {
    formattedData.recurringPercent = formatPercent(serviceProvider.recurringPercent);
  }
  if (!!serviceProvider.oneTimePercent) {
    formattedData.oneTimePercent = formatPercent(serviceProvider.oneTimePercent);
  }
  if (!!serviceProvider.takeRateCap) {
    formattedData.takeRateCap = serviceProvider.takeRateCap.toString();
  }
  //retrieved from query param and only set if creation mode
  if (!serviceProvider.id && createProviderName) {
    formattedData.name = createProviderName;
  }

  return formattedData;
}